import { useState, useEffect } from "react";
import axios from "axios";
import yaml from "js-yaml";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { Button, Form, InputText, Modal, RadioButton, Select, Tooltip, Textarea, FileInput, TranslationLoader } from "../../UI";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { encodebody, getDecodedBody } from "../../utils/utils";
import "./embeddingsManager.css";

export default function EmbeddingsManager ({ clientNr, explorerId, open, onClose }) {
  const { getTranslatedPlainText, getTranslatedPlaceholder, getTranslatedTooltipConfig } = useCustomTranslation();
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [embeddings, setEmbeddings] = useState([]);
  const [filters, setFilters] = useState({ keyword: "", source: "", heading: "", textquery: "" });
  const [filterType, setFilterType] = useState("filters");
  const [newEmbedding, setNewEmbedding] = useState({ keywords: "", sources: "", heading: "", text: "" });
  const [loading, setLoading] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editingEmbedding, setEditingEmbedding] = useState(null);
  const [importingTextFile, setImportingTextFile] = useState(false);
  const [importingYaml, setImportingYaml] = useState(false);
  const [exporting, setExporting] = useState(false);
  const vectorDBClient = process.env.REACT_APP_HOST_VECTORDB_CLIENT;

  // Fetch collections when component mounts or when clientNr or explorerId changes
  useEffect(() => {
    fetchCollections();
  }, [clientNr, explorerId]);

  const handleClose = () => {
    setLoading(false);
    setImportingTextFile(false);
    setImportingYaml(false);
    setExporting(false);
    onClose?.()
  }

  async function fetchCollections() {
    try {
      const response = await axios.post(`${process.env.REACT_APP_CENTRAL_BACK}/workspace/collections`, encodebody({ clientNr, explorerId }));
      const responseData = getDecodedBody(response.data);
      setCollections(responseData);
      setSelectedCollection(responseData[0]);
    } catch (error) {
      console.log("Error fetching collections:", error);
    }
  };

  const fetchEmbeddings = async () => {
    try {
      setLoading(true);
      const query = filterType === "filters" ? { ...filters, textquery: "" } : { keyword: "", source: "", heading: "", textquery: filters.textquery };
      const response = await axios.post(`${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/query`, { clientNr: vectorDBClient, collectionId: selectedCollection, ...query });
      setEmbeddings(response.data);
    } catch (error) {
      console.error("Error fetching embeddings:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditEmbeddingClick = (embedding) => {
    setEditingEmbedding({
      ...embedding,
      keywords: embedding.keywords.join(", "),
      sources: embedding.sources.join(", "),
    });
    setEditDialogOpen(true);
  };

  const handleEditEmbeddingChange = (e) => {
    const { name, value } = e.target;
    setEditingEmbedding((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateEmbedding = async () => {
    try {
      setLoading(true);
      const { _id, keywords, sources, ...updatedData } = editingEmbedding;
      const embeddingData = {
        ...updatedData,
        keywords: keywords.split(",").map(item => item.trim()),
        sources: sources.split(",").map(item => item.trim()),
      };
      await axios.post(`${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/update`, { clientNr, collectionId: selectedCollection, embeddingId: _id, ...embeddingData });
      fetchEmbeddings();
      setEditingEmbedding(null);
      setEditDialogOpen(false);
    } catch (error) {
      console.error("Error updating embedding:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateEmbeddingCancel = () => {
    setEditingEmbedding(null);
    setEditDialogOpen(false);
  };

  // New functions for handling "Add Embedding"
  const handleNewEmbeddingClick = () => {
    setNewEmbedding({ keywords: "", sources: "", heading: "", text: "" }); // Reset new embedding state
    setAddDialogOpen(true); // Open the add dialog
  };

  const handleNewEmbeddingChange = (e) => {
    const { name, value } = e.target;
    setNewEmbedding((prev) => ({ ...prev, [name]: value }));
  };

  const handleRegisterEmbedding = async () => {
    try {
      setLoading(true);
      const embeddingData = {
        ...newEmbedding,
        keywords: newEmbedding.keywords.split(",").map(item => item.trim()),
        sources: newEmbedding.sources.split(",").map(item => item.trim()),
      };
      await axios.post(`${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/register`, { clientNr: vectorDBClient, collectionId: selectedCollection, ...embeddingData });
      fetchEmbeddings();
      setAddDialogOpen(false); // Close the add dialog
    } catch (error) {
      console.error("Error registering embedding:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddEmbeddingCancel = () => {
    setNewEmbedding({ keywords: "", sources: "", heading: "", text: "" }); // Reset state
    setAddDialogOpen(false); // Close the dialog
  };

  const handleDeleteEmbedding = async (embeddingId) => {
    try {
      await axios.post(`${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/delete`, { clientNr: vectorDBClient, collectionId: selectedCollection, embeddingId });
      await fetchEmbeddings();
    } catch (error) {
      console.error("Error deleting embedding:", error);
    }
  };

  const handleDeleteAllEmbeddings = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/deleteall`, { clientNr: vectorDBClient, collectionId: selectedCollection });
      await fetchEmbeddings();
    } catch (error) {
      console.error("Error deleting all embeddings:", error);
    }
  };

  const handleImport = async (file) => {
    if(!file) {
      return setImportingYaml(false)
    }

    setImportingYaml(true)
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const yamlContent = yaml.load(e.target.result);
        let importedCount = 0;
        let skippedCount = 0;

        for (const obj of yamlContent) {
          if (typeof obj.text === "string" && obj.text.trim() !== "") {
            const embeddingData = {
              keywords: Array.isArray(obj.keywords) ? obj.keywords : (obj.keywords ? [obj.keywords] : []),
              sources: Array.isArray(obj.sources) ? obj.sources : (obj.sources ? [obj.sources] : []),
              heading: typeof obj.heading === "string" ? obj.heading : "",
              text: obj.text,
            };

            await axios.post(`${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/register`, {
              clientNr: vectorDBClient,
              collectionId: selectedCollection,
              ...embeddingData,
            });
            importedCount++;
          } else {
            skippedCount++;
          }
        }

        await fetchEmbeddings();
        alert(getTranslatedPlainText("modals.embeddingsManager.alerts.embeddingsImportSuccess", { importedCount, skippedCount }));
      } catch (error) {
        console.log("Error importing embeddings:", error);
        alert(getTranslatedPlainText("modals.embeddingsManager.alerts.embeddingsImportError"));
      } finally {
        setImportingYaml(false)
      }
    };

    reader.readAsText(file);
  };

  const handleFileSelect = async (event) => {
    await handleImport(event.target.files[0])
  };

  const handleImportTextFiles = async (files) => {
    if (files.length === 0) {
      console.log("No files selected for import.");
      return setImportingTextFile(false);
    }

    setImportingTextFile(true);

    // Step 1: Read file contents into an array
    const fileContents = [];
    console.log(`Number of files selected: ${files.length}`);
    if (files.length > 50) {
      alert(getTranslatedPlainText("modals.embeddingsManager.alerts.fileImportLimitError"));
      return setImportingTextFile(false);
    }

    try {
      for (const file of files) {
        try {
          const text = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.onerror = reject;
            reader.readAsText(file);
          });

          fileContents.push({
            name: file.name,
            text,
          });
          console.log(`File read successfully: ${file.name}`);
        } catch (readError) {
          console.error(`Error reading file ${file.name}:`, readError);
        }
      }
    } catch (error) {
      console.error("Error during file reading:", error);
      return setImportingTextFile(false);
    }

    // Step 2: Process each file content
    let importedCount = 0;

    try {
      for (const { name, text } of fileContents) {
        try {
          const embeddingData = {
            clientNr: vectorDBClient,
            collectionId: selectedCollection,
            keywords: [],
            sources: [],
            heading: name.replace(/\.[^/.]+$/, ""),
            text,
          };

          console.log(`Sending file to backend: ${name}`);
          console.log("EMBEDDING DATA",embeddingData);
          await axios.post(
            `${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/register`,
            embeddingData
          );

          importedCount++;
          console.log(`Successfully processed file: ${name}`);
        } catch (apiError) {
          console.log(`Error sending file ${name} to API:`, apiError);
        }
      }

      alert(getTranslatedPlainText("modals.embeddingsManager.alerts.textFilesImportSuccess", { importedCount }));
      console.log(`${importedCount} files processed successfully.`);
      fetchEmbeddings();
    } catch (error) {
      console.error("Error during API processing:", error);
      alert(getTranslatedPlainText("modals.embeddingsManager.alerts.fileImportError"));
    } finally {
      setImportingTextFile(false);
    }
  };

  const handleTextFilesSelect = async (event) => {
    if (event.target.files.length === 0) return;
    await handleImportTextFiles(event.target.files);
  };

  const handleExport = async () => {
    try {
      setExporting(true);
      const response = await axios.post(`${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/query`, {
        clientNr: vectorDBClient,
        collectionId: selectedCollection,
      });

      const filteredData = response.data.map(({ keywords, sources, heading, text }) => ({
        keywords,
        sources,
        heading,
        text,
      }));

      const yamlContent = yaml.dump(filteredData);
      const blob = new Blob([yamlContent], { type: "application/x-yaml" });
      saveAs(blob, "embeddings.yaml");
    } catch (error) {
      console.error("Error exporting embeddings:", error);
    } finally {
      setExporting(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} maxWidth="78rem">
      <Modal.Title>
        <TranslationLoader
          translationKey="modals.embeddingsManager.sections.main.heading"
          fallbackText="Embeddings Manager"
        />
      </Modal.Title>
      <Modal.Body>
        <Form>
          <Form.Col>
            <Select
              value={selectedCollection || ""}
              onChange={(e) => setSelectedCollection(e.target.value)}
              options={collections.map((collection) => ({ label: collection, value: collection }))}
            />
            {/* Placing all buttons in the same Form.Row */}
            <Form.Row>
              {/* YAML Import Button */}
              {importingYaml
                ? <Button disabled>
                    {getTranslatedPlainText("modals.embeddingsManager.sections.main.buttons.importYaml.loading")}
                  </Button>
                : (
                  <FileInput
                    accept=".yaml, .yml"
                    className="embeddings-manager__import-button"
                    chooseLabel={getTranslatedPlainText("modals.embeddingsManager.sections.main.buttons.importYaml.default")}
                    onChange={handleFileSelect}
                  />
              )}
              {/* Export Button */}
              <Button onClick={handleExport} disabled={exporting}>
                {exporting
                  ? getTranslatedPlainText("modals.embeddingsManager.sections.main.buttons.exportYaml.loading")
                  : <TranslationLoader
                      type="button"
                      translationKey="modals.embeddingsManager.sections.main.buttons.exportYaml"
                      fallbackText="Export YAML"
                    />
                }
              </Button>
              {/* Text Files Import Button */}
              {importingTextFile
                ? <Button disabled>
                    {getTranslatedPlainText("modals.embeddingsManager.sections.main.buttons.importTextFile.loading")}
                  </Button>
                : (
                  <FileInput
                    accept=".txt"
                    className="embeddings-manager__import-button"
                    chooseLabel={getTranslatedPlainText("modals.embeddingsManager.sections.main.buttons.importTextFile.default")}
                    onChange={handleTextFilesSelect}
                    multiple
                  />
              )}
            </Form.Row>
          </Form.Col>
        </Form>

        <section className="embeddings-manager__section">
          <Form>
            <Form.Col>
              <RadioButton
                name="filterType"
                value="filters"
                checked={filterType === "filters"}
                onChange={() => setFilterType("filters")}
              >
                <TranslationLoader
                  type="label"
                  translationKey="modals.embeddingsManager.sections.main.inputs.filterByKeywordSourceAndHeading"
                  fallbackText="Filter by Keyword, Source, and Heading"
                />
              </RadioButton>
              <RadioButton
                name="filterType"
                value="textquery"
                checked={filterType === "textquery"}
                onChange={() => setFilterType("textquery")}
              >
                <TranslationLoader
                  type="label"
                  translationKey="modals.embeddingsManager.sections.main.inputs.filterByTextQuery"
                  fallbackText="Filter by Text Query"
                />
              </RadioButton>
            </Form.Col>
            <Form.Col>
              {filterType === "filters" ? (
                <>
                  <InputText
                    name="keyword"
                    placeholder={getTranslatedPlaceholder("modals.embeddingsManager.sections.main.inputs.keyword")}
                    value={filters.keyword || ""}
                    onChange={handleFilterChange}
                  />
                  <InputText
                    name="source"
                    placeholder={getTranslatedPlaceholder("modals.embeddingsManager.sections.main.inputs.source")}
                    value={filters.source || ""}
                    onChange={handleFilterChange}
                  />
                  <InputText
                    name="heading"
                    placeholder={getTranslatedPlaceholder("modals.embeddingsManager.sections.main.inputs.heading")}
                    value={filters.heading || ""}
                    onChange={handleFilterChange}
                  />
                </>
              ) : (
                <Form.Row>
                  <InputText
                    name="textquery"
                    placeholder={getTranslatedPlaceholder("modals.embeddingsManager.sections.main.inputs.textQuery")}
                    value={filters.textquery || ""}
                    onChange={handleFilterChange}
                  />
                </Form.Row>
              )}
              <Form.Row>
                <Button onClick={fetchEmbeddings}>{
                  loading
                  ? getTranslatedPlainText("modals.embeddingsManager.sections.main.buttons.fetchEmbeddings.loading")
                  : <TranslationLoader
                      type="button"
                      translationKey="modals.embeddingsManager.sections.main.buttons.fetchEmbeddings"
                      fallbackText="Fetch Embeddings"
                    />
                }</Button>
                <Button color="secondary" onClick={handleNewEmbeddingClick}>
                  <TranslationLoader
                    type="button"
                    translationKey="modals.embeddingsManager.sections.main.buttons.addEmbedding"
                    fallbackText="Add Embedding"
                  />
                </Button>
                <Button color="danger" onClick={handleDeleteAllEmbeddings}>
                  <TranslationLoader
                    type="button"
                    translationKey="modals.embeddingsManager.sections.main.buttons.deleteAll"
                    fallbackText="Delete All"
                  />
                </Button>
              </Form.Row>
            </Form.Col>
          </Form>
        </section>

        <section className="embeddings-manager__section">
          <h3 className="embeddings-manager__section-title">
            <TranslationLoader
              translationKey="modals.embeddingsManager.sections.main.table.heading"
              fallbackText="Embeddings"
            />
          </h3>
          <div className="embeddings-manager__table-container">
            <table className="embeddings-manager__table">
              <thead>
                <tr>
                  <th>
                    <TranslationLoader
                      translationKey="modals.embeddingsManager.sections.main.table.thead.keywords"
                      fallbackText="Keywords"
                    />
                  </th>
                  <th>
                    <TranslationLoader
                      translationKey="modals.embeddingsManager.sections.main.table.thead.sources"
                      fallbackText="Sources"
                    />
                  </th>
                  <th>
                    <TranslationLoader
                      translationKey="modals.embeddingsManager.sections.main.table.thead.heading"
                      fallbackText="Heading"
                    />
                  </th>
                  <th>
                    <TranslationLoader
                      translationKey="modals.embeddingsManager.sections.main.table.thead.text"
                      fallbackText="Text"
                    />
                  </th>
                  <th className="actions-column">
                    <TranslationLoader
                      translationKey="modals.embeddingsManager.sections.main.table.thead.actions"
                      fallbackText="Actions"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {embeddings.map((embedding) => (
                  <tr key={embedding._id}>
                    <td className="text-column">{embedding.keywords.join(", ")}</td>
                    <td className="text-column">{embedding.sources.join(", ")}</td>
                    <td className="text-column">{embedding.heading}</td>
                    <td className="text-column">{embedding.text}</td>
                    <td className="actions-column">
                      <Tooltip tooltip={getTranslatedTooltipConfig("modals.embeddingsManager.sections.main.table.trows.buttons.editEmbedding")}>
                        <Button
                          color="secondary"
                          onClick={() => handleEditEmbeddingClick(embedding)}
                          size="sm"
                          rounded
                        >
                          <EditOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip tooltip={getTranslatedTooltipConfig("modals.embeddingsManager.sections.main.table.trows.buttons.deleteEmbedding")}>
                        <Button
                          color="danger"
                          onClick={() => handleDeleteEmbedding(embedding._id)}
                          size="sm"
                          rounded
                        >
                          <DeleteOutline />
                        </Button>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>

        {/* Edit dialog */}
        <Modal open={editDialogOpen} onClose={handleUpdateEmbeddingCancel}>
          <Modal.Title>
            <TranslationLoader
              translationKey="modals.embeddingsManager.sections.editEmbedding.heading"
              fallbackText="Edit Embedding"
            />
          </Modal.Title>
          <Modal.Body>
            <Form>
              <Form.Control>
                <InputText
                  name="keywords"
                  placeholder={getTranslatedPlaceholder("modals.embeddingsManager.sections.editEmbedding.inputs.keywords")}
                  value={editingEmbedding?.keywords || ""}
                  onChange={handleEditEmbeddingChange}
                />
                <InputText
                  name="sources"
                  placeholder={getTranslatedPlaceholder("modals.embeddingsManager.sections.editEmbedding.inputs.sources")}
                  value={editingEmbedding?.sources || ""}
                  onChange={handleEditEmbeddingChange}
                />
                <InputText
                  type="text"
                  name="heading"
                  placeholder={getTranslatedPlaceholder("modals.embeddingsManager.sections.editEmbedding.inputs.heading")}
                  value={editingEmbedding?.heading || ""}
                  onChange={handleEditEmbeddingChange}
                  />
                <div style={{ width: "800px" }}>
                <Textarea
                  type="text"
                  name="text"
                  rows="10"
                  placeholder={getTranslatedPlaceholder("modals.embeddingsManager.sections.editEmbedding.inputs.text")}
                  value={editingEmbedding?.text || ""}
                  onChange={handleEditEmbeddingChange}
                />
                </div>
              </Form.Control>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button color="secondary" onClick={handleUpdateEmbeddingCancel}>
              <TranslationLoader
                type="button"
                translationKey="modals.embeddingsManager.sections.editEmbedding.buttons.cancel"
                fallbackText="Cancel"
              />
            </Button>
            <Button onClick={handleUpdateEmbedding} disabled={loading}>
              {loading
              ? getTranslatedPlainText("modals.embeddingsManager.sections.editEmbedding.buttons.saveChanges.loading")
              : <TranslationLoader
                  type="button"
                  translationKey="modals.embeddingsManager.sections.editEmbedding.buttons.saveChanges"
                  fallbackText="Save Changes"
                />
              }
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add dialog */}
        <Modal open={addDialogOpen} onClose={handleAddEmbeddingCancel}>
          <Modal.Title>
            <TranslationLoader
              translationKey="modals.embeddingsManager.sections.addEmbedding.heading"
              fallbackText="Add New Embedding"
            />
          </Modal.Title>
          <Modal.Body>
            <Form>
              <Form.Control>
                <InputText
                  name="keywords"
                  placeholder={getTranslatedPlaceholder("modals.embeddingsManager.sections.addEmbedding.inputs.keywords")}
                  value={newEmbedding.keywords || ""}
                  onChange={handleNewEmbeddingChange}
                />
                <InputText
                  name="sources"
                  placeholder={getTranslatedPlaceholder("modals.embeddingsManager.sections.addEmbedding.inputs.sources")}
                  value={newEmbedding.sources || ""}
                  onChange={handleNewEmbeddingChange}
                />
                <InputText
                  name="heading"
                  placeholder={getTranslatedPlaceholder("modals.embeddingsManager.sections.addEmbedding.inputs.heading")}
                  value={newEmbedding.heading || ""}
                  onChange={handleNewEmbeddingChange}
                />
                <div style={{ width: "800px" }}>
                <Textarea
                  name="text"
                  placeholder={getTranslatedPlaceholder("modals.embeddingsManager.sections.addEmbedding.inputs.text")}
                  value={newEmbedding.text || ""}
                  onChange={handleNewEmbeddingChange}
                  rows="10"
                />
                </div>
              </Form.Control>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button color="secondary" onClick={handleAddEmbeddingCancel}>
              <TranslationLoader
                type="button"
                translationKey="modals.embeddingsManager.sections.addEmbedding.buttons.cancel"
                fallbackText="Cancel"
              />
            </Button>
            <Button onClick={handleRegisterEmbedding} disabled={loading}>
              {loading
                ? getTranslatedPlainText("modals.embeddingsManager.sections.addEmbedding.buttons.addEmbedding.loading")
                : <TranslationLoader
                    type="button"
                    translationKey="modals.embeddingsManager.sections.addEmbedding.buttons.addEmbedding"
                    fallbackText="Save Changes"
                  />
              }
            </Button>
          </Modal.Footer>
        </Modal>
      </Modal.Body>
    </Modal>
  );
};
