import { useState } from "react";
import axios from "axios";
import { useAsync } from "../../hooks/useAsync";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { Form, Icon, InputText, TextEditor, Toolbar, TranslationLoader } from "../../UI";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { encodebody, getDecodedBody } from "../../utils/utils.js";

export default function Taskcomplianceview({ clientNr, explorerId, workflowName, taskId, authorization }) {
  const { getTranslatedPlainText } = useCustomTranslation();
  const [task, setTask] = useState(null);
  const { loading, error, retrying, retry } = useAsync(fetchTask, [workflowName, taskId]);
  const readOnly = !authorization.designer && !authorization.owner;

  async function fetchTask() {
    const apiUrl = `${process.env.REACT_APP_CENTRAL_BACK}/task/query`;

    // Define the request body
    const requestBody = {
      clientNr,
      explorerId,
      workflowName,
      taskId,
    };

    try {
      // Make a POST request to fetch the task
      const response = await axios.post(apiUrl, encodebody(requestBody), {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = getDecodedBody(response.data);
      // Set the fetched product data to the state
      setTask(data);
      // Set the markdown
    } catch (error) {
      console.error("Error fetching task:", error);
      throw error;
    }
  }

  const handleComplianceDescriptionChange = (value) => {
    setTask((prevTask) => ({
      ...prevTask,
      complianceDescription: value,
    }));
  };

  const handleUpdate = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + "/task/update";

    // Define the request body
    const requestBody = {
      complianceDescription: task.complianceDescription,
      clientNr,
      explorerId,
      workflowName,
      taskId,
    };

    await axios.post(apiUrl, encodebody(requestBody));
    alert(getTranslatedPlainText("pages.home.taskComplianceView.alerts.taskUpdateSuccess"));
  };

  return error || retrying ? (
    <FetchErrorView
      className="section-right__content"
      retry={retry}
      retrying={retrying}
    />
  ) : (
    <>
      <header className="section-right__toolbar-container">
        <Toolbar>
          {!readOnly && (
            <Toolbar.Button onClick={handleUpdate}>
              <TranslationLoader
                type="button"
                translationKey="pages.home.taskComplianceView.toolbar.buttons.update"
                fallbackText="Update"
              />
            </Toolbar.Button>
          )}
          <Toolbar.Item>
            <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/tasks-detail-panel#taskcompliance-section">
              <HelpCenterIcon />
            </Icon>
          </Toolbar.Item>
        </Toolbar>
      </header>
      <div className="section-right__content">
        <Form className="section-right__form">
          <Form.Control>
            <Form.Label htmlFor="taskName">
              <TranslationLoader
                type="label"
                translationKey="pages.home.taskComplianceView.inputs.taskName"
                fallbackText="Task Name"
              />
            </Form.Label>
            <InputText
              id="taskName"
              value={task?.name}
              loading={loading}
              disabled
            />
          </Form.Control>
          <Form.Control className="panel__text-editor-section">
            <Form.Label htmlFor="taskComplianceDescription">
              <TranslationLoader
                type="label"
                translationKey="pages.home.taskComplianceView.inputs.complianceDescription"
                fallbackText="Compliance Description"
              />
            </Form.Label>
            <TextEditor
              id="taskComplianceDescription"
              value={task?.complianceDescription}
              onChange={handleComplianceDescriptionChange}
              loading={loading}
              readOnly={readOnly}
            />
          </Form.Control>
        </Form>
      </div>
    </>
  );
}
