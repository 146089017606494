import TreeItemOptions from "./TreeItemOptions";
import FolderIcon from "./FolderIcon";
import { useRef } from "react";
import { Lock } from "@material-ui/icons";
import "./RenderItemRenderer.css";

export default function RenderItemRenderer({
  arrow,
  children,
  context,
  depth,
  item,
  onCopyApi,
  onRemoveItem,
  onStartRenamingItem,
  readOnly,
  title,
}) {
  const InteractiveComponent = context.isRenaming ? "div" : "button";
  const renderDepthOffset = 10;
  const type = context.isRenaming ? undefined : "button";
  const isSystemFolder = item.index === "Unassigned" || item.index === "MyFolders";
  const itemRef = useRef(null);

  const suffixes = [];
  if (item.isFolder) suffixes.push("-isFolder");
  if (context.isSelected) suffixes.push("-selected");
  if (context.isExpanded) suffixes.push("-expanded");
  if (context.isFocused) suffixes.push("-focused");
  if (context.isDraggingOver) suffixes.push("-dragging-over");
  if (context.isSearchMatching) suffixes.push("-search-match");

  const getFullClass = (baseClass) => {
    let fullClass = baseClass;
    for (const sufix of suffixes) {
      fullClass += ` ${baseClass}${sufix}`;
    }
    return fullClass;
  };

  function handleFocus(e) {
    if (e.target.closest(".menu")) return;
    context.interactiveElementProps.onFocus(e);
  }

  function handleClick(e) {
    if (e.target.closest(".menu")) return;
    context.interactiveElementProps.onClick(e);
  }

  return (
    <li
      {...context.itemContainerWithChildrenProps}
      className={getFullClass("rct-tree-item-li")}
    >
      <div
        {...context.itemContainerWithoutChildrenProps}
        style={{ paddingLeft: `${(depth + 1) * renderDepthOffset}px` }}
        className={getFullClass("rct-tree-item-title-container")}
        ref={itemRef}
      >
        {arrow}
        <InteractiveComponent
          type={type}
          {...context.interactiveElementProps}
          className={getFullClass("rct-tree-item-button")}
          onClick={handleClick}
          onFocus={handleFocus}
          title={title}
        >
          {item.isFolder && (
            <FolderIcon
              className="rct-tree-item-folder-icon"
              open={context.isExpanded}
            />
          )}
          {item.system && <Lock className="rct-tree-item-folder-icon" />}
          <span className="rct-tree-item-title">{title}</span>
          {!isSystemFolder && !context.isRenaming && !readOnly && (
            <TreeItemOptions
              className="rct-tree-item-more-options-button"
              item={item}
              onRename={(e) => {
                onStartRenamingItem();
                context.startRenamingItem(e);
              }}
              onCopy={() => onCopyApi(item)}
              onRemove={() => onRemoveItem(item)}
            />
          )}
        </InteractiveComponent>
      </div>
      {children}
    </li>
  );
};
