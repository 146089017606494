import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { TerminalContextProvider } from "react-terminal";
import { saveAs } from "file-saver";
import jsYaml from "js-yaml";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import Chatbot from "../chatbot/Chatbot";
import ApiTerminal from "../../components/apiTerminal/ApiTerminal";
import ApiCode from "../../components/apicode/ApiCode";
import WorkflowCode from "../../components/workflowcode/WorkflowCode";
import Graphview from "../../components/graphview/Graphview";
import Productview from "../productview/Productview";
import Productcomplianceview from "../productcomplianceview/Productcomplianceview";
import Workflowview from "../workflowview/Workflowview";
import Workflowcomplianceview from "../workflowcomplianceview/Workflowcomplianceview";
import Workflowterminal from "../workflowterminal/Workflowterminal";
import Taskview from "../taskview/Taskview";
import Taskcomplianceview from "../taskcomplianceview/Taskcomplianceview";
import Linkview from "../linkview/Linkview";
import TreeMenu from "../treemenu/TreeMenu";
import Modalworkflow from "../modalworkflow/Modalworkflow";
import ModalPrivateInvitation from "../modalprivateinvitation/ModalPrivateInvitation";
import ModalPublicInvitation from "../modalpublicinvitation/ModalPublicInvitation";
// import JiraServiceDeskModal from "../jiraservicedeskmodal/JiraServiceDeskModal";
import Modalproduct from "../modalproduct/Modalproduct";
import Modalconfiguration from "../modalconfiguration/Modalconfiguration";
import Thirdparties from "../thirdparties/Thirdparties";
import Webhooks from "../webhooks/Webhooks";
import ExportProducts from "../exportproducts/ExportProducts";
import ExportGuides from "../exportguides/ExportGuides";
import ImportProducts from "../importproducts/ImportProducts";
import EmbeddingsManager from "../embeddingsmanager/EmbeddingsManager";
import DetailsPanelMenu from "../detailspanelmenu/DetailsPanelMenu.jsx";
import { Icon, Toolbar, TranslationLoader } from "../../UI";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { encodebody, getDecodedBody, convertToOpenAPI } from "../../utils/utils.js";
import "./producttree.css";

// import Modalexportdocumentation from "../modalexportdocumentation/Modalexportdocumentation";
const TreeNode = ({ label, children, isChild, topLevelClick }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div
      className={`tree-node ${isChild ? "child-node" : ""}`}>
      <span onClick={topLevelClick || toggleCollapse}>
        {collapsed ? "▶" : "▼"} {label}
      </span>
      <div style={{ display: collapsed ? "none" : "block" }}>
        {children}
      </div>
    </div>
  );
};

const INITIAL_EXECUTION_STATE = {
  runningTaskId: "",
  tasksToRun: {}
}

export default function ProductTree({ authorization, clientNr, explorerId }) {
  const { user } = useContext(AuthContext);
  const { getTranslatedPlainText } = useCustomTranslation();
  const [selectedItemType, setSelectedItemType] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedWork, setSelectedWorkflow] = useState(null);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [selectedLink, setSelectedLink] = useState(null);
  const [selectedApi, setSelectedApi] = useState(null);
  const [selectedCodeType, setCodeType] = useState(null);
  const [products, setProducts] = useState([]);
  const [executionState, setExecutionState] = useState(INITIAL_EXECUTION_STATE);

  const [newTreeItem, setNewTreeItem] = useState(0);
  const [newGraphItem, setNewGraphItem] = useState(0);

  const [showModalInvite, setShowModalInvite] = useState(false);
  const [showModalPublicInvite, setShowModalPublicInvite] = useState(false);

  const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);

  const [isExportProductsModalOpen, setIsExportProductsModalOpen] = useState(false);
  const [isExportGuidesModalOpen, setIsExportGuidesModalOpen] = useState(false);

  const [isImportProductsModalOpen, setIsImportProductsModalOpen] = useState(false);
  // const [isServicedDeskModalOpen, setIsServicedDeskModalOpen] = useState(false);

  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isEmbeddingsManagerOpen, setIsEmbeddingsManagerOpen] = useState(false);
  const [isConfigurationModalOpen, setIsConfigurationModalOpen] = useState(false);
  const [isThirdpartiesOpen, setIsThirdpartiesOpen] = useState(false);
  const [isWebhooksOpen, setIsWebhooksOpen] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(true); // Force refetching the data displayed in TreeMenu
  const history = useHistory();

  const readOnly = !authorization.designer && !authorization.owner;
  const fetchingAuthorization = readOnly && !authorization.reader; // A user must have at least a role

  const openWorkflowModal = () => {
    setIsWorkflowModalOpen(true);
  };

  const openProductModal = () => {
    setIsProductModalOpen(true);
  };

  const startRefetching = () => setShouldRefetch(true);
  const finishRefetching = () => setShouldRefetch(false);

  const updateRunningTask = (runningTaskId) => setExecutionState(prevState => ({ ...prevState, runningTaskId }));
  const initializeWorkflowTerminal = ({ nodes: tasks }) => setExecutionState(() => {
    const tasksToRun = {};
    for(const task of tasks) {
      tasksToRun[task.id] = task.label;
    }

    return ({
      runningTaskId: "",
      tasksToRun
    })
  });

  const initializeApiTerminal = (task) => setExecutionState({
    runningTaskId: task.id,
    tasksToRun: {
      [task.id]: task.label
    }
  })

  const resetRunningTask = () => updateRunningTask("");
  const clearExecutionState = () => setExecutionState(INITIAL_EXECUTION_STATE);

  async function exportApiOpenApi(ExportApiName) {
    try {
      const myApibody = {
        name: ExportApiName,
        clientNr,
        explorerId,
      }

      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/api/query", encodebody(myApibody));
      const myApi = await getDecodedBody(response.data);
      const myApiList = [];
      myApiList.push(myApi);

      // create the openApi Object
      const openAPIObject = convertToOpenAPI(myApiList,myApi.name, myApi.description);
      const yamlContent = jsYaml.dump(openAPIObject, { skipInvalid: true });
      // Create a Blob from the YAML content
      const blob = new Blob([yamlContent], { type: "text/yaml" });
      // Use the saveAs function from the file-saver library to trigger the download
      saveAs(blob, "api.yaml");
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  }

  async function exportWorkflowOpenApi(workflowName) {
    try {
      const myWorkflowbody = {
        clientNr,
        explorerId,
        workflowName,
      }
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/link/queryorderedapi", encodebody(myWorkflowbody));
      const myApiList = await getDecodedBody(response.data);

      if (myApiList === undefined || myApiList.length === 0) return;

      // remove empty object from array.
      const nonEmptyApiList = myApiList.filter(item => Object.keys(item).length > 0);

      const openAPIObject = convertToOpenAPI(nonEmptyApiList, workflowName, "collection of APIS belonging to workflow");
      const yamlContent = jsYaml.dump(openAPIObject, { skipInvalid: true });
      // Create a Blob from the YAML content
      const blob = new Blob([yamlContent], { type: "text/yaml" });
      // Use the saveAs function from the file-saver library to trigger the download
      saveAs(blob, "api.yaml");
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  }

  async function exportProductOpenApi(productName) {
    try {
      const myWorkflowListRequestBody = {
        clientNr,
        explorerId,
        productName
      };

      const workflowListResponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/workflow/queryallgivenproduct", encodebody(myWorkflowListRequestBody));
      const myWorkflowList = await getDecodedBody(workflowListResponse.data);

      if (myWorkflowList === undefined || myWorkflowList.length === 0) return;

      var myProductApiList = [];

      for (const myWorkflow of myWorkflowList) {
        const myWorkflowRequestbody = {
          workflowName: myWorkflow.name,
          clientNr,
          explorerId
        };

        try {
          const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/link/queryorderedapi", encodebody(myWorkflowRequestbody));
          var myApiList = await getDecodedBody(response.data);

          if (myApiList === undefined || myApiList.length === 0) {
            myApiList = [];
          }
          // add myApiList to myProductApiList
          myProductApiList = myProductApiList.concat(myApiList);
        } catch (error) {
          // Handle the error for this specific API call, e.g., set myApiList to an empty array
          myApiList = [];
        }

      }
      // remove empty object from array.

      const nonEmptyApiList = myProductApiList.filter(item => Object.keys(item).length > 0)
      const openAPIObject = convertToOpenAPI(nonEmptyApiList, productName, "collection of APIS belonging to product" );
      const yamlContent = jsYaml.dump(openAPIObject, { skipInvalid: true });
      // Create a Blob from the YAML content
      const blob = new Blob([yamlContent], { type: "text/yaml" });
      // Use the saveAs function from the file-saver library to trigger the download
      saveAs(blob, "api.yaml");
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  }

  const handleSelectTreeMenuItem = async (menuItem, value) => {
    // isThirdpartiesOpen
    switch (menuItem) {
      case "createWorkspace":
        try {
          await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/workspace/register", encodebody({clientNr: user.clientNr, explorerId: value,  chatbotKey: user.chatbotKey, email: user.email}));
          startRefetching();
          alert(getTranslatedPlainText("pages.home.productTree.alerts.workspaceCreationSuccess"));
        } catch (err) {
          if (err.response) {
            // The request was made and the server responded with a status code that is not in the range of 2xx
            alert(getTranslatedPlainText("pages.home.productTree.alerts.workspaceCreationError", { error: getDecodedBody(err.response.data) }));
          } else if (err.request) {
            // The request was made but no response was received
            alert(getTranslatedPlainText("pages.home.productTree.alerts.workspaceCreationNoResponseError"));
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error:", err.message);
            alert(getTranslatedPlainText("pages.home.productTree.alerts.workspaceCreationError", { error: getDecodedBody(err.message) }));
          }
        }
        break;

      case "SendNewInvitation":
        setShowModalInvite(true);
        break;

      case "CreatePublicInvitation":
        setShowModalPublicInvite(true);
        break;

      case "editUser":
      // fetch user
        const responseTargetUser = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/users/query", encodebody({ clientNr:user.clientNr, chatbotKey: user.chatbotKey, email: value}));
        const targetuser = getDecodedBody(responseTargetUser.data);

        history.push({
          pathname: "/edituser",
          state: { targetuser, explorerId }
        });
        break;

      case "deleteInvite":
        try {
          await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/invitation/delete", encodebody({ chatbotKey: user.chatbotKey, email: value}));
          startRefetching();
          alert(getTranslatedPlainText("pages.home.productTree.alerts.invitationDeletionSuccess"));
        } catch (err) {
          if (err.response) {
            // The request was made and the server responded with a status code that is not in the range of 2xx
            alert(getTranslatedPlainText("pages.home.productTree.alerts.invitationDeletionError", { error: getDecodedBody(err.response.data) }));
          } else if (err.request) {
            // The request was made but no response was received
            alert(getTranslatedPlainText("pages.home.productTree.alerts.invitationDeletionNoResponseError"));
          } else {
            // Something happened in setting up the request that triggered an Error
            alert(getTranslatedPlainText("pages.home.productTree.alerts.invitationDeletionError", { error: getDecodedBody(err.message) }));
          }
        }
        break;

      case "deleteUser":
        try {
          // proceed to remove the current explorer from the user"s explorers.
          const myUser = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/users/query", encodebody({ clientNr: user.clientNr, chatbotKey: user.chatbotKey, email: value}));
          const myUserData = getDecodedBody(myUser.data);
          myUserData.explorers = myUserData.explorers.filter(explorer => explorer.name !== user.explorerId);
          // Update the user to the backend
          myUserData.clientNr = user.clientNr;
          await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/users/update", encodebody(myUserData));
          startRefetching();
          alert(getTranslatedPlainText("pages.home.productTree.alerts.userDeletionSuccess"));
        } catch (err) {
          if (err.response) {
            // The request was made and the server responded with a status code that is not in the range of 2xx
            alert(getTranslatedPlainText("pages.home.productTree.alerts.userDeletionError", { error: getDecodedBody(err.response.data) }));
          } else if (err.request) {
            // The request was made but no response was received
            console.error("API Error: No response received");
            alert(getTranslatedPlainText("pages.home.productTree.alerts.userDeletionNoResponseError"));
          } else {
            // Something happened in setting up the request that triggered an Error
            alert(getTranslatedPlainText("pages.home.productTree.alerts.userDeletionError", { error: getDecodedBody(err.message) }));
          }
        }
        break;

      case "deleteExplorer":
        try {
          await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/workspace/delete", encodebody({ clientNr: user.clientNr, explorerId: value, chatbotKey: user.chatbotKey, email: user.email }));
          alert(getTranslatedPlainText("pages.home.productTree.alerts.workspaceDeletionSuccess"));
          window.location.reload();
        } catch (err) {
          if (err.response) {
            // The request was made and the server responded with a status code that is not in the range of 2xx
            alert(getTranslatedPlainText("pages.home.productTree.alerts.workspaceDeletionError", { error: getDecodedBody(err.response.data) }));
          } else if (err.request) {
            // The request was made but no response was received
            alert(getTranslatedPlainText("pages.home.productTree.alerts.workspaceDeletionNoResponseError"));
          } else {
            // Something happened in setting up the request that triggered an Erro
            alert(getTranslatedPlainText("pages.home.productTree.alerts.workspaceDeletionError", { error: getDecodedBody(err.message) }));
          }
        }
        break;

      case "explorer":
        // Check if workspace exists (it could have been deleted by another user)
        let myExplorerId = value;
        const myUser = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/users/query", encodebody({clientNr: user.clientNr, chatbotKey: user.chatbotKey, email: user.email}));

        let myExplorers = getDecodedBody(myUser.data).explorers;
        const myworkspacePayload = { clientNr, explorerId: myExplorerId }
        try {
          await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/workspace/exist", encodebody(myworkspacePayload));
        } catch(err) {
          alert(getTranslatedPlainText("pages.home.productTree.alerts.noAccessToWorkspaceError"));
          // remove workspace from user.explorers

          let filteredExplorers = myExplorers.filter(explorer => explorer !== myExplorerId);
          myExplorers = filteredExplorers;
           // the user does not have access to any other workspaces
          if (myExplorers.length === 0) {
            localStorage.removeItem("user");
            alert(getTranslatedPlainText("pages.home.productTree.alerts.noWorkspacesLeftError"));
            window.location.reload();
            break;
          }
        }

        const myNewUserSetting = {
          ...user,
          explorers: myExplorers,
          explorerId: myExplorerId
        }
        const userSettingsString = JSON.stringify(myNewUserSetting);
        localStorage.setItem("user", userSettingsString);

        // reload the page
        window.location.reload();
        break;

      case "exportproducts":
        setIsExportProductsModalOpen(true);
        break;

      case "exportguides":
        setIsExportGuidesModalOpen(true);
        break;

      case "importproducts":
        setIsImportProductsModalOpen(true);
        break;

      case "helpdesk":
        const jiraServiceDeskUrl = "https://customers.support.gwocu.com";
        window.open(jiraServiceDeskUrl, "_blank", "noopener,noreferrer");
        // setIsServicedDeskModalOpen(true);
        break;

      case "workspace-action":
        setIsConfigurationModalOpen(true);
        break;

      case "api-action":
        setIsThirdpartiesOpen(true);
        break;

      case "webhooks":
        setIsWebhooksOpen(true);
        break;

      case "trainyourchatbots":
        setIsEmbeddingsManagerOpen(true)
        break;

      default:
        break;
    }
  };

  const handleSelectMenuItem = (menuItem) => {
    switch (menuItem) {
      case "javascript":
      case "python":
        if (selectedItemType === "api"|| selectedItemType === "taskapi" || selectedItemType === "apicompliance") {
          setSelectedItemType("apicode");
        } else if (selectedItemType === "workflow" || selectedItemType === "workflowcompliance"|| selectedItemType === "workflowterminal") {
          setSelectedItemType("workflowcode");
        }
        setCodeType(menuItem);
        break;

      case "cURL":
        if (selectedItemType === "api" || selectedItemType === "apicode" || selectedItemType === "taskapi" || selectedItemType === "apicompliance" ) {
          setSelectedItemType("api");
        }
        break;

      case "description":
        if (selectedItemType === "api" || selectedItemType === "apicode" || selectedItemType === "taskapi" || selectedItemType === "apicompliance"  ) {
          setSelectedItemType("taskapi");
        } else if (selectedItemType === "task" || selectedItemType === "taskcompliance") {
          setSelectedItemType("task");
        } else if (selectedItemType === "workflow" || selectedItemType === "workflowcode"  || selectedItemType === "workflowcompliance" || selectedItemType === "workflowterminal") {
          setSelectedItemType("workflow");
        } else if (selectedItemType === "product" || selectedItemType === "productcompliance" ) {
          setSelectedItemType("product");
        }
        break;

      case "compliancedescription":
        if (selectedItemType === "api" || selectedItemType === "apicode" || selectedItemType === "taskapi" ) {
          setSelectedItemType("apicompliance");
        } else if (selectedItemType === "task" ) {
          setSelectedItemType("taskcompliance");
        } else if (selectedItemType === "workflow" || selectedItemType === "workflowcode" || selectedItemType === "workflowterminal") {
          setSelectedItemType("workflowcompliance");
        } else if (selectedItemType === "product" || selectedItemType === "productcompliance" ) {
          setSelectedItemType("productcompliance");
        }
        break;

      case "workflowterminal":
        if (selectedItemType === "workflow" || selectedItemType === "workflowcode" || selectedItemType === "workflowterminal" || selectedItemType === "workflowcompliance") {
          setSelectedItemType("workflowterminal");
        }
        break;

      case "export-openapi":
        if (selectedItemType === "api"|| selectedItemType === "apicode" || selectedItemType === "taskapi" || selectedItemType === "apicompliance") {
          exportApiOpenApi(selectedApi);
        } else if (selectedItemType === "workflow" || selectedItemType === "workflowcode"  || selectedItemType === "workflowcompliance" || selectedItemType === "workflowterminal" ) {
        exportWorkflowOpenApi(selectedWork);
        } else if (selectedItemType === "product" || selectedItemType === "productcompliance" ) {
          exportProductOpenApi(selectedProduct);
        }
        break;

      default:
        break;
    }
  };

  const handleSelectedItemChange = (newselectItem, newProductName, newWorkflowName, newApiName, newTaskId) => {
    setSelectedItemType(newselectItem);
    setSelectedProduct(newProductName);
    setSelectedTaskId(newTaskId);
    setSelectedApi(newApiName);
    setSelectedWorkflow(newWorkflowName);
    setSelectedLink(null);
  };

  const handleSelectedLinkChange = (newselectItem, newProductName, newWorkflowName, newLink) => {
    setSelectedItemType(newselectItem);
    setSelectedProduct(newProductName);
    setSelectedWorkflow(newWorkflowName);
    setSelectedLink(newLink);
    setSelectedTaskId(null);
    setSelectedApi(null);
  };

  const handleSelectedWorkflowChange = (newselectItem, newProductName, newWorkflowName, newWorkflow) => {
    // if (newWorkflowName === selectedWork && newselectItem !== "workflowterminal") return;
    setSelectedItemType(newselectItem);
    setSelectedProduct(newProductName);
    setSelectedWorkflow(newWorkflowName);
    setSelectedLink(null);
    setSelectedTaskId(null);
    setSelectedApi(null);
  };

  const handleProductClick = (product) => {
    setSelectedItemType("product"); // Set the selected item type to "product"
    setSelectedProduct(product); // Set the selected product
    setSelectedWorkflow(null);
  };

  const handleWorkflowClick = (workflow, product) => {
    setSelectedItemType("workflow");
    setSelectedWorkflow(workflow);
    setSelectedProduct(product);
    setSelectedTaskId(null);
    setSelectedLink(null);
    setSelectedApi(null);
  };

  useEffect(() => {
    // Fetch the initial products using an API call
    // Replace this with your actual API endpoint
    if (fetchingAuthorization) return;
    fetchProducts();
  }, [newTreeItem, authorization, fetchingAuthorization]);

  const fetchProducts = async () => {
    const status = readOnly ? "Public" : "All";

    try {
      const getProductsPayload = { clientNr, explorerId, status };

      // Make the API call using axios and parse the response as JSON
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/product/gettree", encodebody(getProductsPayload));
      const products = getDecodedBody(response.data);

      // Set the data state variable with the JSON data
      setProducts(products);
      // on first fetch set the productname
      if (products.length > 0) {
        setSelectedProduct(products[0].name);
        setSelectedItemType("product");
      } else {
        setSelectedItemType(null);
      }
    } catch (error) {
      // Handle any errors
      console.log(error);
    }
  };

  const renderTree = (nodes, isChild) => {
    return nodes.map((node, index) => (
      <TreeNode
        key={index}
        label={node.name}
        isChild={isChild}
        onProductClick={handleProductClick}
        topLevelClick={isChild ? undefined : () => handleProductClick(node.name)}
      >
        {node.workflows.map((workflow, wIndex) => (
          <div
            key={wIndex}
            className="workflow"
            onClick={() => handleWorkflowClick(workflow, node.name)} // Handle workflow click
          >
            {workflow}
          </div>
        ))}
      </TreeNode>
    ));
  };

  const handleApiEditorClick = () => {
    history.push("/apiseditor");
  };

  return (
    <div className="product-tree">
      <section className="product-tree__section product-tree__section--left">
        <header className="product-tree__section-toolbar-container">
          <Toolbar>
          {!readOnly && (
            <>
              <Toolbar.Button onClick={openProductModal}>
                <TranslationLoader
                  type="button"
                  translationKey="pages.home.productTree.toolbar.buttons.addProduct"
                  fallbackText="Add Product"
                />
              </Toolbar.Button>
              <Toolbar.Button onClick={openWorkflowModal}>
                <TranslationLoader
                  type="button"
                  translationKey="pages.home.productTree.toolbar.buttons.addWorkflow"
                  fallbackText="Add Workflow"
                />
              </Toolbar.Button>
            </>
          )}
            <Toolbar.Button onClick={handleApiEditorClick}>
              <TranslationLoader
                type="button"
                translationKey="pages.home.productTree.toolbar.buttons.apiManager"
                fallbackText="API Manager"
              />
            </Toolbar.Button>
            <Toolbar.Item>
              <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel#treebuttons-section">
                <HelpCenterIcon />
              </Icon>
            </Toolbar.Item>
            <Toolbar.Item>
              <div className="product-tree__menu--left-container">
                <TreeMenu
                  onSelectTreeMenuItem={handleSelectTreeMenuItem}
                  refetching={shouldRefetch}
                  finishRefetching={finishRefetching}
                />
              </div>
            </Toolbar.Item>
          </Toolbar>
        </header>
        <div className="product-tree__section-container">
          {renderTree(products, false)}
        </div>
      </section>
      <section className="product-tree__section product-tree__section--middle">
        <div className="section-middle__top">
          <Graphview
            clientNr={clientNr}
            explorerId={explorerId}
            selectedProduct={selectedProduct}
            selectedWork={selectedWork}
            onTaskChange={handleSelectedItemChange}
            onWorkflowChange={handleSelectedWorkflowChange}
            onLinkChange={handleSelectedLinkChange}
            graphChange={newGraphItem}
            authorization={authorization}
            runningTaskId={executionState.runningTaskId}
            onApiRun={initializeApiTerminal}
            onWorkflowRun={initializeWorkflowTerminal}
          />
        </div>
        <div className="section-middle__bottom botpanel">
          <Chatbot
            clientNr={clientNr}
            explorerId={explorerId}
            authorization ={authorization}
          />
        </div>
      </section>
      <section className="product-tree__section product-tree__section--right">
        <div className="section-right__menu">
          <DetailsPanelMenu
            selectedItemType={selectedItemType}
            onSelectMenuItem={handleSelectMenuItem}
          />
        </div>
        {selectedItemType === "product" ?
          <Productview
            clientNr={clientNr}
            explorerId={explorerId}
            productName={selectedProduct}
            authorization={authorization}
            updateTreeView={() => setNewTreeItem(newTreeItem + 1)}
          />
        : null}
        {selectedItemType === "productcompliance" ?
          <Productcomplianceview
            clientNr={clientNr}
            explorerId={explorerId}
            productName={selectedProduct}
            authorization={authorization}
          />
          : null}
        {selectedItemType === "workflow" ?
          <Workflowview
            clientNr={clientNr}
            explorerId={explorerId}
            productName={selectedProduct}
            name={selectedWork}
            authorization={authorization}
          />
          : null}
        {selectedItemType === "workflowcompliance" ?
          <Workflowcomplianceview
            clientNr={clientNr}
            explorerId={explorerId}
            productName={selectedProduct}
            name={selectedWork}
            authorization={authorization}
          />
          : null}
        {selectedItemType === "workflowterminal" ?
          <TerminalContextProvider>
            <Workflowterminal
              clientNr={clientNr}
              explorerId={explorerId}
              name={selectedWork}
              onRunTask={updateRunningTask}
              onRestartWorkflow={resetRunningTask}
              onFinishWorkflow={clearExecutionState}
              tasksToRun={executionState.tasksToRun}
            />
          </TerminalContextProvider>
          : null}
        {(selectedItemType === "task" || selectedItemType === "taskapi") && selectedWork  ?
          <Taskview
            clientNr={clientNr}
            explorerId={explorerId}
            workflowName={selectedWork}
            taskId={selectedTaskId}
            authorization={authorization}
            updateGraphView={() => setNewGraphItem(newGraphItem + 1)}
          />
        : null}
        {selectedItemType === "taskcompliance" ||  selectedItemType === "apicompliance" ?
          <Taskcomplianceview
            clientNr={clientNr}
            explorerId={explorerId}
            workflowName={selectedWork}
            taskId={selectedTaskId}
            authorization={authorization}
          />
          : null}
        {selectedItemType === "link" ?
          <Linkview
            clientNr={clientNr}
            explorerId={explorerId}
            workflowName={selectedWork}
            mylink={selectedLink}
            authorization={authorization}
            updateGraphView={() => setNewGraphItem(newGraphItem + 1)}
          />
          : null}
        {selectedItemType === "api" && selectedApi ? (
          <TerminalContextProvider>
            <ApiTerminal
              clientNr={clientNr}
              explorerId={explorerId}
              workflowName={selectedWork}
              apiName={selectedApi}
              OnGetTask={() => selectedTaskId} // Pass a function that returns selectedTaskId so as to not cause a re render
              authorization={authorization}
              onFinishExecution={clearExecutionState}
              taskName={executionState.tasksToRun?.[selectedTaskId]}
            />
          </TerminalContextProvider>
        ) : null}
        {(selectedItemType ==="apicode") ?
          <ApiCode
            clientNr={clientNr}
            explorerId={explorerId}
            apiName={selectedApi}
            codeType={selectedCodeType}
          />
          : null}
        {(selectedItemType === "workflowcode") ?
          <WorkflowCode
            clientNr={clientNr}
            explorerId={explorerId}
            productName={selectedProduct}
            workflowName={selectedWork}
            codeType={selectedCodeType}
          />
          : null}
      </section>
        {isWorkflowModalOpen && (
          <Modalworkflow
            clientNr={clientNr}
            explorerId={explorerId}
            open={isWorkflowModalOpen}
            onClose={() => {
              setIsWorkflowModalOpen(false);
              setNewTreeItem(newTreeItem + 1);
            }}
          />
        )}
        {isProductModalOpen && (
          <Modalproduct
            clientNr={clientNr}
            explorerId={explorerId}
            open={isProductModalOpen}
            onClose={() => {
              setIsProductModalOpen(false);
              setNewTreeItem(newTreeItem + 1);
            }}
          />
        )}
        {showModalInvite && (
          <ModalPrivateInvitation
            clientNr={user.clientNr}
            open={showModalInvite}
            onClose={() => {
              setShowModalInvite(false);
              startRefetching();
            }}
          />
        )}
        {showModalPublicInvite && (
          <ModalPublicInvitation
            clientNr={clientNr}
            explorerId={explorerId}
            open={showModalPublicInvite}
            onClose={() => setShowModalPublicInvite(false)}
          />
        )}
        {isConfigurationModalOpen && (
          <Modalconfiguration
            clientNr={clientNr}
            explorerId={explorerId}
            open={isConfigurationModalOpen}
            onClose={() => setIsConfigurationModalOpen(false)}
          />
        )}
        {isThirdpartiesOpen && (
          <Thirdparties
            clientNr={clientNr}
            explorerId={explorerId}
            open={isThirdpartiesOpen}
            onClose={() => setIsThirdpartiesOpen(false)}
          />
        )}
        {isWebhooksOpen && (
          <Webhooks
            clientNr={clientNr}
            explorerId={explorerId}
            open={isWebhooksOpen}
            onClose={() => setIsWebhooksOpen(false)}
          />
        )}
        {isExportProductsModalOpen && (
          <ExportProducts
            clientNr={clientNr}
            explorerId={explorerId}
            open={isExportProductsModalOpen}
            onClose={() => setIsExportProductsModalOpen(false)}
          />
        )}
        {isImportProductsModalOpen && (
          <ImportProducts
            targetClientNr={clientNr}
            targetExplorerId={explorerId}
            open={isImportProductsModalOpen}
            onClose={() => setIsImportProductsModalOpen(false)}
          />
        )}
        {isExportGuidesModalOpen && (
          <ExportGuides
            clientNr={clientNr}
            explorerId={explorerId}
            open={isExportGuidesModalOpen}
            onClose={() => setIsExportGuidesModalOpen(false)}
          />
        )}
        {isEmbeddingsManagerOpen && (
          <EmbeddingsManager
            clientNr={clientNr}
            explorerId={explorerId}
            open={isEmbeddingsManagerOpen}
            onClose={() => setIsEmbeddingsManagerOpen(false)}
          />
        )}
        {/* {isServicedDeskModalOpen && (
          <JiraServiceDeskModal
            clientNr={clientNr}
            explorerId={explorerId}
            onClose={() => setIsServicedDeskModalOpen(false)}
          />
        )} */}
    </div>
  );
};
