export default function FolderIcon({ className, open }) {
  return open ? (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 800 800"
      fill="none"
      className={className}
    >
      <path
        fill="currentColor"
        d="M795.408 450.129L694.819 622.567C686.992 635.986 675.784 647.12 662.312 654.857C648.84 662.595 633.576 666.667 618.04 666.667H62.5347C36.8083 666.667 20.7792 638.76 33.7417 616.538L134.331 444.1C142.159 430.681 153.367 419.547 166.839 411.809C180.311 404.072 195.575 400 211.111 400H766.617C792.343 400 808.372 427.907 795.408 450.129ZM211.111 355.556H666.667V288.889C666.667 252.069 636.819 222.222 600 222.222H377.778L288.889 133.333H66.6667C29.8472 133.333 0 163.181 0 200V586.175L95.9403 421.706C119.742 380.903 163.874 355.556 211.111 355.556Z"
      />
    </svg>
  ) : (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 800 800"
      fill="none"
      className={className}
    >
      <path
        fill="currentColor"
        d="M725 200H425L325 100H75C33.5781 100 0 133.578 0 175V625C0 666.422 33.5781 700 75 700H725C766.422 700 800 666.422 800 625V275C800 233.578 766.422 200 725 200Z"
      />
    </svg>
  );
};
