import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { skeletonLoading } from "./animations";

const StyledTranslationLoader = styled.span`
  color: transparent;

  &.text-translation-loader {
    animation: ${skeletonLoading} 1s linear infinite alternate;
    opacity: var(--skeleton-opacity);
  }
`;

const SUFFIXES = {
  text: "",
  label: ".label",
  button: ".default",
  "menu-item": ".label",
};

/**
 * A placeholder component that displays while translations are loaded asynchronously.
 *
 * @param {Object} props - The properties for the component.
 * @param {string} props.translationKey - The key used to fetch the corresponding translation.
 * @param {"text" | "label" | "button" | "menu-item" | "html"} [props.type="text"] - The type of content being translated. Defaults value: "text".
 * @param {string} [props.fallbackText=""] - The text used to calculate placeholder size. It won't be displayed if the translation isn't found. Default value: "".
 * @param {Object} [props.interpolationObject={}] - An object with placeholder keys and their replacement values for dynamic interpolation in translation strings. Default value: {}
 *
 * @returns {JSX.Element} A placeholder element representing the translation in progress.
 */
export const TranslationLoader = ({
  translationKey,
  type = "text",
  fallbackText = "",
  interpolationObject = {},
}) => {
  const { t, ready } = useTranslation();
  const normalizedType = type.toLowerCase();
  const suffix = SUFFIXES[normalizedType] || "";
  const key = translationKey + suffix;

  if (normalizedType === "html") {
    return ready ? (
      <Trans i18nKey={key} values={interpolationObject} />
    ) : (
      <StyledTranslationLoader
        className={normalizedType === "button" ? undefined : "text-translation-loader"}
        aria-hidden="true"
      >
        {fallbackText}
      </StyledTranslationLoader>
    );
  }

  return ready ? (
    t(key, interpolationObject)
  ) : (
    <StyledTranslationLoader
      className={
        normalizedType === "button" ? undefined : "text-translation-loader"
      }
      aria-hidden="true"
    >
      {fallbackText}
    </StyledTranslationLoader>
  );
};
