import { useState, createRef } from "react";
import axios from "axios";
import jsYaml from "js-yaml";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import { Button, Modal, ProgressBar, TranslationLoader } from "../../UI";
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import "./fileupload.css";

export default function FileUpload({ clientNr, explorerId }) {
  const { getTranslatedPlainText } = useCustomTranslation();
  const file0InputRef = createRef();
  const fileInputRef = createRef();
  const file2InputRef = createRef();
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [confirmation0Open, setConfirmation0Open] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false); // State for confirmation dialog
  const [confirmation2Open, setConfirmation2Open] = useState(false);
  const [filename, setFilename] = useState("");
  const [files, setFiles] = useState(null); // State for uploaded files

  const handleCancelUpload = () => {
    // Close the confirmation dialog
    setConfirmation0Open(false);
    setConfirmationOpen(false);
    setConfirmation2Open(false);

    // Clear the file input when the user cancels
    file0InputRef.current.value = "";
    fileInputRef.current.value = "";
    file2InputRef.current.value = "";

    // Reset the files state
    setFiles(null);
  };

  const handleFileChange = () => {
    const selectedFiles = fileInputRef.current.files;
    console.log("IN POSTMAN CHANGE");

    if (selectedFiles.length === 0) return;

    const selectedFilename = selectedFiles[0].name;
    setFilename(selectedFilename);
    // Display confirmation dialog before uploading
    setConfirmationOpen(true);
    setConfirmation0Open(false);
    setConfirmation2Open(false);
    // Set the selected files in the state
    setFiles(selectedFiles[0]);
  };

  const handleFile2Change = () => {
    const selectedFiles = file2InputRef.current.files;

    if (selectedFiles.length === 0) return;

    const selectedFilename = selectedFiles[0].name;
    setFilename(selectedFilename);
    // Display confirmation dialog before uploading
    setConfirmation0Open(false);
    setConfirmation2Open(true);
    setConfirmationOpen(false);
    // Set the selected files in the state
    setFiles(selectedFiles[0]);
  };

  const handleFile0Change = () => {
    console.log("IN FILE Studio CHANGE");
    const selectedFiles = file0InputRef.current.files;

    if (selectedFiles.length === 0) return;

    const selectedFilename = selectedFiles[0].name;
    setFilename(selectedFilename);
    // Display confirmation dialog before uploading
    setConfirmation0Open(true);
    setConfirmation2Open(false);
    setConfirmationOpen(false);
    // Set the selected files in the state
    setFiles(selectedFiles[0]);
  };

  const confirmUpload = () => {
    // Close the confirmation dialog
    setConfirmationOpen(false);

    // Set progress to 25% before uploading
    setProgress(25);
    setShowProgressBar(true); // Show the progress bar

    const formData = new FormData();
    formData.append("clientNr", clientNr);
    formData.append("explorerId", explorerId);
    formData.append("file", files);

    axios
      .post(process.env.REACT_APP_CENTRAL_BACK + "/uploadapidef", formData)
      .then(() => {
        const myfilename = files.name;

        // Set progress to 25% before calling API
        setProgress(50);
        callAPI(clientNr, explorerId, process.env.REACT_APP_HOST_CENTRAL_BACK + "/upload/postmandef/" + clientNr + "/" + explorerId + "/" + myfilename);
      })
      .catch((err) => {
        const error = err.response ? JSON.stringify(getDecodedBody(err.response.data)) : err.message;
        alert(getTranslatedPlainText("modals.fileUpload.alerts.fileUploadError", { error }));
      });
  };

  function callAPI(clientNr, explorerId, url) {
    const originalbody = {
      clientNr,
      explorerId,
      url
    };

    axios
      .post(process.env.REACT_APP_CENTRAL_BACK + "/apiimport/postman", encodebody(originalbody))
      .then(() => {
        setProgress(90);
        alert(getTranslatedPlainText("modals.fileUpload.alerts.uploadSuccess"));
        setShowProgressBar(false);
        window.location.reload();
      })
      .catch((err) => {
        const error = err.response ? JSON.stringify(getDecodedBody(err.response.data)) : err.message;
        alert(getTranslatedPlainText("modals.fileUpload.alerts.fileUploadError", { error }));
      });
  }

  const confirmUpload2 = () => {
    // Close the confirmation dialog
    setConfirmation2Open(false);

    // Set progress to 25% before uploading
    setProgress(25);
    setShowProgressBar(true); // Show the progress bar

    const formData = new FormData();

    formData.append("clientNr", clientNr);
    formData.append("explorerId", explorerId);
    formData.append("file", files);

    axios
      .post(process.env.REACT_APP_CENTRAL_BACK + "/uploadapidef2", formData)
      .then(() => {
        const myfilename = files.name;
        // Set progress to 25% before calling API
        setProgress(50);
        callAPI2(clientNr, explorerId, process.env.REACT_APP_HOST_CENTRAL_BACK + "/upload/swaggerdef/" + clientNr + "/" + explorerId + "/" + myfilename);
      })
      .catch((err) => {
        const error = err.response ? JSON.stringify(getDecodedBody(err.response.data)) : err.message;
        alert(getTranslatedPlainText("modals.fileUpload.alerts.fileUploadError", { error }));
      });
  };

  const cleanObject = (obj) => {
      const newObj = { ...obj };
      delete newObj._id;
      delete newObj.__v;
      delete newObj.createdAt;
      delete newObj.updatedAt;
      newObj.clientNr = clientNr;
      newObj.explorerId = explorerId;
      return newObj;
  };

  const confirmUpload0 = async () => {
    setConfirmation0Open(false);

      if (!files) return;
      setProgress(25);
      setShowProgressBar(true);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        const data = jsYaml.load(text);

        // Process and register APIs
        if (data.apis && data.apis.length > 0) {
          for (const api of data.apis) {
            const cleanedApi = cleanObject(api);
            try {
              await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/api/query", encodebody({
                name: cleanedApi.name,
                clientNr,
                explorerId
              }));
            } catch (error) {
              try {
                await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/api/register", encodebody(cleanedApi));
              } catch (err) {
                console.log("Error while saving API:", err);
              }
            } finally {
              setProgress(50);
            }
          }
        }

        try {
          await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/folder/sync", encodebody({ clientNr, explorerId }));
        } catch (err) {
          console.log("Could not SYNC APIs:", err);
        }

        setProgress(100);
        setTimeout(() => {
          setProgress(0);
          alert(getTranslatedPlainText("modals.fileUpload.alerts.apisImportSuccess"));
          window.location.reload();
        }, 500);
      };

    reader.readAsText(files);
  };

  function callAPI2(clientNr,explorerId, url) {
    const originalbody = {
      clientNr,
      explorerId,
      url
    };

    axios
      .post(process.env.REACT_APP_CENTRAL_BACK + "/apiimport/openapi", encodebody(originalbody))
      .then(() => {
        setProgress(90);
        alert(getTranslatedPlainText("modals.fileUpload.alerts.uploadSuccess"));
        setShowProgressBar(false);
        window.location.reload();
      })
      .catch((err) => {
        const error = err.response ? JSON.stringify(getDecodedBody(err.response.data)) : err.message;
        alert(getTranslatedPlainText("modals.fileUpload.alerts.fileUploadError", { error }));
      });
  }

  return (
    <>
      <div className="uploadBox">
        <div className="uploadelements">
          <label htmlFor="file0" className="my-custom-file-label">
            <TranslationLoader
              type="label"
              translationKey="modals.fileUpload.inputs.uploadStudioApis"
              fallbackText="Upload Studio APIs"
            />
          </label>
          <input
            type="file"
            id="file0"
            name="file0"
            className="fileInput"
            acceptCharset="utf-8"
            ref={file0InputRef}
            onChange={handleFile0Change} // Trigger file upload on change
          />
          <label htmlFor="file" className="my-custom-file-label">
            <TranslationLoader
              type="label"
              translationKey="modals.fileUpload.inputs.uploadPostmanApis"
              fallbackText="Upload Postman APIs v2.1"
            />
          </label>
          <input
            type="file"
            id="file"
            name="file"
            className="fileInput"
            acceptCharset="utf-8"
            ref={fileInputRef}
            onChange={handleFileChange} // Trigger file upload on change
          />
          <label htmlFor="file2" className="my-custom-file-label">
            <TranslationLoader
              type="label"
              translationKey="modals.fileUpload.inputs.uploadOpenApiDefinitions"
              fallbackText="Upload OpenAPI APIs v3.1.0"
            />
          </label>
          <input
            type="file"
            id="file2"
            name="file2"
            className="fileInput"
            acceptCharset="utf-8"
            ref={file2InputRef}
            onChange={handleFile2Change} // Trigger file upload on change
          />
        </div>
        {showProgressBar && (
          <div className="file-upload__progress-bar">
            <ProgressBar progress={progress} />
          </div>
        )}
      </div>
        <Modal open={confirmationOpen} onClose={handleCancelUpload} maxWidth="40rem">
          <Modal.Title>
            <TranslationLoader
              translationKey="modals.fileUpload.heading"
              fallbackText="Confirm Upload"
            />
          </Modal.Title>
          <Modal.Body className="confirm-upload__body">
            <p>
              <b>
                <TranslationLoader
                  translationKey="modals.fileUpload.confirmPostmanApisUpload"
                  fallbackText="Are you sure, you want to upload the Postman collection?"
                />
              </b>
            </p>
            <p>
              <TranslationLoader
                translationKey="modals.fileUpload.overwriteWarning"
                fallbackText="If there are API definitions with the same name, it will overwrite them."
              />
            </p>
            <p><b>{filename}</b></p>
          </Modal.Body>
          <Modal.Footer>
            <Button color="secondary" onClick={handleCancelUpload}>Cancel</Button>
            <Button onClick={confirmUpload}>Upload</Button>
          </Modal.Footer>
        </Modal>

        <Modal open={confirmation0Open} onClose={handleCancelUpload} maxWidth="40rem">
          <Modal.Title>
            <TranslationLoader
              translationKey="modals.fileUpload.heading"
              fallbackText="Confirm Upload"
            />
          </Modal.Title>
          <Modal.Body className="confirm-upload__body">
            <p>
              <b>
                <TranslationLoader
                  translationKey="modals.fileUpload.confirmStudioApisUpload"
                  fallbackText="Are you sure, you want to upload the Studio API collection?"
                />
              </b>
            </p>
            <p>
              <TranslationLoader
                translationKey="modals.fileUpload.overwriteWarning"
                fallbackText="If there are API definitions with the same name, it will overwrite them."
              />
            </p>
            <p><b>{filename}</b></p>
          </Modal.Body>
          <Modal.Footer>
            <Button color="secondary" onClick={handleCancelUpload}>
              <TranslationLoader
                type="button"
                translationKey="modals.fileUpload.buttons.cancel"
                fallbackText="Cancel"
              />
            </Button>
            <Button onClick={confirmUpload0}>
              <TranslationLoader
                type="button"
                translationKey="modals.fileUpload.buttons.upload"
                fallbackText="Upload"
              />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal open={confirmation2Open} onClose={handleCancelUpload} maxWidth="40rem">
          <Modal.Title>
            <TranslationLoader
              translationKey="modals.fileUpload.heading"
              fallbackText="Confirm Upload"
            />
          </Modal.Title>
          <Modal.Body className="confirm-upload__body">
            <p>
              <b>
                <TranslationLoader
                  translationKey="modals.fileUpload.confirmOpenApiDefinitionsUpload"
                  fallbackText="Are you sure, you want to upload the OpenAPI 3.0 definitions?"
                />
              </b>
            </p>
            <p>
              <TranslationLoader
                translationKey="modals.fileUpload.overwriteWarning"
                fallbackText="If there are API definitions with the same name, it will overwrite them."
              />
            </p>
            <p><b>{filename}</b></p>
          </Modal.Body>
          <Modal.Footer>
            <Button color="secondary" onClick={handleCancelUpload}>
              <TranslationLoader
                type="button"
                translationKey="modals.fileUpload.buttons.cancel"
                fallbackText="Cancel"
              />
            </Button>
            <Button onClick={confirmUpload2}>
              <TranslationLoader
                type="button"
                translationKey="modals.fileUpload.buttons.upload"
                fallbackText="Upload"
              />
            </Button>
          </Modal.Footer>
        </Modal>
    </>
  );
}
