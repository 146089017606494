import axios from "axios"
import { encodebody, getDecodedBody } from "../utils/utils"

const BASE_URL = process.env.REACT_APP_CENTRAL_BACK

const ApiManagerService = {}

ApiManagerService.getTreeItems = async ({ clientNr, explorerId }) => {
  const payload = { clientNr, explorerId }
  const { data } = await axios.post(BASE_URL + "/folder/query", encodebody(payload))
  return getDecodedBody(data)?.items
}

ApiManagerService.updateTreeItems = async ({ clientNr, explorerId, items }) => {
  const payload = { clientNr, explorerId, items }
  await axios.post(BASE_URL + "/folder/update", encodebody(payload));
}

ApiManagerService.getApi = async ({ clientNr, explorerId, apiName: name }) => {
  const payload = { clientNr, explorerId, name }
  const { data } = await axios.post(BASE_URL + "/api/query", encodebody(payload))
  return getDecodedBody(data)
}

ApiManagerService.createApi = async ({ clientNr, explorerId, apiName }) => {
  const payload = {
    clientNr,
    explorerId,
    name: apiName,
    description: "none",
    urlRoute: "https://",
    resourcePath: "https://",
    headers: [],
    method: "POST",
    requestBodyType: "JSON",
    responseBodyType: "JSON"
  }

  await axios.post(BASE_URL + "/api/register", encodebody(payload));
}

ApiManagerService.copyApi = async ({ clientNr, explorerId, apiToCopy, newApiName }) => {
  const payload = {
    clientNr,
    explorerId,
    apiToCopy,
    newApiName
  }

  await axios.post(BASE_URL + "/api/copy", encodebody(payload));
}

ApiManagerService.renameApi = async ({ clientNr, explorerId, oldName, newName }) => {
  const payload = {
    clientNr,
    explorerId,
    oldName,
    newName
  }

  await axios.post(BASE_URL + "/api/changename", encodebody(payload));
}

ApiManagerService.updateApi = async (payload) => {
  await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/api/update", encodebody(payload));
}

ApiManagerService.deleteApi = async ({ clientNr, explorerId, apiName }) => {
  const payload = {
    clientNr,
    explorerId,
    name: apiName
  }

  await axios.post(BASE_URL + "/api/delete", encodebody(payload));
}

export default ApiManagerService;
