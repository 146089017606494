import React, { useEffect, useRef, useState } from "react";
import Tippy from "@tippyjs/react";
import styled from "styled-components";
import "tippy.js/themes/material.css";
import "tippy.js/dist/tippy.css";

const StyledTooltip = styled(Tippy)`
  &.tippy-box {
    background-color: var(--tooltip-bg-clr);
    color: var(--tooltip-txt-clr);
  }

  & .tippy-arrow {
    color: var(--tooltip-arrow-clr);
  }

  & .tippy-content * {
    max-height: 80vh;
    padding: revert;
    overflow-y: auto;
  }

  & ul {
    list-style-position: inside;
  }
`;

/**
 * Gwocu's tooltip component
 *
 * The `children` passed to this component must be able to receive refs.
 *
 * @component
 * @param {Object} props - The component's props
 * @param {React.ReactNode} props.children - The content wrapped by the tooltip. This must be able to receive refs.
 * @param {boolean} [props.hideTooltip=false] - If `true`, the tooltip won't show when hovering the component. Default value: `false`.
 * @param {boolean} [props.interactive=false] - If `true` the tooltip can be hovered over or clicked without hiding. Default value: `false`.
 * @param {"top" | "left" | "right" | "bottom"} [props.placement="top"] - Sets the position of the tooltip relative to its content. Default value: `"top"`.
 * @param {"mouseenter" | "focus" | "click" | "manual"} [props.trigger="mouseenter focus"] - The events (each separated by a space) which cause a tooltip to show. Default values: `"mouseenter focus"`.
 * @param {number} [props.showDelay=0] - Delay in milliseconds before the tooltip is shown after a trigger event. Default value: `0`.
 * @param {number} [props.hideDelay=0] - Delay in milliseconds before the tooltip is hidden after a trigger event. Default value: `0`.
 * @param {Object} props.tooltip - The content for the tooltip.
 * @param {string} props.tooltip.content - The content of the tooltip. It can be a string with HTML or plain text.
 * @param {boolean} props.tooltip.isHtml - Whether the content is HTML.
 * @returns {JSX.Element} The rendered content with its tooltip.
 *
 * @example
 * <Tooltip
 *   tooltip={{
 *     content: "<h1>I'm a clickable tooltip</h1>",
 *     isHtml: true
 *   }}
 *   placement="bottom"
 *   interactive={true}
 *  >
 *   <div>Component that receives refs</div>
 * </Tooltip>
 */
export const Tooltip = ({
  children,
  showDelay = 0,
  hideDelay = 0,
  hideTooltip = false,
  interactive = false,
  placement: initialPlacement = "top",
  tooltip,
  trigger = "mouseenter focus",
  visible,
}) => {
  const ref = useRef(null);
  const [placement, setPlacement] = useState(initialPlacement);

  const updatePlacement = () => {
    if (window.innerWidth <= 745) {
      setPlacement("top");
    } else {
      setPlacement(initialPlacement);
    }
  };

  useEffect(() => {
    updatePlacement();
    window.addEventListener("resize", updatePlacement);
    return () => window.removeEventListener("resize", updatePlacement);
  }, [initialPlacement]);

  if(hideTooltip) return children;

  if (interactive) {
    return (
      <StyledTooltip
        placement={placement}
        appendTo={document.body}
        content={
          tooltip.isHtml ? (
            <div
              dangerouslySetInnerHTML={{ __html: tooltip.content }}
              ref={ref}
            />
          ) : (
            <div ref={ref}>{tooltip.content}</div>
          )
        }
        trigger={trigger}
        interactive={interactive}
        arrow={true}
        delay={[showDelay, hideDelay]}
      >
        {children}
      </StyledTooltip>
    );
  }
  return (
    <StyledTooltip
      placement={placement}
      content={
        tooltip.isHtml ? (
          <div
            dangerouslySetInnerHTML={{ __html: tooltip.content }}
            ref={ref}
          />
        ) : (
          <div ref={ref}>{tooltip.content}</div>
        )
      }
      trigger={trigger}
      visible={visible}
      arrow={true}
      delay={[showDelay, hideDelay]}
    >
      {children}
    </StyledTooltip>
  );
};
