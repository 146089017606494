import { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext.js";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import { Button, Form, Icon, InputText, Modal, Select, Tooltip, TranslationLoader } from "../../UI/index.js";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { encodebody, getDecodedBody } from "../../utils/utils.js";

export default function ModalPrivateInvitation({ clientNr, open, onClose }) {
  const { getTranslatedPlainText, getTranslatedSelectOptions, getTranslatedTooltipConfig } = useCustomTranslation();
  const ROLE_OPTIONS = getTranslatedSelectOptions("modals.privateInvitation.inputs.userRole");
  const { user } = useContext(AuthContext);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedAuth, setSelectedAuth] = useState("reader");

  const handleEmailChange = (event) => {
    setSelectedEmail(event.target.value);
  };

  const handleAuthChange = (event) => {
    setSelectedAuth(event.target.value);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (selectedEmail) {
      let explorers = [] ;
      const myExplorer = {
        name: user.explorerId,
        designer: selectedAuth === "designer",
        owner: selectedAuth === "owner",
        reader: selectedAuth === "reader",
      }
      explorers.push(myExplorer);
      await handleSendEmail(user.chatbotKey, selectedEmail,explorers);
    }

    onClose();
  };

  async function handleSendEmail(chatbotKey, email, explorers) {
    try {
      const clientResponse = await axios.post(`${process.env.REACT_APP_CENTRAL_BACK}/clients/query`, encodebody({ clientNr }));
      const mypayload = {
        toEmail: email,
        url: process.env.REACT_APP_FROND_END + "/acceptinvite",
        client: clientResponse.data,
        clientNr,
        explorers,
        chatbotKey,
      };

      await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/invitation/invite", encodebody(mypayload));
      alert(getTranslatedPlainText("modals.privateInvitation.alerts.invitationSuccess"));
      return true;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code that is not in the range of 2xx
        alert(getTranslatedPlainText("modals.privateInvitation.alerts.invitationError", { error: getDecodedBody(error.response.data) }));
      } else if (error.request) {
        // The request was made but no response was received
        alert(getTranslatedPlainText("modals.privateInvitation.alerts.invitationNoResponseError"));
      } else {
        // Something happened in setting up the request that triggered an Error
        alert(getTranslatedPlainText("modals.privateInvitation.alerts.invitationError", { error: getDecodedBody(error.message) }));
      }
      return false;
    }
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="48rem">
      <Modal.Title>
        <TranslationLoader
          translationKey="modals.privateInvitation.heading"
          fallbackText="Send Invitation"
        />
        <Tooltip
          tooltip={getTranslatedTooltipConfig("modals.privateInvitation.buttons.help")}
          placement="right"
          trigger="click"
          interactive
        >
          <Icon size="sm" interactive>
            <HelpCenterIcon />
          </Icon>
        </Tooltip>
      </Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleSave}>
          <Form.Control>
            <Form.Label htmlFor="email">
              <TranslationLoader
                type="label"
                translationKey="modals.privateInvitation.inputs.email"
                fallbackText="Email"
              />
            </Form.Label>
            <InputText
              type="email"
              id="email"
              value={selectedEmail}
              onChange={handleEmailChange}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="userRole">
              <TranslationLoader
                type="label"
                translationKey="modals.privateInvitation.inputs.userRole"
                fallbackText="User Role"
              />
            </Form.Label>
            <Select
              id="userRole"
              value={selectedAuth}
              onChange={handleAuthChange}
              options={ROLE_OPTIONS}
            />
          </Form.Control>
          <Form.Row>
            <Button color="secondary" onClick={onClose}>
              <TranslationLoader
                type="button"
                translationKey="modals.privateInvitation.buttons.cancel"
                fallbackText="Cancel"
              />
            </Button>
            <Button type="submit">
              <TranslationLoader
                type="button"
                translationKey="modals.privateInvitation.buttons.sendInvitation"
                fallbackText="Send Invitation"
              />
            </Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
