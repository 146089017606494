import ProductTree from "../../components/producttree/ProductTree";
import Topbar from "../../components/topbar/Topbar";
import "./explorer.css";

export default function Explorer({ clientNr, explorerId, authorization }) {
  return (
    <div className="explorer">
      <header className="explorer__header">
        <Topbar />
      </header>
      <main className="explorer__main">
        <ProductTree
          authorization={authorization}
          clientNr={clientNr}
          explorerId={explorerId}
        />
      </main>
    </div>
  );
}
