import { useState, useEffect, useMemo, useRef, useContext } from "react";
import { TerminalContextProvider } from "react-terminal";
import { nanoid } from "nanoid";
import { AuthContext } from "../../context/AuthContext";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import Topbar from "../../components/topbar/Topbar";
import APIDetails from "../../components/apidetails/APIDetails";
import MyFolderTree from "../../components/myfoldertree/MyFolderTree";
import KeyboardShortcutsView from "../../components/myfoldertree/KeyboardShortcutsView/KeyboardShortcutsView";
import TreeViewSkeleton from "../../components/myfoldertree/TreeViewSkeleton/TreeViewSkeleton";
import { CustomDataProviderImpl } from "../../components/myfoldertree/CustomDataProviderImpl.js";
import ApiManagerService from "../../services/apiManagerService.js";
import "./apisEditor.css";

export default function ApisEditor({ clientNr, explorerId, authorization }) {
  const { getTranslatedPlainText } = useCustomTranslation();
  const [focusedIndex , setFocusedIndex] = useState("root");
  const [fetching, setFetching] = useState({
    tree: true,
    api: false
  });
  const [apiData, setApiData] = useState(null);
  const tree = useRef();
  const [renamingEvent, setRenamingEvent] = useState({
    prevFocusedIndex: null,
    renaming: false,
  });
  const { user } = useContext(AuthContext);
  const isAdmin = user.isAdmin;
  const dataProvider = useMemo(() => new CustomDataProviderImpl(nanoid, isAdmin), []);
  const treeItems = dataProvider.getItems();
  const readOnly = !authorization.designer && !authorization.owner;

  useEffect(() => {
    setFetching(prevState => ({...prevState, tree: true}))

    ApiManagerService
    .getTreeItems({ clientNr, explorerId })
    .then((data) => dataProvider.setItems(data))
    .catch((error) => console.log("Error fetching data:", error))
    .finally(() => setFetching(prevState => ({...prevState, tree: false})))
  }, []);

  const startRenamingFromTree = () => setRenamingEvent({prevFocusedIndex: focusedIndex, renaming: true})

  const completeRenamingFromTree = () => {
    tree.current.focusItem(renamingEvent.prevFocusedIndex)
    setRenamingEvent({prevFocusedIndex: null, renaming: false})
  }

  function handleFocusItem(item) {
    if (!item) {
      setApiData(null);
      return setFocusedIndex(null)
    }

    // Visually highlights focused items
    const itemsToHighlight = [item.index]
    if(renamingEvent.renaming) {
      itemsToHighlight.push(renamingEvent.prevFocusedIndex);
    }
    tree.current.selectItems(itemsToHighlight)

    if(renamingEvent.renaming) return;

    if(item.isFolder) {
      setApiData(null);
      return setFocusedIndex(item.index)
    }

    setFetching(prevState => ({...prevState, api: true}))
    ApiManagerService
      .getApi({
        apiName: item.data,
        clientNr,
        explorerId,
      })
      .then((data) => {
        setApiData(data)
        setFocusedIndex(item.index)
      }).catch((error) => console.log("Error fetching data:", error))
      .finally(() => setFetching(prevState => ({...prevState, api: false})))
  }

  const handleSystemFlagToggle = async () => {
    if (!focusedIndex) {
      return alert(getTranslatedPlainText("pages.apisEditor.alerts.noApiSelectedToToggleSystemError"));
    }

    const item = treeItems[focusedIndex];
    if (item.isFolder) {
      return alert(getTranslatedPlainText("pages.apisEditor.alerts.noApiSelectedToToggleSystemError"));
    }

    dataProvider.toggleSystemFlag(focusedIndex);
    try {
      await ApiManagerService.updateTreeItems({ clientNr, explorerId, items: treeItems })
      await ApiManagerService.updateApi({...apiData, system: item.system})
    } catch (error) {
      console.log("Error fetching data:", error)
    }
  }

  async function handleRename(item, newName) {
    const oldName = item.data;
    const normalizedNewName = newName.trim();

    try {
      await dataProvider.renameItem(item, normalizedNewName);
      await ApiManagerService.updateTreeItems({
        items: dataProvider.getItems(),
        clientNr,
        explorerId,
      })

      if(!item.isFolder) {
        if(item.index === focusedIndex) {
          setApiData(prevData => ({...prevData, name: normalizedNewName}))
        }

        await ApiManagerService.renameApi({
          newName: normalizedNewName,
          clientNr,
          explorerId,
          oldName,
        })
      }
    } catch(error) {
      console.log(error)
    } finally {
      if(renamingEvent.renaming) completeRenamingFromTree();
    }
  }

  async function handleApiRenameFromDetailedView(newName) {
    const item = dataProvider.getItem(focusedIndex);
    await handleRename(item, newName);
  }

    return (
    <div className="apis-editor">
      <header className="apis-editor__header">
        <Topbar />
      </header>
      <main className="apis-editor__main">
        <section className="apis-editor__section apis-editor__sidebar">
          {
            fetching.tree
            ? <TreeViewSkeleton />
            : (
                <MyFolderTree
                  tree={tree}
                  dataProvider={dataProvider}
                  focusedIndex={focusedIndex}
                  onFocusItem={handleFocusItem}
                  onRenameItem={handleRename}
                  onStartRenamingItem={startRenamingFromTree}
                  onCompleteRenamingItem={completeRenamingFromTree}
                  onSystemFlagToggle={handleSystemFlagToggle}
                  clientNr={clientNr}
                  explorerId={explorerId}
                  isAdmin={isAdmin}
                  readOnly={readOnly}
                />
              )
          }
        </section>
        <section className="apis-editor__content-wrapper">
        {apiData || fetching.api ? ( // This line ensures APIDetails is rendered only if selectedApi has a value
          <div className="apis-editor__section apis-editor__section--details">
            <TerminalContextProvider>
              <APIDetails
                api={apiData || {}}
                loading={fetching.api}
                clientNr={clientNr}
                explorerId={explorerId}
                isNameInUse={(name) => dataProvider.isNameInUse(name, false)}
                onRename={handleApiRenameFromDetailedView}
                readOnly={readOnly}
              />
            </TerminalContextProvider>
          </div>
        ) : (
          <div className="apis-editor__section apis-editor__section--shortcuts">
            <KeyboardShortcutsView />
          </div>
        )}
        </section>
      </main>
    </div>
  );
};
