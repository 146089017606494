import { useState, useEffect } from "react"
import axios from "axios";
import { useTheme } from "../../context/ThemeContext";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { SyntaxHighlighter } from "../../UI";
import { generateJavaScriptCode, generatePythonCode, getDecodedBody, encodebody } from "../../utils/utils.js";

export default function ApiCode ({ clientNr, explorerId, apiName, codeType }) {
  const [explorer, setExplorer] = useState([]);
  const [requestBodyFields, setRequestBodyFields] = useState({});
  const [api, setApi] = useState(null);
  const [generatedCode, setGeneratedCode] = useState("");
  const { getTranslatedPlainText, language } = useCustomTranslation();
  const { isADarkTheme } = useTheme();

  useEffect(() => {
    fetchApi();
  }, [codeType]);

  useEffect(() => {
    if(!api) return;
    generateCode(api)
  }, [language])

  async function fetchApi() {
    try {
      const myApibody = {
        name: apiName,
        clientNr,
        explorerId
      };
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/api/query", encodebody(myApibody));
      const myApi = getDecodedBody(response.data);

      generateCode(myApi);
      setApi(myApi);

      const myExplorerbody = { clientNr, explorerId };
      const Eresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/explorer/query", encodebody(myExplorerbody));
      const myExplorer = getDecodedBody(Eresponse.data);
      setExplorer(myExplorer);

      if (myApi.requestBody) {
        const initialRequestBodyFields = { ...myApi.requestBody };
        setRequestBodyFields(initialRequestBodyFields);
      }
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };

  const generateCode = (api) => {
    let combinedPythonCode = getTranslatedPlainText("pages.home.apiCode.pythonIntroComment");
    let combinedJavaScriptCode = getTranslatedPlainText("pages.home.apiCode.javascriptIntroComment");

    if (codeType === "python") {
      const pythonCode = generatePythonCode(api, explorer, requestBodyFields, getTranslatedPlainText("pages.home.apiCode.apiNameComment"));
      combinedPythonCode += pythonCode;
    } else if (codeType === "javascript") {
      const javascriptCode = generateJavaScriptCode(api, explorer, requestBodyFields, getTranslatedPlainText("pages.home.apiCode.apiNameComment"));
      combinedJavaScriptCode += javascriptCode;
    }

    // Determine the combined code based on the selected codeType
    const combinedCode = codeType === "python" ? combinedPythonCode : combinedJavaScriptCode;

    // Set the combined code using setGeneratedCode
    setGeneratedCode(combinedCode);
  };

  return (
    <>
      <div className="section-right__toolbar-container section-right__toolbar-container--empty" />
      <div className="section-right__content">
        <div className="section-right__syntax-highlighter">
          <SyntaxHighlighter
            code={generatedCode}
            darkMode={isADarkTheme}
          />
        </div>
      </div>
    </>
    )
};
