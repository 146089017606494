import { useState, useEffect } from "react";
import axios from "axios";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { Button, Form, InputText, Modal, Select, Textarea, TranslationLoader } from "../../UI";
import { encodebody, getDecodedBody } from "../../utils/utils.js";

export default function Modalworkflow({ clientNr, explorerId, open, onClose }) {
  const { getTranslatedPlainText } = useCustomTranslation();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedWorkflowName, setSelectedWorkflowName] = useState(getTranslatedPlainText("modals.workflow.inputs.workflowName.default"));
  const [selectedWorkflowDescription, setSelectedWorkflowDescription] = useState(getTranslatedPlainText("modals.workflow.inputs.description.default"));
  const [selectedSequence, setSelectedSequence] = useState("1");

  useEffect(() => {
    const fetchData = async () => {
      // first query products for products listbox
      const myProductsPayload = { clientNr, explorerId };
      try {
        const productresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/product/queryall", encodebody(myProductsPayload));
        const productresponseData = getDecodedBody(productresponse.data);
        setProducts(productresponseData);

        if (productresponseData.length > 0) {
          setSelectedProduct(productresponseData[0].productName);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleNameChange = (event) => {
    setSelectedWorkflowName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setSelectedWorkflowDescription(event.target.value)
  }

  const handleSequenceChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input is a valid number
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      // If it's a valid number or an empty string, update the state
      setSelectedSequence(inputValue);
    }
    // If it's not a valid number, you can choose to do nothing or provide feedback to the user
    // For example, show an error message or prevent further action
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Perform save logic with selectedSource and selectedTarget
    // You can use these values to update your backend or state, as needed
    if (await handleCreateProduct(selectedProduct, selectedWorkflowName, selectedWorkflowDescription, selectedSequence)) {
      onClose();
    }
  };

  async function handleCreateProduct(productName, workflowName, workflowDescription, sequence) {
    try {
      const mypayload = {
        name: workflowName,
        description: workflowDescription,
        status: "Private",
        clientNr,
        explorerId,
        productName,
        sequence,
      };

      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/workflow/register", encodebody(mypayload));
      const responseData = getDecodedBody(response.data)
      // Check if the response indicates an error
      if (responseData && responseData.error) {
        // Display an alert with the error data
        alert(getTranslatedPlainText("modals.workflow.alerts.error", { error: responseData.error }));
        return false;
      }

      // setNodesAdded(nodesAdded + 1);
      return true;
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      console.error("An unexpected error occurred:", error);
      alert(getTranslatedPlainText("modals.workflow.alerts.unexpectedError"));
      return false;
    }
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="60rem">
      <Modal.Title>
        <TranslationLoader
          translationKey="modals.workflow.heading"
          fallbackText="Add New Workflow"
        />
      </Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleSave}>
          <Form.Control>
            <Form.Label htmlFor="products">
              <TranslationLoader
                type="label"
                translationKey="modals.workflow.inputs.product"
                fallbackText="Product"
              />
            </Form.Label>
            <Select
              id="products"
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
              options={products.map(({productName}) => ({label: productName, value: productName}))}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="workflowName">
              <TranslationLoader
                type="label"
                translationKey="modals.workflow.inputs.workflowName"
                fallbackText="Workflow Name"
              />
            </Form.Label>
            <InputText
              id="workflowName"
              value={selectedWorkflowName}
              onChange={handleNameChange}
              />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="sequence">
              <TranslationLoader
                type="label"
                translationKey="modals.workflow.inputs.sequence"
                fallbackText="Sequence in product tree"
              />
            </Form.Label>
            <InputText
              id="sequence"
              value={selectedSequence}
              onChange={handleSequenceChange}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="workflowDescription">
              <TranslationLoader
                type="label"
                translationKey="modals.workflow.inputs.description"
                fallbackText="Description"
              />
            </Form.Label>
            <Textarea
              id="workflowDescription"
              value={selectedWorkflowDescription}
              onChange={handleDescriptionChange}
              rows="5"
            />
          </Form.Control>
          <Form.Row>
            <Button color="secondary" onClick={onClose}>
              <TranslationLoader
                type="button"
                translationKey="modals.workflow.buttons.cancel"
                fallbackText="Cancel"
              />
            </Button>
            <Button type="submit">
              <TranslationLoader
                type="button"
                translationKey="modals.workflow.buttons.addWorkflow"
                fallbackText="Add Workflow"
              />
            </Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
