import React, { createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

const StyledMenu = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;

  & .menu__menu-handler svg {
    font-size: 1.5em;
    vertical-align: middle;
    pointer-events: none;
  }
`;

const StyledMenuItems = styled.ul`
  list-style: none;
  position: fixed;
  background-color: var(--menu-bg-clr);
  color: var(--menu-txt-clr);
  border-radius: 0.6rem;
  z-index: var(--z-index-menu, 3000);
  min-width: max-content;
  box-shadow: var(--menu-box-shadow);
  overflow: hidden;
`;

const StyledMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
  height: 4rem;
  padding: 0 0.8rem;

  & svg {
    font-size: 1.25em;
    vertical-align: middle;
    color: var(--menu-icon-clr);
  }

  &:hover {
    background-color: var(--menu-bg-clr-hover);
    color: var(--menu-txt-clr-hover);
    cursor: pointer;
  }

  &:hover > svg {
    color: var(--menu-icon-clr-hover);
  }
`;

const StyledDivider = styled.hr`
  border: none;
  border-top: 1px solid var(--menu-border-clr);
  margin: 0.25em 0.5em;
`;

const MenuContext = createContext();

/**
 * GWOCU's menu component
 *
 * This component serves as a container for the entire menu.
 * Can have `Menu.Handler` or `Menu.List` as direct children.
 *
 * @component
 * @param {Object} props - The props for the menu component.
 * @param {React.ReactNode} props.children - The content to be rendered inside the menu. Can have `Menu.Handler` or `Menu.List` as children.
 * @returns {JSX.Element} The rendered menu component.
 *
 * @example
 * <OptionMenu>
 *   <OptionMenu.Handler>Open</OptionMenu.Handler>
 *   <OptionMenu.List>
 *     <OptionMenu.Item>Centered title</OptionMenu.Item>
 *     <OptionMenu.Item>Clickable menu item</OptionMenu.Item>
 *     <OptionMenu.Divider />
 *     <OptionMenu.Item>Some text</OptionMenu.Item>
 *   </OptionMenu.List>
 * </OptionMenu>
 */
export const OptionMenu = ({ children }) => {
  const [position, setPosition] = useState(null);
  const menuRef = useOutsideClick(close);
  const isOpen = position !== null;

  useEffect(() => {
    function handleScroll (e) {
      if(!isOpen || !menuRef.current) return;
      const scrollableContainer = e.target;
      if (scrollableContainer.contains(menuRef.current)) close();
    }

    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [isOpen]);

  function close () {
    setPosition(null);
  }

  const toggle = (e) => {
    const {x, y} = e.target.getBoundingClientRect();
    setPosition(prevPosition => {
      if(prevPosition !== null) return null;

      return ({
        bottom: `calc(100vh - ${y}px)`,
        left: `${x}px`
      })
    })
  };

  return (
    <MenuContext.Provider value={{ toggle, position, close }}>
      <StyledMenu
        ref={menuRef}
        className={`menu ${isOpen ? "open" : ""}`} role="menu" tabIndex={-1}>
        {children}
      </StyledMenu>
    </MenuContext.Provider>
  );
};

/**
 * GWOCU's option menu handler component.
 *
 * This component is a slot for any element to handle opening and closing the menu.
 *
 * You can put any interactive element (e.g., a button, icon, etc.) inside this handler.
 * The state of whether the menu is open or closed is managed internally.
 *
 * @memberof OptionMenu
 * @param {Object} props - The props for the menu handler component.
 * @param {React.ReactNode} props.children - The content inside the menu handler, typically a button or icon.
 * @returns {JSX.Element} The rendered menu handler component.
 */
OptionMenu.Handler = ({ children }) => {
  const { toggle } = useContext(MenuContext);

  return (
    <span
      onClick={toggle}
      aria-haspopup="true"
      className="menu__menu-handler"
    >
      {children}
    </span>
  );
};

/**
 * GWOCU's option menu list component.
 *
 * @memberof OptionMenu
 * @param {Object} props - The props for the menu list component.
 * @param {React.ReactNode} props.children - The direct children of the `OptionMenu.List`. This can include `OptionMenu.Item` and `OptionMenu.Divider` components.
 * @returns {JSX.Element} The rendered menu list component.
 */
OptionMenu.List = ({ children }) => {
  const { position } = useContext(MenuContext);
  return (
    position !== null ? (
      <StyledMenuItems className="menu__items" style={position}>
        {children}
      </StyledMenuItems>
      ) : null
    );
};

/**
 * GWOCU's option menu item component.
 *
 * This component represents an item in a menu and should be used inside a `OptionMenu.List` component.
 *
 * @memberof OptionMenu
 * @param {Object} props - The props for the menu item component.
 * @param {React.ReactNode} props.children - The content inside the menu item.
 * @param {string} [props.className=undefined] - Adds a custom className for the menu item. Default value: `undefined`.
 * @param {string} [props.id=undefined] - The id of the menu item. Default value: `undefined`.
 * @param {function} [props.onClick=undefined] - The `click` event handler. Default value: `undefined`.
 * @returns {JSX.Element} The rendered menu item component.
 *
 * @example
 * <OptionMenu>
 *   <OptionMenu.Handler>Toggle Menu</OptionMenu.Handler>
 *   <OptionMenu.List>
 *     <OptionMenu.Item type="generic">Menu section title</OptionMenu.Item>
 *     <OptionMenu.Item href="https://studio.gwocu.com">Open an external link</OptionMenu.Item>
 *     <OptionMenu.Item onClick={openModal}>Open a modal</OptionMenu.Item>
 *   </OptionMenu.List>
 * </OptionMenu>
 */

OptionMenu.Item = ({
  children,
  className,
  id,
  onClick,
}) => {

  const { close } = useContext(MenuContext);
  const classes = `menu__item ${className || ""}`;

  const handleClick = (e) => {
    e.stopPropagation();
    close();
    onClick?.(e);
  };

  return (
    <li role="menuitem" tabIndex={0} id={id} onClick={handleClick}>
      <StyledMenuItem className={classes} onClick={handleClick}>
        {children}
      </StyledMenuItem>
    </li>
  );
};

/**
 * GWOCU's option menu divider component
 *
 * The `OptionMenu.Divider` is a self-closing component used to visually separate items in a menu.
 *
 * It should be used within a `OptionMenu.List` to separate different groups of `OptionMenu.Item`s.
 *
 * @memberof OptionMenu
 * @returns {JSX.Element} The rendered menu divider component.
 */
OptionMenu.Divider = () => {
  return <StyledDivider className="menu__divider" />;
};


OptionMenu.displayName = "OptionMenu";
OptionMenu.Handler.displayName = "Handler";
OptionMenu.List.displayName = "List";
OptionMenu.Item.displayName = "Item";
OptionMenu.Divider.displayName = "Divider";