import { useState, useEffect } from "react";
import axios from "axios";
import yaml from 'js-yaml';
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { Button, Form, Modal, Textarea, TranslationLoader } from "../../UI";
import { encodebody, getDecodedBody } from "../../utils/utils.js";

export default function Modalconfiguration({ clientNr, explorerId, open, onClose }) {
  const { getTranslatedPlainText, getTranslatedPlaceholder } = useCustomTranslation();
  const [selectedConfigurationYaml, setSelectedConfigurationYaml] = useState(getTranslatedPlaceholder("modals.configuration.inputs.yaml"));

  useEffect(() => {
    const fetchConfiguration = async () => {
      const apiUrl = `${process.env.REACT_APP_CENTRAL_BACK}/explorer/query`;

      const requestBody = { clientNr, explorerId };
      try {
        // Make a POST request to fetch the configuration
        const response = await axios.post(apiUrl, encodebody(requestBody), {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        // Set the fetched configuration data to the state
        const responseData = getDecodedBody(response.data);
        setSelectedConfigurationYaml(responseData.yaml);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchConfiguration();
  }, []);

  const handleYamlChange = (event) => {
    setSelectedConfigurationYaml(event.target.value)
  }

  const handleSave = async (e) => {
    e.preventDefault();

    // Perform save logic with selectedSource and selectedTarget
    // You can use these values to update your backend or state, as needed
    if (await handleSaveYaml(selectedConfigurationYaml)) {
      onClose();
    }
  };

  function validateYaml(yamlString) {
    try {
      // Try to parse the YAML string
      yaml.load(yamlString);
      // If parsing succeeds, return true
      return true;
    } catch (error) {
      // If an error occurs during parsing, return false
      alert(getTranslatedPlainText("modals.configuration.alerts.invalidYamlError"));
      return false;
    }
  };

  async function handleSaveYaml(configuration) {
    if (!validateYaml(configuration)) return;

    try {
      const mypayload = {
        yaml: configuration,
        clientNr,
        explorerId
      };

      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/explorer/update", encodebody(mypayload));
      const responseData = getDecodedBody(response.data);

      // Check if the response indicates an error
      if (responseData && responseData.error) {
        // Display an alert with the error data
        alert(getTranslatedPlainText("modals.configuration.alerts.invalidYamlError", { error: responseData.error }));
        return false;
      }

      return true;
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      console.error("An unexpected error occurred:", error);
      alert(getTranslatedPlainText("modals.configuration.alerts.unexpectedError"));
      return false;
    }
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="80rem">
      <Modal.Title>
        <TranslationLoader
          translationKey="modals.configuration.heading"
          fallbackText="Configuration"
        />
      </Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleSave}>
          <Form.Control>
            <Form.Label htmlFor="yaml">
              <TranslationLoader
                type="label"
                translationKey="modals.configuration.inputs.yaml"
                fallbackText="YAML"
              />
            </Form.Label>
            <Textarea
              id="yaml"
              value={selectedConfigurationYaml}
              onChange={handleYamlChange}
              rows="20"
              required
            />
          </Form.Control>
          <Form.Row>
            <Button color="secondary" onClick={onClose}>
              <TranslationLoader
                type="button"
                translationKey="modals.configuration.buttons.close"
                fallbackText="Close"
              />
            </Button>
            <Button type="submit">
              <TranslationLoader
                type="button"
                translationKey="modals.configuration.buttons.save"
                fallbackText="Save"
              />
            </Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
