import { useState } from "react";
import axios from "axios";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import { Textarea } from "../../UI";
import { encodebody } from "../../utils/utils.js";
import "./chatbot.css";

export default function Chatbot({ clientNr, explorerId, authorization }) {
  const [inputValue, setInputValue] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false); // Track loading state
  const { getTranslatedPlaceholder, getTranslatedPlainText } = useCustomTranslation();

  const removeNewlines = (text) => {
    return text.replace(/\\n/g, "").replace(/\n/g, ""); // Handle both escaped and real newlines
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter" && !loading) { // Disable if loading is true
      event.preventDefault(); // Prevent the default form submission behavior

      const prompt = inputValue.trim();

      let targetChatbot;
      // Determine which chatbot to use:
      try {
        const chatbotexplorerrel = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/chatbotexplorerrel/query", 
          encodebody({ clientNr, explorerId }));

        if (authorization.designer || authorization.owner) {
          targetChatbot = chatbotexplorerrel.data.chatbotKeyDesigner;
        } else if (authorization.reader) {
          targetChatbot = chatbotexplorerrel.data.chatbotKeyReader;
        } else {
          return alert(getTranslatedPlainText("pages.home.chatbot.alerts.noAccessToChatbotsError"));
        }
      } catch (err) {
        return alert(getTranslatedPlainText("pages.home.chatbot.alerts.noChatbotsInWorkspaceError"));
      }

      if (prompt !== "") {
        const requestData = {
          clientNr: "111111",
          gwoken: "saasasasas",
          chatbotKey: targetChatbot,
          prompt: prompt,
        };

        try {
          setLoading(true); // Set loading state to true before making request
          const response = await fetch(
            process.env.REACT_APP_CENTRAL_BACK + "/chat/ask",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(encodebody(requestData)),
            }
          );

          const responseBody = await response.json();
          console.log("RESPONSE BODY", responseBody);
          const cleanedResponse = removeNewlines(responseBody)
            .replace(/.*ANSWER: /g, "") // Remove the "ANSWER: " prefix
            .replace(/"/g, ""); // Remove quotation marks

          setResponse(""); // Clear previous content

          // Typing effect logic
          const typingSpeed = 20; // Speed of typing effect (in ms per character)
          for (let i = 0; i < cleanedResponse.length; i++) {
            setTimeout(() => {
              setResponse((prevResponse) => prevResponse + cleanedResponse[i]);

              // When the typing effect finishes, set loading to false
              if (i === cleanedResponse.length - 1) {
                setLoading(false); // Set loading to false after the last character is typed
              }
            }, i * typingSpeed);
          }

          setInputValue(""); // Clear the input field
        } catch (error) {
          console.error("Error:", error);
          setLoading(false); // Ensure loading is false even if there is an error
        }
      }
    } else {
      // Hide the response container when typing in the input field
    }
  };

  return (
    <div className="chatbot">
      <Textarea
        id="chatbot-input-field"
        value={inputValue}
        className="chatbot__prompt"
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={getTranslatedPlaceholder("pages.home.chatbot.inputs.chatbox")}
        disabled={loading} // Optionally disable input while loading
      />
      <div
        id="response-container"
        className="chatbot__response"
      >
        {response}
      </div>
    </div>
  );
};
