import FileUpload from '../fileupload/FileUpload';
import { Icon, Modal, TranslationLoader } from "../../UI";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

export default function Modalapidefimport({ clientNr, explorerId, open, onClose }) {

  return (
    <Modal open={open} onClose={onClose} maxWidth="fit-content">
      <Modal.Title>
        <TranslationLoader
          translationKey="modals.apiDefImport.heading"
          fallbackText="Import Api Definitions"
        />
        <Icon
          size="sm"
          href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel-menu#importapi-section"
        >
          <HelpCenterIcon />
        </Icon>
      </Modal.Title>
      <Modal.Body>
        <FileUpload
          clientNr={clientNr}
          explorerId={explorerId}
        />
      </Modal.Body>
    </Modal>
  );
}
