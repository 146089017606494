import { useState } from "react";
import axios from "axios";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import { Button, Form, InputText, Modal, Textarea, TranslationLoader } from "../../UI";
import { encodebody, getDecodedBody } from "../../utils/utils.js";

export default function Modalproduct({ clientNr, open, explorerId, onClose }) {
  const { getTranslatedPlainText } = useCustomTranslation();
  const [selectedSequence, setSelectedSequence] = useState("1");
  const [selectedProductName, setSelectedProductName] = useState(getTranslatedPlainText("modals.product.inputs.productName.default"));
  const [selectedProductDescription, setSelectedProductDescription] = useState(getTranslatedPlainText("modals.product.inputs.description.default"));

  const handleNameChange = (event) => {
    setSelectedProductName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setSelectedProductDescription(event.target.value)
  }

  const handleSequenceChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input is a valid number
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      // If it's a valid number or an empty string, update the state
      setSelectedSequence(inputValue);
    }
    // If it's not a valid number, you can choose to do nothing or provide feedback to the user
    // For example, show an error message or prevent further action
  };

  const handleSave = async (e) => {
    e.preventDefault();
    // Perform save logic with selectedSource and selectedTarget
    // You can use these values to update your backend or state, as needed
    if (await handleCreateProduct(selectedProductName, selectedProductDescription, selectedSequence)) {
      onClose();
    }
  };

  async function handleCreateProduct(productName, productDescription, sequence) {
    try {
      const mypayload = {
        description: productDescription,
        status: "Private",
        clientNr,
        explorerId,
        productName,
        sequence,
      };

      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/product/register", encodebody(mypayload));
      const responseData = getDecodedBody(response.data);
      // Check if the response indicates an error
      if (responseData && responseData.error) {
        // Display an alert with the error data
        alert(getTranslatedPlainText("modals.product.alerts.error", { error: responseData.error }));
        return false;
      }
      return true;
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      console.error("An unexpected error occurred:", error);
      alert(getTranslatedPlainText("modals.product.alerts.unexpectedError"));
      return false;
    }
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="60rem" className="custom-modal">
      <Modal.Title>
        <TranslationLoader
          translationKey="modals.product.heading"
          fallbackText="Add New Product"
        />
      </Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleSave}>
          <Form.Control>
            <Form.Label htmlFor="productName">
              <TranslationLoader
                type="label"
                translationKey="modals.product.inputs.productName"
                fallbackText="Product Name"
              />
            </Form.Label>
            <InputText
              id="productName"
              value={selectedProductName}
              onChange={handleNameChange}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="sequence">
              <TranslationLoader
                type="label"
                translationKey="modals.product.inputs.sequence"
                fallbackText="Sequence in product tree"
              />
            </Form.Label>
            <InputText
              id="sequence"
              value={selectedSequence}
              onChange={handleSequenceChange}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="productDescription">
              <TranslationLoader
                type="label"
                translationKey="modals.product.inputs.description"
                fallbackText="Description"
              />
            </Form.Label>
            <Textarea
              id="productDescription"
              value={selectedProductDescription}
              onChange={handleDescriptionChange}
              rows="5"
            />
          </Form.Control>
          <Form.Row>
            <Button color="secondary" onClick={onClose}>
              <TranslationLoader
                type="button"
                translationKey="modals.product.buttons.cancel"
                fallbackText="Cancel"
              />
            </Button>
            <Button type="submit">
              <TranslationLoader
                type="button"
                translationKey="modals.product.buttons.addProduct"
                fallbackText="Add Product"
              />
            </Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
