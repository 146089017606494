import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext.js";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import { Button, Icon, Menu, Tooltip, TranslationLoader } from "../../UI/index.js";
import {
  BookmarkBorderOutlined,
  Code,
  CreateNewFolderOutlined,
  DeleteOutline,
  DescriptionOutlined,
  EditOutlined,
  EmailOutlined,
  FolderOutlined,
  GroupAddOutlined,
  GroupOutlined,
  MoreVertOutlined,
  SyncOutlined,
} from "@material-ui/icons";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import "./treemenu.css";

export default function TreeMenu({ onSelectTreeMenuItem, refetching, finishRefetching }) {
  const { getTranslatedTooltipConfig, getTranslatedPlainText } = useCustomTranslation();
  const [workspaceNames, setWorkspaceNames] = useState([]);
  const [workspaces, setWorkspaces] = useState({});
  const [invites, setInvites] = useState([]);
  const [users, setUsers] = useState([]);
  const { user } = useContext(AuthContext);
  const [isOwnerInSomeWorkspace, setIsOwnerInSomeWorkspace] = useState(false);
  const currentWorkspaceName = user.explorerId;
  const isOwnerInThisWorkspace = workspaces[currentWorkspaceName]?.owner;
  const isDesignerInThisWorkspace = workspaces[currentWorkspaceName]?.designer;
  const readOnly = !isOwnerInThisWorkspace && !isDesignerInThisWorkspace;

  function filterUsersByExplorerId(users, explorerId) {
    return users.filter((user) =>
      user.explorers.some((explorer) => explorer.name === explorerId)
    );
  }

  useEffect(() => {
    if(!refetching) return;

    const fetchData = async () => {
      try {
        const currentUser = await axios.post(
          process.env.REACT_APP_CENTRAL_BACK + "/users/query",
          encodebody({
            clientNr: user.clientNr,
            chatbotKey: user.chatbotKey,
            email: user.email,
          })
        );

        //set workspaces
        const decodedWorkspaces = getDecodedBody(currentUser.data.explorers);
        const userWorkspaces = {};
        const userWorkspaceNames = [];

        decodedWorkspaces.forEach((workspace => {
          const workspaceName = workspace.name;
          userWorkspaces[workspaceName] = workspace;
          userWorkspaceNames.push(workspaceName);
        }));

        setWorkspaces(userWorkspaces);
        setWorkspaceNames(userWorkspaceNames);
        setIsOwnerInSomeWorkspace(decodedWorkspaces.some(workspace => workspace.owner));

        // fetch invites to populate submenu
        const inviteResponse = await axios.post(
          process.env.REACT_APP_CENTRAL_BACK + "/invitation/queryall",
          encodebody({
            chatbotKey: user.chatbotKey,
          })
        );
        const inviteResponseData = getDecodedBody(inviteResponse.data);
        const emails = inviteResponseData.map((invite) => invite.email);
        setInvites(emails); // Set the state with the array of emails

        const usersResponse = await axios.post(
          process.env.REACT_APP_CENTRAL_BACK + "/users/queryall",
          encodebody({
            clientNr: user.clientNr,
            chatbotKey: user.chatbotKey,
          })
        );
        // now filter the users so that the list will include only those tha are
        // member of this workspace

        const allUsers = getDecodedBody(usersResponse.data);
        const filteredUsers = filterUsersByExplorerId(
          allUsers,
          user.explorerId
        );

        setUsers(filteredUsers); // Set the state with the array of emails
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData().then(() => finishRefetching())
  }, [refetching]); // The empty array ensures this effect runs only once after the component mounts

  const handleClick = (item, value = null) => (
    async() => await onSelectTreeMenuItem(item, value)
  );

  const handleDeleteExplorer = (item, value) => async (e) => {
    e.stopPropagation();
    if (window.confirm(getTranslatedPlainText("pages.home.treeMenu.prompts.deleteWorkspace", {value}))) {
      await onSelectTreeMenuItem(item, value);
    } else {
      // User clicked 'Cancel', do nothing
      console.log("Deletion cancelled.");
    }
  };

  const handleDeleteInvite = (item, value) => async () => {
    if (window.confirm(getTranslatedPlainText("pages.home.treeMenu.prompts.deleteInvitation", {value}))) {
      await onSelectTreeMenuItem(item, value);
    } else {
      // User clicked 'Cancel', do nothing
      console.log("Deletion cancelled.");
    }
  };

  const handleDeleteUser = (item, value) => async () => {
    if (window.confirm(getTranslatedPlainText("pages.home.treeMenu.prompts.deleteUser", {value}))) {
      await onSelectTreeMenuItem(item, value);
    } else {
      // User clicked 'Cancel', do nothing
      console.log("Deletion cancelled.");
    }
  };

  const handleEditUser = (item, value) => async () => {
    await onSelectTreeMenuItem(item, value);
  };

  const handleCreateWorkspace = (item, value) => async (e) => {
    e.stopPropagation();
    const workspaceName = prompt(getTranslatedPlainText("pages.home.treeMenu.prompts.newWorkspaceName"));
    if (workspaceName) {
      // Checks if the user entered something and did not just cancel the dialog
      console.log(`Creating workspace with name: ${workspaceName}`);
      await onSelectTreeMenuItem(item, workspaceName);
    } else {
      console.log("Workspace creation cancelled.");
    }
  };

  return (
    <Menu>
      <Menu.Handler>
        <Tooltip
          tooltip={getTranslatedTooltipConfig("pages.home.treeMenu.handler")}
          placement="right"
        >
          <Button color="secondary" size="xs" rounded>
            <MoreVertOutlined />
          </Button>
        </Tooltip>
      </Menu.Handler>
      <Menu.List>
        {isOwnerInThisWorkspace && (
          <>
            <Menu.NestedMenu>
              <Menu.NestedMenuHandler onClick={handleClick("invitations")}>
                <GroupAddOutlined />
                <span>
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.treeMenu.invitations"
                    fallbackText="Invitations"
                  />
                </span>
              </Menu.NestedMenuHandler>
              <Menu.List>
                <Menu.Item onClick={handleClick("SendNewInvitation")}>
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.treeMenu.invitations.nestedMenu.sendPrivateInvitation"
                    fallbackText="Send private invitation"
                  />
                </Menu.Item>
                <Menu.Item onClick={handleClick("CreatePublicInvitation")}>
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.treeMenu.invitations.nestedMenu.createPublicInvitation"
                    fallbackText="Create public invitation"
                  />
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  type="generic"
                  centered
                  className="tree-menu__heading"
                >
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.treeMenu.invitations.nestedMenu.pendingInvitations"
                    fallbackText="Pending Invitations"
                  />
                </Menu.Item>
                {invites.map((invite) => (
                  <React.Fragment key={`invite-${invite}`}>
                    <Menu.Divider />
                    <Menu.Item type="generic">
                      <EmailOutlined />
                      {invite}
                      <div className="tree-menu__item-button-group">
                        <Tooltip
                          tooltip={getTranslatedTooltipConfig(
                            "pages.home.treeMenu.invitations.nestedMenu.pendingInvitations.buttons.editInvitation"
                          )}
                        >
                          <Button
                            color="danger"
                            size="xs"
                            onClick={handleDeleteInvite("deleteInvite", invite)}
                            rounded
                          >
                            <DeleteOutline />
                          </Button>
                        </Tooltip>
                      </div>
                    </Menu.Item>
                  </React.Fragment>
                ))}
                <Menu.Divider />
                <Menu.Item href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel-menu#invitation-section">
                  <Icon size="xs">
                    <HelpCenterIcon />
                  </Icon>
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.treeMenu.invitations.nestedMenu.moreInformation"
                    fallbackText="More information"
                  />
                </Menu.Item>
              </Menu.List>
            </Menu.NestedMenu>
            <Menu.NestedMenu>
              <Menu.NestedMenuHandler onClick={handleClick("users")}>
                <GroupOutlined />
                <TranslationLoader
                  type="menu-item"
                  translationKey="pages.home.treeMenu.users"
                  fallbackText="Users"
                />
              </Menu.NestedMenuHandler>
              <Menu.List className="tree-menu__nested-menu--scrollable scrollable-submenu">
                {users.map((user) => (
                  <React.Fragment key={`user-${user.email}`}>
                    <Menu.Item type="generic">
                      <EmailOutlined />
                      <span>{user.email}</span>
                      {!user.isAdmin &&
                        user.email !== "public@gwocu.com" && (
                          <div className="tree-menu__item-button-group">
                            <Tooltip
                              tooltip={getTranslatedTooltipConfig(
                                "pages.home.treeMenu.users.buttons.editUser"
                              )}
                            >
                              <Button
                                color="secondary"
                                size="xs"
                                onClick={handleEditUser("editUser", user.email)}
                                rounded
                              >
                                <EditOutlined />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              tooltip={getTranslatedTooltipConfig(
                                "pages.home.treeMenu.users.buttons.deleteUser"
                              )}
                            >
                              <Button
                                color="danger"
                                size="xs"
                                onClick={handleDeleteUser("deleteUser", user.email)}
                                rounded
                              >
                                <DeleteOutline />
                              </Button>
                            </Tooltip>
                          </div>
                        )}
                    </Menu.Item>
                    <Menu.Divider />
                  </React.Fragment>
                ))}
                <Menu.Item href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel-menu#users-section">
                  <Icon size="xs">
                    <HelpCenterIcon />
                  </Icon>
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.treeMenu.users.nestedMenu.moreInformation"
                    fallbackText="More information"
                  />
                </Menu.Item>
              </Menu.List>
            </Menu.NestedMenu>
          </>
        )}
        <Menu.NestedMenu>
          <Menu.NestedMenuHandler>
            <FolderOutlined />
            <span>
              <TranslationLoader
                type="menu-item"
                translationKey="pages.home.treeMenu.workspaces"
                fallbackText="Workspaces"
              />
            </span>
          </Menu.NestedMenuHandler>
          <Menu.List className="tree-menu__nested-menu--scrollable scrollable-submenu">
            {isOwnerInSomeWorkspace && (
              <>
                <Menu.Item
                  onClick={handleCreateWorkspace("createWorkspace")}
                >
                  <CreateNewFolderOutlined />
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.treeMenu.workspaces.nestedMenu.createWorkspace"
                    fallbackText="Create New Workspace"
                  />
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            {workspaceNames.map((workspaceName) => (
              <React.Fragment key={`explorer-${workspaceName}`}>
                <Menu.Item onClick={handleClick("explorer", workspaceName)}>
                  <BookmarkBorderOutlined />
                  {workspaceName}
                  {workspaceName !== "1" &&
                    workspaces[workspaceName].owner &&
                    user.explorerId !== workspaceName && (
                    <div className="tree-menu__item-button-group">
                      <Tooltip
                        tooltip={getTranslatedTooltipConfig(
                          "pages.home.treeMenu.workspaces.nestedMenu.availableWorkspaces.buttons.deleteWorkspace"
                        )}
                        placement="right"
                      >
                        <Button
                          color="danger"
                          size="xs"
                          onClick={handleDeleteExplorer("deleteExplorer", workspaceName)}
                          rounded
                        >
                          <DeleteOutline />
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </Menu.Item>
              </React.Fragment>
            ))}
            <Menu.Divider />
            <Menu.Item
              href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel-menu#workspaces-section"
              className="tree-menu__heading"
            >
              <Icon size="xs" color="secondary">
                <HelpCenterIcon />
              </Icon>
              <TranslationLoader
                type="menu-item"
                translationKey="pages.home.treeMenu.workspaces.nestedMenu.moreInformation"
                fallbackText="More information"
              />
            </Menu.Item>
          </Menu.List>
        </Menu.NestedMenu>
        {!readOnly && (
          <>
            <Menu.Divider />
            <Menu.NestedMenu>
              <Menu.NestedMenuHandler
                onClick={handleClick("api-pre-request-actions")}
              >
                <DescriptionOutlined />
                <TranslationLoader
                  type="menu-item"
                  translationKey="pages.home.treeMenu.actionsAndWebhooks"
                  fallbackText="Actions & Webhooks"
                />
              </Menu.NestedMenuHandler>
              <Menu.List>
                <Menu.Item onClick={handleClick("workspace-action")}>
                  <Code />
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.treeMenu.actionsAndWebhooks.nestedMenu.workspaceActions"
                    fallbackText="Workspace Actions"
                  />
                </Menu.Item>
                <Menu.Item onClick={handleClick("api-action")}>
                  <Code />
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.treeMenu.actionsAndWebhooks.nestedMenu.apiActions"
                    fallbackText="API Actions"
                  />
                </Menu.Item>
                <Menu.Item onClick={handleClick("webhooks")}>
                  <Code />
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.treeMenu.actionsAndWebhooks.nestedMenu.manageWebhooks"
                    fallbackText="Manage Webhooks"
                  />
                </Menu.Item>
                <Menu.Item href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel-menu#prerequest-section">
                  <Icon size="xs">
                    <HelpCenterIcon />
                  </Icon>
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.treeMenu.actionsAndWebhooks.nestedMenu.moreInformation"
                    fallbackText="More information"
                  />
                </Menu.Item>
              </Menu.List>
            </Menu.NestedMenu>
            <Menu.Divider />
            <Menu.Item onClick={handleClick("exportproducts")}>
              <FolderOutlined />
              <TranslationLoader
                type="menu-item"
                translationKey="pages.home.treeMenu.exportProducts"
                fallbackText="Export Products"
              />
            </Menu.Item>
            <Menu.Item onClick={handleClick("importproducts")}>
              <FolderOutlined />
              <TranslationLoader
                type="menu-item"
                translationKey="pages.home.treeMenu.importProducts"
                fallbackText="Import Products"
              />
            </Menu.Item>
          </>
        )}
        <Menu.Item onClick={handleClick("exportguides")}>
          <FolderOutlined />
          <TranslationLoader
            type="menu-item"
            translationKey="pages.home.treeMenu.exportGuides"
            fallbackText="Export Guides"
          />
        </Menu.Item>
        {!readOnly && (
          <>
            <Menu.Divider />
            <Menu.NestedMenu>
              <Menu.NestedMenuHandler onClick={handleClick("integrations")}>
                <SyncOutlined />
                <TranslationLoader
                  type="menu-item"
                  translationKey="pages.home.treeMenu.integrations"
                  fallbackText="Integrations"
                />
              </Menu.NestedMenuHandler>
              <Menu.List>
                <Menu.Item onClick={handleClick("trainyourchatbots")}>
                  <SyncOutlined />
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.treeMenu.integrations.nestedMenu.trainChatbots"
                    fallbackText="Train your chatbots"
                  />
                </Menu.Item>
                <Menu.Item href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel-menu#prerequest-section">
                  <Icon size="xs">
                    <HelpCenterIcon />
                  </Icon>
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.treeMenu.integrations.nestedMenu.moreInformation"
                    fallbackText="More information"
                  />
                </Menu.Item>
              </Menu.List>
            </Menu.NestedMenu>
          </>
        )}
      </Menu.List>
    </Menu>
  );
};
