import { useState, useEffect } from "react";
import axios from "axios";
import ReactJson from "react-json-view";
import { useTheme } from "../../context/ThemeContext.js";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import {
  Button,
  CodeEditor,
  Form,
  InputText,
  Select,
  Terminal,
  TextEditor,
  TranslationLoader,
} from "../../UI";
import { Tab, Tabs } from "@mui/material";
import { AddBox, Delete } from "@mui/icons-material";
import {
  HeadersGlobalAdd,
  requestBodyGlobalAdd,
  addAuthToHeaders,
  addAuthToRequestBody,
  parseApiHeaders,
  getConfiguration,
  isValidConfiguration,
} from "../../utils/api-spec-util.js";
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import ApiManagerService from "../../services/apiManagerService.js";
import "./apidetails.css"; // Import your CSS file here

export default function APIDetails({
  clientNr,
  explorerId,
  readOnly,
  loading,
  api: { name: outerApiName, ...outerApiDetails },
  onRename,
  isNameInUse,
}) {
  const { getTranslatedPlainText } = useCustomTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [innerApiName, setInnerApiName] = useState(outerApiName);
  const [innerApiDetails, setInnerApiDetails] = useState(outerApiDetails);
  const [headers, setHeaders] = useState([]);
  const [explorer, setExplorer] = useState([]);
  const [thirdparties, setThirdparties] = useState([]);
  const { isADarkTheme } = useTheme();
  const [jsonErrors, setJsonErrors] = useState({
    reqBody: [],
    responseBody: [],
    paramDesc: [],
  });

  useEffect(() => {
    if(!outerApiDetails._id) return;

    const fetchExplorer = async () => {
      const myExplorerbody = { clientNr, explorerId };
      try {
        const Eresponse = await axios.post(
          process.env.REACT_APP_CENTRAL_BACK + "/explorer/query",
          encodebody(myExplorerbody)
        );
        const myExplorer = getDecodedBody(Eresponse.data);
        setExplorer(myExplorer);
      } catch (error) {
        console.log("Error while fetching explorer:", error);
      }
    };

    const fetchThirdParties = async () => {
      const myBody = { clientNr, explorerId };
      try {
        const thirdpartiesresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/thirdparties/queryall", encodebody(myBody));
        const myEmptyThirdParty = {
          name: getTranslatedPlainText("pages.apisEditor.apiDetails.tabs.apiBasicInformation.inputs.apiAction.default")
        };
        const mythirdparties = getDecodedBody(thirdpartiesresponse.data);
        mythirdparties.unshift(myEmptyThirdParty);
        setThirdparties(mythirdparties);
      } catch (error) {
        console.error("Error fetching third party data:", error);
      }
    };

    fetchExplorer();
    fetchThirdParties();
    setInnerApiDetails(outerApiDetails);

    const newHeaders = Array.isArray(outerApiDetails.headers)
      ? outerApiDetails.headers.map((header) => {
          const [headerName, value] = header.split(": ");
          return { headerName, value };
        })
      : [];

    setHeaders(newHeaders);
    setSelectedTabIndex(0); // Reset the tab to the first one
  }, [outerApiDetails._id, clientNr, explorerId]);

  useEffect(() => {
    if (innerApiName === outerApiName) return;

    setInnerApiName(outerApiName);
  }, [outerApiName]);

  const handleTabChange = (_, newIndex) => setSelectedTabIndex(newIndex);

  // API Basic Information Tab
  const handleApiDataChange = ({ target }) =>
    setInnerApiDetails((prevApiData) => ({
      ...prevApiData,
      [target.name]: target.value,
    }));

  const handleNameChange = (e) => setInnerApiName(e.target.value);

  const handleDescriptionChange = (value) =>
    setInnerApiDetails((prevApiData) => ({
      ...prevApiData,
      description: value,
    }));

  const handleHeaderChange = ({ target }) => {
    const newHeaders = [...headers];
    newHeaders[target.dataset.index][target.name] = target.value;
    setHeaders(newHeaders);
  };

  const addHeader = () => {
    setHeaders([...headers, { headerName: "", value: "" }]);
  };

  const deleteHeader = (index) => {
    const newHeaders = headers.filter((_, i) => i !== index);
    setHeaders(newHeaders);
  };

  // Payload Tab
  const validateJson = (inputName) => (errors) => {
    const formattedErrors = errors.map(({ message, endLineNumber, endColumn }) => (
      `${message} in line: ${endLineNumber}, column: ${endColumn}`
    ));
    setJsonErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: formattedErrors,
    }));
  };

  const clearJsonErrors = (inputName) => () => {
    setJsonErrors((prevErrors) => ({ ...prevErrors, [inputName]: [] }));
  };

  const handleJsonChange = (value, fieldName, defaultValue = {}) => {
    let parsedValue;
    // Check if the value is an empty string and set it to an empty object
    if (value === "") {
      parsedValue = defaultValue;
    } else {
      try {
        // Attempt to parse the value as JSON
        parsedValue = JSON.parse(value);
      } catch (error) {
        console.error("Invalid JSON:", error);
        // If the value is not valid JSON, you might want to handle this case,
        // e.g., by not calling setInnerApiDetails, showing an error message, etc.
        return; // Exit the function if the JSON is invalid
      }
    }

    // Update the state  with the parsed value, which is now guaranteed to be an object
    setInnerApiDetails(prevData => ({ ...prevData, [fieldName]: parsedValue }));
  };

  const handleRequestBodyChange = (value) => handleJsonChange(value, "requestBody");
  const handleResponseBodyChange = (value) => handleJsonChange(value, "responseBody");
  const handleParametersDescriptionChange = (value) => handleJsonChange(value, "parametersDescription");

  const updateApi = async () => {
    const errors = [];
    const normalizedName = innerApiName.trim();
    const changedName = outerApiName !== normalizedName;

    if (normalizedName.length === 0) {
      errors.push(getTranslatedPlainText("pages.apisEditor.apiDetails.alerts.blankApiNameError"));
    } else if (changedName && isNameInUse(normalizedName)) {
      errors.push(getTranslatedPlainText("pages.apisEditor.apiDetails.alerts.duplicateTaskNameError"));
    }

    // Convert headers back to the string format expected by the backend
    const headersAsStringArray = headers.map(({ headerName, value }) => `${headerName}: ${value}`);

    const { reqBody: reqBodyErrors, paramDesc: paramDescErrors } = jsonErrors;

    if (reqBodyErrors.length > 0) {
      errors.push(getTranslatedPlainText("pages.apisEditor.apiDetails.alerts.invalidJsonInRequestBodyError", { error: reqBodyErrors.join("\n") }));
    }

    if (paramDescErrors.length > 0) {
      errors.push(getTranslatedPlainText("pages.apisEditor.apiDetails.alerts.invalidJsonInParametersDescription", { error: paramDescErrors.join("\n") }));
    }

    if (errors.length > 0) {
      return alert(errors.join("\n\n"));
    }

    try {
      if (changedName) {
        await onRename(normalizedName);
      }
      await ApiManagerService.updateApi({
        ...innerApiDetails,
        name: normalizedName,
        headers: headersAsStringArray,
      });
      alert("pages.apisEditor.apiDetails.alerts.apiUpdateSuccess");
    } catch (error) {
      if (error.response) {
        const decodedErrorMessage = getDecodedBody(error.response.data.message);
        alert(decodedErrorMessage
          ? getTranslatedPlainText("pages.apisEditor.apiDetails.alerts.apiUpdateError", { error: decodedErrorMessage })
          : getTranslatedPlainText("pages.apisEditor.apiDetails.alerts.error")
        );
      } else if (error.request) {
        alert(getTranslatedPlainText("pages.apisEditor.apiDetails.alerts.apiUpdateNoResponseError"));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
        alert(getTranslatedPlainText("pages.apisEditor.apiDetails.alerts.apiUpdateError", { error: error.message }));
      }
    }
  };

  // Terminal Tab
  const commands = {
    cd: (directory) => getTranslatedPlainText("pages.apisEditor.apiDetails.terminalMessages.changedPathInfo", { directory }),
    run: async () => {
      try {
        // Execute the handleApiCall logic when "run" is entered in the terminal
        const myResponse = await handleApiCall();

        let jsonResponse;
        if (typeof myResponse === "string") {
          // Convert string to a valid JSON object
          jsonResponse = { data: myResponse };
        } else {
          jsonResponse = myResponse;
        }
        return (
          <ReactJson
            src={jsonResponse}
            theme="apathy"
            name={null}
            collapsed={1}
          />
        );
      } catch (error) {
        console.error("Error during API execution:", error);
        return (
          <div className="terminal__message terminal__message--error">
            {getTranslatedPlainText("pages.apisEditor.apiDetails.terminalMessages.apiExecutionError")}
          </div>
        );
      }
    },
  };

  const handleApiCall = async () => {
    try {
      // setResponse('');
      // Get the YAML configuration of GWOCU Studio for this explorer
      if (!isValidConfiguration(explorer)) {
        return alert(getTranslatedPlainText("pages.apisEditor.apiDetails.alerts.invalidYamlError"));
      }

      const yamlObject = await getConfiguration(explorer, innerApiDetails.thirdparty);
      console.log("yamlObject", yamlObject);

      // Build the headers as found in the API dynamically
      const apiHeaders = parseApiHeaders(innerApiDetails);

      // Add or replace the global parameters (found in the config) to the headers
      const myheadersWithGlobals = HeadersGlobalAdd(apiHeaders, yamlObject);

      // Add or replace the global parameters (found in the config) to the request body
      const myRequestBodyWithGlobals = requestBodyGlobalAdd(innerApiDetails.requestBody, yamlObject);

      const finalHeaders = addAuthToHeaders(myheadersWithGlobals, yamlObject);
      const finalRequestBody = addAuthToRequestBody(
        myRequestBodyWithGlobals,
        yamlObject,
        crypto
      );

      // We are using the relay function of our backen to get to the clients API so:
      finalHeaders.destination = innerApiDetails.urlRoute;

      // Determine the three mayor parameters of API call based on the authentication case
      const allowedMethodsForBody = ["POST", "PUT", "PATCH"];
      const fetchOptions = {
        method: innerApiDetails.method,
        headers: { ...finalHeaders },
      };

      // Check if the current API method allows a body
      if (allowedMethodsForBody.includes(innerApiDetails.method.toUpperCase())) {
        fetchOptions.body = JSON.stringify(finalRequestBody);
      }

      const fetchResponse = await fetch(process.env.REACT_APP_CENTRAL_BACK + "/relay", fetchOptions);
      const responseData = await fetchResponse.json();
      const resultWithStatus = {
        status: fetchResponse.status,
        resultBody: responseData,
      };

      // save result for eventual workflow use
      const user = JSON.parse(localStorage.getItem("user"));
      const endpoint = `${process.env.REACT_APP_CENTRAL_BACK}/api/registerapiresult`;

      const myresultPayload = {
        result: { ...resultWithStatus },
        clientNr: clientNr,
        explorerId: explorerId,
        name: innerApiName,
        email: user.email,
        chatbotKey: user.chatbotKey,
      };

      try {
        await axios.post(endpoint, encodebody(myresultPayload));
      } catch (error) {
        console.log("An error occured when saving result", error);
      }

      return resultWithStatus;

      // return JSON.stringify(responseData, null, 2); // Return the response data
    } catch (error) {
      console.error("Error during API execution:", error);
      throw error; // Throw the error to be caught in the catch block outside the fetch
    }
  };

  return (
    <div className="api__details">
      <Tabs
        value={selectedTabIndex}
        onChange={handleTabChange}
        aria-label={getTranslatedPlainText("pages.apisEditor.apiDetails.tabs.ariaLabel")}
        className="api-details__tabs"
      >
        <Tab label={
          <TranslationLoader
            type="label"
            translationKey="pages.apisEditor.apiDetails.tabs.apiBasicInformation"
            fallbackText="API Basic Information"
          />
        } />
        <Tab label={
          <TranslationLoader
            type="label"
            translationKey="pages.apisEditor.apiDetails.tabs.payload"
            fallbackText="Payload"
          />
        } />
        <Tab label={
          <TranslationLoader
            type="label"
            translationKey="pages.apisEditor.apiDetails.tabs.terminal"
            fallbackText="Terminal"
          />
        } />
      </Tabs>
      <TabPanel value={selectedTabIndex} index={0}>
        <Form>
          <Form.Row className="api-details__save-button-container">
            <Button onClick={updateApi} disabled={readOnly}>
              <TranslationLoader
                type="button"
                translationKey="pages.apisEditor.apiDetails.tabs.apiBasicInformation.buttons.saveChanges"
                fallbackText="Save Changes"
              />
            </Button>
          </Form.Row>
          <Form.Control>
            <Form.Label htmlFor="name">
              <TranslationLoader
                type="label"
                translationKey="pages.apisEditor.apiDetails.tabs.apiBasicInformation.inputs.apiName"
                fallbackText="API Name"
              />
            </Form.Label>
            <InputText
              id="name"
              name="name"
              value={innerApiName}
              onChange={handleNameChange}
              readOnly={readOnly}
              loading={loading}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="thirdparty">
              <TranslationLoader
                type="label"
                translationKey="pages.apisEditor.apiDetails.tabs.apiBasicInformation.inputs.apiAction"
                fallbackText="API Action"
              />
            </Form.Label>
            <Select
              id="thirdparty"
              name="thirdparty"
              value={innerApiDetails.thirdparty}
              onChange={handleApiDataChange}
              options={thirdparties.map((thirdparty) => ({
                label: thirdparty.name,
                value: thirdparty.name,
              }))}
              disabled={readOnly}
              loading={loading}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="method">
              <TranslationLoader
                type="label"
                translationKey="pages.apisEditor.apiDetails.tabs.apiBasicInformation.inputs.method"
                fallbackText="Method"
              />
            </Form.Label>
            <Select
              id="method"
              name="method"
              value={innerApiDetails.method}
              onChange={handleApiDataChange}
              options={["POST", "GET", "PUT", "DELETE", "HEAD", "OPTIONS"].map(
                (method) => ({ label: method, value: method })
              )}
              disabled={readOnly}
              loading={loading}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="urlRoute">
              <TranslationLoader
                type="label"
                translationKey="pages.apisEditor.apiDetails.tabs.apiBasicInformation.inputs.urlExample"
                fallbackText="URL Route Example"
              />
            </Form.Label>
            <InputText
              id="urlRoute"
              name="urlRoute"
              value={innerApiDetails.urlRoute}
              onChange={handleApiDataChange}
              readOnly={readOnly}
              loading={loading}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="resourcePath">
              <TranslationLoader
                type="label"
                translationKey="pages.apisEditor.apiDetails.tabs.apiBasicInformation.inputs.baseUrl"
                fallbackText="Base URL"
              />
            </Form.Label>
            <InputText
              id="resourcePath"
              name="resourcePath"
              value={innerApiDetails.resourcePath || ""}
              onChange={handleApiDataChange}
              readOnly={readOnly}
              loading={loading}
            />
          </Form.Control>
          <Form.Col>
            <TextEditor
              value={innerApiDetails.description}
              onChange={handleDescriptionChange}
              readOnly={readOnly}
              loading={loading}
            />
          </Form.Col>
          <Form.Control className="api-details__headers">
            <Form.Label>
              <TranslationLoader
                type="label"
                translationKey="pages.apisEditor.apiDetails.tabs.apiBasicInformation.inputs.headers"
                fallbackText="Headers"
              />
            </Form.Label>
            {headers.map((header, index) => (
              <Form.Row key={index} className="api-details__header">
                <>
                  <InputText
                    name="headerName"
                    data-index={index}
                    value={header.headerName}
                    onChange={handleHeaderChange}
                    readOnly={readOnly}
                    loading={loading}
                  />
                  <InputText
                    name="value"
                    data-index={index}
                    value={header.value}
                    onChange={handleHeaderChange}
                    readOnly={readOnly}
                    loading={loading}
                  />
                  <Button
                    className="api-details__button-delete-header"
                    color="danger"
                    size="sm"
                    rounded
                    onClick={() => deleteHeader(index)}
                    disabled={readOnly}
                  >
                    <Delete />
                  </Button>
                </>
              </Form.Row>
            ))}
            <Form.Row>
              <Button
                color="secondary"
                onClick={addHeader}
                className="api-details__button-new-header"
                disabled={readOnly}
              >
                <AddBox />
                <TranslationLoader
                  type="button"
                  translationKey="pages.apisEditor.apiDetails.tabs.apiBasicInformation.buttons.newHeader"
                  fallbackText="New Header"
                />
              </Button>
            </Form.Row>
          </Form.Control>
        </Form>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <Form>
          <Form.Row className="api-details__save-button-container">
            <Button onClick={updateApi} disabled={readOnly}>
              <TranslationLoader
                type="button"
                translationKey="pages.apisEditor.apiDetails.tabs.payload.buttons.saveChanges"
                fallbackText="Save Changes"
              />
            </Button>
          </Form.Row>
          <Form.Row>
            <Form.Control>
              <Form.Label htmlFor="requestBodyType">
                <TranslationLoader
                  type="label"
                  translationKey="pages.apisEditor.apiDetails.tabs.payload.inputs.requestBodyType"
                  fallbackText="Request Body Type"
                />
              </Form.Label>
              <Select
                id="requestBodyType"
                value={innerApiDetails.requestBodyType?.toUpperCase() || "JSON"}
                onChange={handleApiDataChange}
                options={["JSON", "RAW"].map((type) => ({
                  label: type,
                  value: type,
                }))}
                disabled={readOnly}
                loading={loading}
              />
            </Form.Control>
            <Form.Control>
              <Form.Label htmlFor="responseBodyType">
                <TranslationLoader
                  type="label"
                  translationKey="pages.apisEditor.apiDetails.tabs.payload.inputs.responseBodyType"
                  fallbackText="Response Body Type"
                />
              </Form.Label>
              <Select
                id="responseBodyType"
                value={innerApiDetails.requestBodyType?.toUpperCase() || "JSON"}
                onChange={handleApiDataChange}
                options={["JSON", "RAW"].map((type) => ({
                  label: type,
                  value: type,
                }))}
                disabled={readOnly}
                loading={loading}
              />
            </Form.Control>
          </Form.Row>
          <Form.Control>
            <Form.Label>
              <TranslationLoader
                type="label"
                translationKey="pages.apisEditor.apiDetails.tabs.payload.inputs.requestBody"
                fallbackText="Request Body"
              />
            </Form.Label>
            <CodeEditor
              id="json-editor"
              defaultLanguage="json"
              defaultValue={innerApiDetails.requestBody}
              height="300px"
              onChange={handleRequestBodyChange}
              onValidate={validateJson("reqBody")}
              onMount={clearJsonErrors("reqBody")}
              darkMode={isADarkTheme}
              readOnly={readOnly}
              loading={loading}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label>
              <TranslationLoader
                type="label"
                translationKey="pages.apisEditor.apiDetails.tabs.payload.inputs.responseBodyExample"
                fallbackText="Response Body Example"
              />
            </Form.Label>
            <CodeEditor
              id="json-editor3"
              defaultLanguage="json"
              defaultValue={innerApiDetails.responseBody}
              height="300px"
              onChange={handleResponseBodyChange}
              onValidate={validateJson("responseBody")}
              onMount={clearJsonErrors("responseBody")}
              darkMode={isADarkTheme}
              readOnly={readOnly}
              loading={loading}
            />
          </Form.Control>
          <Form.Control className="api-details__parameters-editor">
            <Form.Label>
              <TranslationLoader
                type="label"
                translationKey="pages.apisEditor.apiDetails.tabs.payload.inputs.parameterDescriptions"
                fallbackText="Parameter Descriptions"
              />
            </Form.Label>
            <CodeEditor
              id="json-editor2"
              defaultLanguage="json"
              defaultValue={innerApiDetails.parametersDescription}
              height="300px"
              onChange={handleParametersDescriptionChange}
              onValidate={validateJson("paramDesc")}
              onMount={clearJsonErrors("paramDesc")}
              darkMode={isADarkTheme}
              readOnly={readOnly}
              loading={loading}
            />
          </Form.Control>
        </Form>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={2}>
        <Terminal
          commands={commands}
          welcomeMessage={
            <div>
              {getTranslatedPlainText("pages.apisEditor.apiDetails.tabs.terminal.terminalMessages.welcomeToTerminalInfo")}
              <br />
              {getTranslatedPlainText("pages.apisEditor.apiDetails.tabs.terminal.terminalMessages.runClearPrompt")}
              <br />
            </div>
          }
        />
      </TabPanel>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="tabpanel-container">{children}</div>}
    </div>
  );
}
