import { useState, useEffect } from "react";
import axios from "axios";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import { Button, Form, Modal, Select, TranslationLoader } from "../../UI";
import { encodebody, getDecodedBody } from "../../utils/utils.js";

export default function Modallink({ clientNr, explorerId, graph, open, onClose }) {
  const { getTranslatedSelectOptions, getTranslatedPlainText } = useCustomTranslation();
  const LINK_TYPE_OPTIONS = getTranslatedSelectOptions("modals.link.inputs.linkType");
  const [sources, setSources] = useState([]);
  const [targets, setTargets] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [selectedType, setSelectedType] = useState(LINK_TYPE_OPTIONS[0].value);

  useEffect(() => {
    const fetchData = async () => {
      const myBody = {
        workflowName: graph.name,
        clientNr,
        explorerId,
      }
      try {
        const tasksresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/task/queryall", encodebody(myBody));
        const tasksresponseData = getDecodedBody(tasksresponse.data)
        setSources(tasksresponseData);
        setTargets(tasksresponseData);
        if (tasksresponseData.length > 0) {
          setSelectedSource(tasksresponseData[0].taskId);
        }
        if (tasksresponseData.length > 0) {
          setSelectedTarget(tasksresponseData[0].taskId);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSave = async () => {
    // Perform save logic with selectedSource and selectedTarget
    // You can use these values to update your backend or state, as needed
    if (await handleCreateLink(selectedSource, selectedTarget)) {
      onClose();
    }
  };

  async function handleCreateLink(nodeIdSource, nodeIdtarget)  {
    const currentLinks = graph.links

    const MyNewLinkObject = {
      source: nodeIdSource,
      target: nodeIdtarget,
      type: selectedType
    }

    if (!isNewLinkValid(MyNewLinkObject, currentLinks)) {
      alert(getTranslatedPlainText("modals.link.alerts.invalidSourceAndTargetError"));
      return false;
    }

    currentLinks.push(MyNewLinkObject);

    const mypayload = {
      workflowName: graph.name,
      links: currentLinks,
      clientNr,
      explorerId,
    };

    await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/link/update", encodebody(mypayload));
    return true;
  };

  function isNewLinkValid(myLinkObject, myLinkList) {
    if (myLinkObject.source === myLinkObject.target || isObjectInList(myLinkObject,myLinkList)) {
      return false
    }

    return true;
  }

  function isObjectInList(obj, list) {
    return list.some(item => item.source === obj.source && item.target === obj.target);
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="48rem">
      <Modal.Title>
        <TranslationLoader
          translationKey="modals.link.heading"
          fallbackText="Add new Link"
        />
      </Modal.Title>
      <Modal.Body>
        <Form>
          <Form.Control>
            <Form.Label htmlFor="source">
            <TranslationLoader
              type="label"
              translationKey="modals.link.inputs.source"
              fallbackText="Source"
            />
          </Form.Label>
            <Select
              id="source"
              defaultValue={selectedSource}
              onChange={(e) => setSelectedSource(e.target.value)}
              options={sources.map((source)=> ({ label: source.name, value: source.taskId }))}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="target">
              <TranslationLoader
                type="label"
                translationKey="modals.link.inputs.target"
                fallbackText="Target"
              />
            </Form.Label>
            <Select
              id="target"
              defaultValue={selectedTarget}
              onChange={(e) => setSelectedTarget(e.target.value)}
              options={targets.map((target) => ({ label: target.name, value: target.taskId }))}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="modalLinkType">
              <TranslationLoader
                type="label"
                translationKey="modals.link.inputs.linkType"
                fallbackText="Link Type"
              />
            </Form.Label>
            <Select
              id="modalLinkType"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              options={LINK_TYPE_OPTIONS}
            />
          </Form.Control>
          <Form.Row>
            <Button color="secondary" onClick={onClose}>
              <TranslationLoader
                type="button"
                translationKey="modals.link.buttons.cancel"
                fallbackText="Cancel"
              />
            </Button>
            <Button onClick={handleSave}>
              <TranslationLoader
                type="button"
                translationKey="modals.link.buttons.addLink"
                fallbackText="Add Link"
              />
            </Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
