import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import { Skeleton, TranslationLoader } from "../../../UI";
import "./KeyboardShortcutsView.css"

const SHORTCUTS_SKELETON = new Array(4).fill(null);

export default function KeyboardShortcutsView() {
  const { getTranslatedArray } = useCustomTranslation();
  const SHORTCUTS = getTranslatedArray("pages.apisEditor.keyboardShortcutsView.shortcuts");

  return (
    <div>
      <h2 className="shortcuts__title">
        <TranslationLoader
          translationKey="pages.apisEditor.keyboardShortcutsView.heading"
          fallbackText="Keyboard shortcuts"
        />
      </h2>
      <ul className="shortcuts__list">
        {
          SHORTCUTS === null
          ? (
            SHORTCUTS_SKELETON.map((_, index) => (
              <li className="shortcut" key={"shortcut-skeleton-" + index}>
                <span className="shortcut__description">
                  <Skeleton height="1em" width={(17 + (index % 3)) + "rem"} />
                </span>
                <span className="shortcut__key">
                  <Skeleton height="1em" width="2em" />
                </span>
              </li>
            ))
          ) : (
            SHORTCUTS.map((shortcut) => (
              <li className="shortcut" key={shortcut.key}>
                <span className="shortcut__description">{shortcut.description}</span>
                <span className="shortcut__key">{shortcut.key}</span>
              </li>
            ))
          )
        }
      </ul>
    </div>
  );
};
