import { useState } from "react";
import axios from "axios";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import { useAsync } from "../../hooks/useAsync.js";
import { Form, Icon, InputText, TextEditor, Toolbar, TranslationLoader } from "../../UI";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { encodebody, getDecodedBody } from "../../utils/utils.js";

export default function Productcomplianceview({ clientNr, explorerId, productName, authorization }) {
  const { getTranslatedPlainText } = useCustomTranslation();
  const { loading, error, retrying, retry } = useAsync(fetchProduct, [clientNr, explorerId, productName]);
  const [product, setProduct] = useState(null);
  const readOnly = !authorization.designer && !authorization.owner;

  const handleComplianceDescriptionChange = (value) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      complianceDescription: value,
    }));
  };

  const handleUpdate = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + "/product/update";

    // Define the request body
    const requestBody = {
      productName: product.productName,
      complianceDescription: product.complianceDescription,
      clientNr,
      explorerId,
    };

    await axios.post(apiUrl, encodebody(requestBody));
    alert(getTranslatedPlainText("pages.home.productComplianceView.alerts.productUpdateSuccess"));
  };

  async function fetchProduct() {
    const apiUrl = `${process.env.REACT_APP_CENTRAL_BACK}/product/query`;

    // Define the request body
    const requestBody = {
      clientNr,
      explorerId,
      productName,
    };

    try {
      // Make a POST request to fetch the product
      const response = await axios.post(apiUrl, encodebody(requestBody), {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = getDecodedBody(response.data);
      // Set the fetched product data to the state
      setProduct(data);
    } catch (error) {
      console.error("Error fetching product:", error);
      throw error;
    }
  };

  return (
    error || retrying
    ? (<FetchErrorView
        className="section-right__content"
        retry={retry}
        retrying={retrying}
      />
      ) : (
      <>
        <header className="section-right__toolbar-container">
          <Toolbar>
            {!readOnly && (
              <Toolbar.Button onClick={handleUpdate}>
                <TranslationLoader
                  type="button"
                  translationKey="pages.home.productComplianceView.toolbar.buttons.update"
                  fallbackText="Update"
                />
              </Toolbar.Button>
            )}
            <Toolbar.Item>
              <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/products-detail-panel#productcompliance-section">
                <HelpCenterIcon />
              </Icon>
            </Toolbar.Item>
          </Toolbar>
        </header>
        <div className="section-right__content">
          <Form className="section-right__form">
            <Form.Control>
              <Form.Label htmlFor="productName">
                <TranslationLoader
                  type="label"
                  translationKey="pages.home.productComplianceView.inputs.productName"
                  fallbackText="Product Name"
                />
              </Form.Label>
              <InputText
                id="productName"
                value={product?.productName}
                loading={loading}
                disabled
              />
            </Form.Control>
            <Form.Control>
              <Form.Label htmlFor="complianceDescription">
                <TranslationLoader
                  type="label"
                  translationKey="pages.home.productComplianceView.inputs.complianceDescription"
                  fallbackText="Compliance Description"
                />
              </Form.Label>
              <TextEditor
                value={product?.complianceDescription}
                onChange={handleComplianceDescriptionChange}
                loading={loading}
                readOnly={readOnly}
              />
            </Form.Control>
          </Form>
        </div>
      </>
    )
  );
}
