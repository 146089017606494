import { useState } from "react";
import axios from "axios";
import { useAsync } from "../../hooks/useAsync";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import FetchErrorView from "../fetchErrorView/FetchErrorView";
import { Form, Icon, InputText, Select, TextEditor, Toolbar, TranslationLoader } from "../../UI";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import "./taskview.css";

export default function Taskview({
    clientNr,
    explorerId,
    workflowName,
    taskId,
    authorization,
    updateGraphView
  }) {
  const { getTranslatedSelectOptions, getTranslatedPlainText } = useCustomTranslation();
  const { error, loading, retry, retrying } = useAsync(fetchApisAndTask, [workflowName, taskId])
  const TASK_TYPE_OPTIONS = getTranslatedSelectOptions("pages.home.taskView.inputs.taskType");
  const NODE_TYPE_OPTIONS = getTranslatedSelectOptions("pages.home.taskView.inputs.nodeType");
  const [selectedTaskType, setSelectedTaskType] = useState(TASK_TYPE_OPTIONS[0].value);
  const [selectedType, setSelectedType] = useState(NODE_TYPE_OPTIONS[0].value);
  const [task, setTask] = useState(null);
  const [selectedApi, setSelectedApi] = useState("");
  const [apis, setApis] = useState([]);
  const readOnly = !authorization.designer && !authorization.owner;

  async function fetchApisAndTask () {
    if(!taskId) return;
    const apiBaseUrl = process.env.REACT_APP_CENTRAL_BACK;

    // Fetch APIs
    const fetchApis = async () => {
      const myBody = {
        clientNr,
        explorerId,
      };

      try {
        const apisResponse = await axios.post(`${apiBaseUrl}/api/queryall`, encodebody(myBody));
        const myEmptyApi = { apiName: "" };
        const myApis = getDecodedBody(apisResponse.data);
        myApis.unshift(myEmptyApi);
        setApis(myApis);
      } catch (error) {
        console.error("Error fetching APIs:", error);
        throw error;
      }
    };

    // Fetch task
    const fetchTask = async () => {
      const requestBody = {
        clientNr,
        explorerId,
        workflowName,
        taskId,
      };

      try {
        const response = await axios.post(`${apiBaseUrl}/task/query`, encodebody(requestBody), {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = getDecodedBody(response.data);
        setTask(data);
        setSelectedType(data.symbolType);
        setSelectedTaskType(data.taskType);
        setSelectedApi(data.apiName);
      } catch (error) {
        console.log("Error fetching task:", error);
        throw error;
      }
    };

    await fetchApis();
    await fetchTask();
  };

  const handleDescriptionChange = (value) => {
    setTask((prevTask) => ({
      ...prevTask,
      description: value,
    }));
  };

  const handleNameChange = (event) => {
    setTask((prevTask) => ({
      ...prevTask,
      name: event.target.value,
    }));
  };

  const handleUpdate = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + "/task/update";

    // Define the request body
    const requestBody = {
      taskType: selectedTaskType,
      symbolType: selectedType,
      apiName: selectedApi,
      name: task.name,
      description: task.description,
      clientNr,
      explorerId,
      workflowName,
      taskId,
    };

    await axios.post(apiUrl, encodebody(requestBody));
    alert(getTranslatedPlainText("pages.home.taskView.alerts.taskUpdateSuccess"));
    updateGraphView();
  };

  return (
    error || retrying
    ? (<FetchErrorView
        className="section-right__content"
        retry={retry}
        retrying={retrying}
      />
    ) : (
      <>
        <header className="section-right__toolbar-container">
          <Toolbar>
            {!readOnly && (
              <Toolbar.Button onClick={handleUpdate}>
                <TranslationLoader
                  type="button"
                  translationKey="pages.home.taskView.toolbar.buttons.update"
                  fallbackText="Update"
                />
              </Toolbar.Button>
            )}
            <Toolbar.Item>
              <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/tasks-detail-panel">
                <HelpCenterIcon />
              </Icon>
            </Toolbar.Item>
          </Toolbar>
        </header>
        <div className="section-right__content">
          <Form className="section-right__form">
            <Form.Control>
              <Form.Label htmlFor="taskName">
                <TranslationLoader
                  type="label"
                  translationKey="pages.home.taskView.inputs.taskName"
                  fallbackText="Task Name"
                />
              </Form.Label>
              <InputText
                id="taskName"
                value={task?.name}
                onChange={handleNameChange}
                disabled={readOnly}
                loading={loading}
              />
            </Form.Control>
            <Form.Row>
              <Form.Control>
                <Form.Label htmlFor="taskType">
                  <TranslationLoader
                    type="label"
                    translationKey="pages.home.taskView.inputs.taskType"
                    fallbackText="Task Type"
                  />
                </Form.Label>
                <Select
                  id="taskType"
                  value={selectedTaskType}
                  className="taskview__select-task-type"
                  onChange={(e) => setSelectedTaskType(e.target.value)}
                  disabled={readOnly}
                  options={TASK_TYPE_OPTIONS}
                  loading={loading}
                  />
              </Form.Control>
              <Form.Control className="taskview__select-api">
                <Form.Label htmlFor="api">
                  <TranslationLoader
                    type="label"
                    translationKey="pages.home.taskView.inputs.implementsApi"
                    fallbackText="Implements API"
                  />
                </Form.Label>
                <Select
                  id="api"
                  value={selectedApi}
                  onChange={(e) => setSelectedApi(e.target.value)}
                  disabled= {readOnly}
                  options={apis.map(({name: apiName}) => ({label: apiName, value: apiName}))}
                  loading={loading}
                />
              </Form.Control>
            </Form.Row>
            <Form.Control>
              <Form.Label htmlFor="nodeType">
                <TranslationLoader
                  type="label"
                  translationKey="pages.home.taskView.inputs.nodeType"
                  fallbackText="Node Type"
                />
              </Form.Label>
              <Select
                id="nodeType"
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                disabled={readOnly}
                options={NODE_TYPE_OPTIONS}
                loading={loading}
              />
            </Form.Control>
            <Form.Control className="panel__text-editor-section">
              <Form.Label htmlFor="taskviewDescription">
                <TranslationLoader
                  type="label"
                  translationKey="pages.home.taskView.inputs.description"
                  fallbackText="Description"
                />
              </Form.Label>
              <TextEditor
                value={task?.description}
                onChange={handleDescriptionChange}
                readOnly={readOnly}
                loading={loading}
              />
            </Form.Control>
          </Form>
        </div>
      </>
    )
  );
}
