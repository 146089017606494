import { Route, Redirect } from "react-router-dom";

export const ProtectedRoute = ({ isAuthenticated, path, exact = false, children, ...rest }) => (
  <Route
    path={path}
    exact={exact}
    {...rest}
    render={({ location }) =>
      isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      )
    }
  />
);
