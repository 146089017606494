import {
  CodeOutlined,
  DescriptionOutlined,
  FolderOutlined,
  MoreVertOutlined,
} from "@material-ui/icons";
import { Button, Menu, TranslationLoader } from "../../UI";
import { BalanceOutlined } from "@mui/icons-material";

export default function DetailsPanelMenu ({ selectedItemType, onSelectMenuItem }) {
  const handleClick = (item) => () => onSelectMenuItem(item);

  const isCodeItem =
    selectedItemType === "api" ||
    selectedItemType === "apicode" ||
    selectedItemType === "workflowcode" ||
    selectedItemType === "taskapi" ||
    selectedItemType === "apicompliance";

  if (!selectedItemType || selectedItemType === "link") return null;

  return (
    <Menu nestedMenuPlacement="left">
      <Menu.Handler>
        <Button color="secondary" size="xs" rounded>
          <MoreVertOutlined />
        </Button>
      </Menu.Handler>
      <Menu.List>
        {(isCodeItem ||
          selectedItemType === "workflow" ||
          selectedItemType === "workflowcompliance" ||
          selectedItemType === "workflowterminal" ||
          selectedItemType === "product") && (
          <>
            {isCodeItem && selectedItemType !== "workflowcode" && (
              <Menu.Item onClick={handleClick("cURL")}>
                <CodeOutlined />
                <TranslationLoader
                  type="menu-item"
                  translationKey="pages.home.detailsPanelMenu.curl"
                  fallbackText="cURL"
                />
              </Menu.Item>
            )}
            {selectedItemType !== "product" && (
              <>
                <Menu.Item onClick={handleClick("javascript")}>
                  <CodeOutlined />
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.detailsPanelMenu.javascriptCode"
                    fallbackText="JavaScript Code"
                  />
                </Menu.Item>
                <Menu.Item onClick={handleClick("python")}>
                  <CodeOutlined />
                  <TranslationLoader
                    type="menu-item"
                    translationKey="pages.home.detailsPanelMenu.pythonCode"
                    fallbackText="Python Code"
                  />
                </Menu.Item>
              </>
            )}
            <Menu.Item onClick={handleClick("export-openapi")}>
              <FolderOutlined />
              <TranslationLoader
                type="menu-item"
                translationKey="pages.home.detailsPanelMenu.exportOpenApi"
                fallbackText="Export Open API 3.0.0"
              />
            </Menu.Item>
          </>
        )}
        <Menu.Item onClick={handleClick("description")}>
          <DescriptionOutlined />
          <TranslationLoader
            type="menu-item"
            translationKey="pages.home.detailsPanelMenu.description"
            fallbackText="Description"
          />
        </Menu.Item>
        <Menu.Item onClick={handleClick("compliancedescription")}>
          <BalanceOutlined />
          <TranslationLoader
            type="menu-item"
            translationKey="pages.home.detailsPanelMenu.compliance"
            fallbackText="Compliance"
          />
        </Menu.Item>
      </Menu.List>
    </Menu>
  );
};
