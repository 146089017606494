import { useState } from "react";
import axios from "axios";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import { useAsync } from "../../hooks/useAsync.js";
import FetchErrorView from "../fetchErrorView/FetchErrorView";
import { Button, Form, InputText, Modal, Select, TranslationLoader } from "../../UI";
import { encodebody, getDecodedBody } from "../../utils/utils.js";

export default function Modalworkflowclone({ clientNr, explorerId, open, onClose, sourceWorkflowName, SourceProductName }) {
  const { getTranslatedPlainText } = useCustomTranslation();
  const [selectedWorkflowCloneName, setSelectedWorkflowCloneName] = useState(getTranslatedPlainText("modals.workflowClone.inputs.workflowName.default"));
  const [products, setProducts] = useState([]);
  const { loading, error, retrying, retry } = useAsync(fetchData, [open]);
  const [selectedDestinationProduct, setSelectedDestinationProduct] = useState(null);

  async function fetchData() {
    if(!open) return;
    // first query products for products listbox
    const myProductsPayload = { clientNr, explorerId };

    try {
      const productresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/product/queryall", encodebody(myProductsPayload));
      const productresponseData = getDecodedBody(productresponse.data);
      setProducts(productresponseData);

      if (productresponseData.length > 0) {
        setSelectedDestinationProduct(productresponseData[0].productName);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const handleNameChange = (event) => {
    setSelectedWorkflowCloneName(event.target.value);
  };

  const handleClone = async (e) => {
    e.preventDefault();
    // Perform save logic with selectedSource and selectedTarget
    // You can use these values to update your backend or state, as needed
    if (await handleCloneWorkflow(selectedDestinationProduct, selectedWorkflowCloneName)) {
      alert(getTranslatedPlainText("modals.workflowClone.alerts.workflowCloningSuccess"))
      window.location.reload();
      onClose();
    }
  };

  async function handleCloneWorkflow(DestinationProductName, workflowCloneName) {
    try {
      const mypayload = {
        clientNr: clientNr,
        explorerId: explorerId,
        productName: SourceProductName,
        destinationProductName: DestinationProductName,
        name: sourceWorkflowName,
        newName: workflowCloneName
      };

      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/workflow/cloneworkflow", encodebody(mypayload));
      const responseData = getDecodedBody(response.data);
      // Check if the response indicates an error
      if (responseData && responseData.error) {
        // Display an alert with the error data
        alert(getTranslatedPlainText("modals.workflowClone.alerts.error", { error: responseData.error }));
        return false;
      }

      // setNodesAdded(nodesAdded + 1);
      return true;
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      console.error("An unexpected error occurred:", error);
      alert(getTranslatedPlainText("modals.workflowClone.alerts.unexpectedError"));
      return false;
    }
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="48rem">
      <Modal.Title>
        <TranslationLoader
          translationKey="modals.workflowClone.heading"
          fallbackText="Clone Workflow"
        />
      </Modal.Title>
      {
        error || retrying ? (
          <FetchErrorView
            className="section-right__content"
            retry={retry}
            retrying={retrying}
          />
        ) : (
          <Modal.Body>
            <Form onSubmit={handleClone}>
              <Form.Control>
                <Form.Label htmlFor="products">
                  <TranslationLoader
                    type="label"
                    translationKey="modals.workflowClone.inputs.productName"
                    fallbackText="Clone into Product"
                  />
                </Form.Label>
                <Select
                  id="products"
                  value={selectedDestinationProduct}
                  onChange={(e) => setSelectedDestinationProduct(e.target.value)}
                  options={products.map(({productName}) => ({label: productName, value: productName}))}
                  loading={loading}
                />
              </Form.Control>
              <Form.Control>
                <Form.Label htmlFor="workflowName">
                  <TranslationLoader
                    type="label"
                    translationKey="modals.workflowClone.inputs.workflowName"
                    fallbackText="New Workflow Name"
                  />
                </Form.Label>
                <InputText
                  id="workflowName"
                  value={selectedWorkflowCloneName}
                  onChange={handleNameChange}
                  loading={loading}
                />
              </Form.Control>
              <Form.Row>
                <Button color="secondary" onClick={onClose}>
                  <TranslationLoader
                    type="button"
                    translationKey="modals.workflowClone.buttons.cancel"
                    fallbackText="Cancel"
                  />
                </Button>
                <Button type="submit">
                  <TranslationLoader
                    type="button"
                    translationKey="modals.workflowClone.buttons.clone"
                    fallbackText="Clone"
                  />
                </Button>
              </Form.Row>
            </Form>
          </Modal.Body>
        )
      }
    </Modal>
  );
}
