import { useState } from "react";
import { UncontrolledTreeEnvironment, Tree } from "react-complex-tree";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import RenameTreeItemRenderer from "./TreeItemRenderers/RenameTreeItemRenderer";
import RenderItemRenderer from "./TreeItemRenderers/RenderItemRenderer";
import Modalapidefexport from "../modalapidefexport/Modalapidefexport";
import Modalapidefimport from "../modalapidefimport/Modalapidefimport";
import { Button, Toolbar, TranslationLoader } from "../../UI";
import ApiManagerService from "../../services/apiManagerService";
import "react-complex-tree/lib/style-modern.css";
import "./myfoldertree.css";

const DEFAULT_KEYBOARD_BINDINGS = {
  expandSiblings: ["control+*"],
  moveFocusToFirstItem: ["home"],
  moveFocusToLastItem: ["end"],
  primaryAction: ["enter"],
  renameItem: ["f2"],
  abortRenameItem: ["escape"],
  toggleSelectItem: ["control+space"],
  abortSearch: ["escape", "enter"],
  startSearch: [],
  selectAll: ["control+a"],
  startProgrammaticDnd: ["control+d"],
  completeProgrammaticDnd: ["enter"],
  abortProgrammaticDnd: ["escape"],
};

const READ_ONLY_KEYBOARD_BINDINGS = {
  expandSiblings: ["control+*"],
  moveFocusToFirstItem: ["home"],
  moveFocusToLastItem: ["end"],
  primaryAction: ["enter"],
  renameItem: [],
  abortRenameItem: [],
  toggleSelectItem: [],
  abortSearch: [],
  startSearch: [],
  selectAll: [],
  startProgrammaticDnd: [],
  completeProgrammaticDnd: [],
  abortProgrammaticDnd: [],
};

export default function MyFolderTree({
  clientNr,
  dataProvider,
  explorerId,
  focusedIndex,
  isAdmin,
  onFocusItem,
  onRenameItem,
  onStartRenamingItem,
  onCompleteRenamingItem,
  onSystemFlagToggle,
  readOnly,
  tree,
}) {
  const { getTranslatedPlainText } = useCustomTranslation();
  const [isApiDefExportModalOpen, setIsApiDefExportModalOpen] = useState(false);
  const [isApiDefImportModalOpen, setIsApiDefImportModalOpen] = useState(false);

  const createFolder = () => {
    if (!focusedIndex) {
      return alert(
        getTranslatedPlainText(
          "pages.apisEditor.myFolderTree.alerts.noLocationSelectedForNewFolderError"
        )
      );
    }

    const parentIndex = dataProvider.findParentIndex(focusedIndex);
    if (focusedIndex === "Unassigned" || parentIndex === "Unassigned") {
      return alert(
        getTranslatedPlainText(
          "pages.apisEditor.myFolderTree.alerts.invalidLocationForNewFolderError"
        )
      );
    }

    const focusedItemIsFolder = dataProvider.isFolder(focusedIndex);
    const locationIndex = focusedItemIsFolder ? focusedIndex : parentIndex;

    // UI Changes
    // Use a Windows OS-like naming system for Folders
    // If there's a Folder called "New Folder", folderName will be "New Folder (2)"
    dataProvider.createFolder(
      getTranslatedPlainText("pages.apisEditor.myFolderTree.newFolderBaseName"),
      locationIndex
    );

    // Server Changes
    ApiManagerService.updateTreeItems({
      items: dataProvider.getItems(),
      clientNr,
      explorerId,
    });
  };

  const createApi = () => {
    // By default, all APIs are created in the "Unassigned APIs" folder
    const locationIndex = "Unassigned";

    // UI Changes
    // Use a Windows OS-like naming system for APIs
    // If there's an API called "New API", apiName will be "New API (2)"
    const { data: apiName } = dataProvider.createApi(
      getTranslatedPlainText("pages.apisEditor.myFolderTree.newApiBaseName"),
      locationIndex
    );

    // Server Changes
    ApiManagerService.createApi({
      clientNr,
      explorerId,
      apiName,
    });
    ApiManagerService.updateTreeItems({
      items: dataProvider.getItems(),
      clientNr,
      explorerId,
    });

    alert(getTranslatedPlainText("pages.apisEditor.myFolderTree.alerts.apiCreationSuccess"));
  };

  const copyApi = (originalApi) => {
    // UI Changes
    // By default, all APIs are created in the "Unassigned APIs" folder
    const locationIndex = "Unassigned";
    const originalApiName = originalApi.data;
    const baseName = getTranslatedPlainText(
      "pages.apisEditor.myFolderTree.apiCopyBaseName",
      { apiName: originalApiName }
    );

    // Use a Windows OS-like naming system for APIs
    // If there's an API called "Copy of [API Name]", the API will be called "Copy of [API Name] (2)"
    const { data: newApiName } = dataProvider.createApi(
      baseName,
      locationIndex,
      originalApi.system
    );

    // Server changes
    ApiManagerService.copyApi({
      apiToCopy: originalApiName,
      newApiName,
      clientNr,
      explorerId,
    });
    ApiManagerService.updateTreeItems({
      items: dataProvider.getItems(),
      clientNr,
      explorerId,
    });

    alert(getTranslatedPlainText("pages.apisEditor.myFolderTree.alerts.apiCopySuccess"));
  };

  const copyFocusedApi = () => {
    if (!focusedIndex || dataProvider.isFolder(focusedIndex)) {
      return alert(getTranslatedPlainText("pages.apisEditor.myFolderTree.alerts.noApiToCopyError"));
    }
    const originalApi = dataProvider.getItem(focusedIndex);

    copyApi(originalApi);
  };

  const removeItem = (item) => {
    const parentIndex = dataProvider.findParentIndex(item.index);

    const items = dataProvider.getItems();
    dataProvider.removeEmptyItem(item.index);
    const wasItemFocused = item.index === focusedIndex;

    // Server changes
    ApiManagerService.updateTreeItems({ clientNr, explorerId, items });

    if (item.isFolder && wasItemFocused) {
      return onFocusItem(items[parentIndex]);
    } else {
      // Server changes
      ApiManagerService.deleteApi({
        apiName: item.data,
        clientNr,
        explorerId,
      });
      if (!wasItemFocused) return;
      tree.current.moveFocusUp();
      alert(
        getTranslatedPlainText(
          "pages.apisEditor.myFolderTree.alerts.apiDeletionSuccess"
        )
      );
    }
  };

  const removeFocusedItem = () => {
    if (!focusedIndex || focusedIndex === "root") {
      return alert(
        getTranslatedPlainText(
          "pages.apisEditor.myFolderTree.alerts.noItemSelectedToRemoveError"
        )
      );
    }

    if (focusedIndex === "MyFolders") {
      return alert(
        getTranslatedPlainText(
          "pages.apisEditor.myFolderTree.alerts.systemFolderRemovalError",
          { folderName: "MyFolders" }
        )
      );
    }

    if (focusedIndex === "Unassigned") {
      return alert(
        getTranslatedPlainText(
          "pages.apisEditor.myFolderTree.alerts.systemFolderRemovalError",
          { folderName: "Unassigned Apis" }
        )
      );
    }

    const item = dataProvider.getItem(focusedIndex);

    if (item.children?.length > 0) {
      return alert(
        getTranslatedPlainText(
          "pages.apisEditor.myFolderTree.alerts.nonEmptyFolderToRemoveError"
        )
      );
    }

    removeItem(item);
  };

  function canDrag(itemsToDrag) {
    // prohibit dragging of Unassigned and MyFolders
    if (
      itemsToDrag.some(
        (item) => item.index === "Unassigned" || item.index === "MyFolders"
      )
    ) {
      return false;
    }

    // prohibit dragging of more than one item
    return itemsToDrag.length === 1;
  }

  function canDrop(_, target) {
    return target.targetItem !== "Unassigned";
  }

  const handleDrop = () =>
    ApiManagerService.updateTreeItems({
      items: dataProvider.getItems(),
      clientNr,
      explorerId,
    });

  return (
    <div className="my-folder-tree">
      <div className="my-folder-tree__toolbar-container">
        <Toolbar>
          {!readOnly && (
            <>
              <Toolbar.Button onClick={createFolder}>
                <TranslationLoader
                  type="button"
                  translationKey="pages.apisEditor.myFolderTree.toolbar.buttons.newFolder"
                  fallbackText="New Folder"
                />
              </Toolbar.Button>
              <Toolbar.Button onClick={createApi}>
                <TranslationLoader
                  type="button"
                  translationKey="pages.apisEditor.myFolderTree.toolbar.buttons.newApi"
                  fallbackText="New API"
                />
              </Toolbar.Button>
              <Toolbar.Button onClick={copyFocusedApi}>
                <TranslationLoader
                  type="button"
                  translationKey="pages.apisEditor.myFolderTree.toolbar.buttons.copyApi"
                  fallbackText="Copy API"
                />
              </Toolbar.Button>
              <Toolbar.Item>
                <Button color="danger" size="sm" onClick={removeFocusedItem}>
                  <TranslationLoader
                    type="button"
                    translationKey="pages.apisEditor.myFolderTree.toolbar.buttons.removeItem"
                    fallbackText="Remove Item"
                  />
                </Button>
              </Toolbar.Item>
              <Toolbar.Button onClick={() => setIsApiDefImportModalOpen(true)}>
                <TranslationLoader
                  type="button"
                  translationKey="pages.apisEditor.myFolderTree.toolbar.buttons.importApis"
                  fallbackText="Import APIs"
                />
              </Toolbar.Button>
            </>
          )}
          <Toolbar.Button onClick={() => setIsApiDefExportModalOpen(true)}>
            <TranslationLoader
              type="button"
              translationKey="pages.apisEditor.myFolderTree.toolbar.buttons.exportApis"
              fallbackText="Export APIs"
            />
          </Toolbar.Button>
          {isAdmin && (
            <Toolbar.Button onClick={() => onSystemFlagToggle()}>
              <TranslationLoader
                type="button"
                translationKey="pages.apisEditor.myFolderTree.toolbar.buttons.toggleSystem"
                fallbackText="Toggle System"
              />
            </Toolbar.Button>
          )}
        </Toolbar>
      </div>
      <div className="my-folder-tree__tree-container">
        <UncontrolledTreeEnvironment
          dataProvider={dataProvider}
          getItemTitle={(item) => item.data}
          viewState={{
            ["tree-1"]: {
              focusedItem: "root",
            },
          }}
          canDragAndDrop={true}
          canReorderItems={true}
          canDropOnFolder={true}
          canDropOnNonFolder={false}
          onFocusItem={onFocusItem}
          canDrag={canDrag}
          canDropAt={canDrop}
          onStartRenamingItem={onStartRenamingItem}
          onDrop={handleDrop}
          keyboardBindings={
            readOnly ? READ_ONLY_KEYBOARD_BINDINGS : DEFAULT_KEYBOARD_BINDINGS
          }
          renderRenameInput={(props) => (
            <RenameTreeItemRenderer
              {...props}
              onStopRenamingItem={() => {
                onCompleteRenamingItem();
                tree.current?.abortRenamingItem();
              }}
              isNameInUse={(name) =>
                dataProvider.isNameInUse(name, props.item.isFolder)
              }
              protectedItems={["Unassigned", "MyFolders"]}
            />
          )}
          onRenameItem={onRenameItem}
          renderItem={(props) => (
            <RenderItemRenderer
              {...props}
              onCopyApi={copyApi}
              onRemoveItem={removeItem}
              onStartRenamingItem={onStartRenamingItem}
              readOnly={readOnly}
            />
          )}
        >
          <Tree
            treeId="tree-1"
            rootItem="root"
            treeLabel="API Folders"
            ref={tree}
          />
        </UncontrolledTreeEnvironment>
      </div>
      {/* Render the Modalapidefexport component conditionally */}
      <Modalapidefexport
        clientNr={clientNr}
        explorerId={explorerId}
        open={isApiDefExportModalOpen}
        onClose={() => setIsApiDefExportModalOpen(false)}
      />
      <Modalapidefimport
        clientNr={clientNr}
        explorerId={explorerId}
        open={isApiDefImportModalOpen}
        onClose={() => setIsApiDefImportModalOpen(false)}
      />
    </div>
  );
}
