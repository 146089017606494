import { useCustomTranslation } from "../hooks/useCustomTranslation"
import { TextEditor } from "./TextEditor"

export const TextEditorWithTranslations = ({
  height = "500px",
  loading = false,
  onChange,
  readOnly = false,
  value = ""
}) => {
  const {getTranslatedPlainText} = useCustomTranslation();

  return (
    <TextEditor
      height={height}
      loading={loading}
      onChange={onChange}
      readOnly={readOnly}
      value={value}
      useMarkdownModeLabel={getTranslatedPlainText("components.textEditor.useMarkdownButton.default")}
      useRichTextModeLabel={getTranslatedPlainText("components.textEditor.useRichTextButton.default")}
    />
  )
}
