import styled from 'styled-components';

const StyledProgressBar = styled.div`
  &.progress-bar-container {
    position: relative;
    width: 100%;
    background-color: var(--progress-bar-container-bg-clr);
    overflow: hidden;
    border-radius: 0.5em;
    height: 1.75em;
  }

  & .progress-bar {
    height: 100%;
    width: 0;
    background-color: var(--progress-bar-bg-clr);
    text-align: center;
    transition: width var(--transition-duration-slow, 400ms) ease;
  }

  & .progress-bar__label {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    color: var(--progress-bar-txt-clr);
    text-align: center;
    transform: translate(-50%, -50%);
  }
`;

/**
 * GWOCU's progress bar component
 *
 * @component
 * @param {Object} props - The props for the progress bar component.
 * @param {number} [props.progress=0] - The percentage of completion, ranging from 0 to 100. Default value: `0`.
 * @param {number} [props.decimals=0] - Number of decimal places for `progress`, ranging from 0 to 20. Default value: `0`.
 * @returns {JSX.Element} The rendered progress bar component.
 *
 * @example
 * <ProgressBar progress={50}/>
 */
export const ProgressBar = ({ progress = 0, decimals = 0 }) => {
  // Ensure the progress is within bounds
  const boundedProgress = Math.max(0, Math.min(100, progress));

  return (
      <StyledProgressBar className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${boundedProgress.toFixed(decimals)}%` }} />
        <span className="progress-bar__label">{boundedProgress}%</span>
      </StyledProgressBar>
  );
};
