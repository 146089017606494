import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "../../context/ThemeContext";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { Menu, TranslationLoader } from "../../UI";
import { themes } from "../../themes";
import "./topbar.css";

export default function Topbar() {
  const history = useHistory();
  const { theme: currentTheme, switchTheme } = useTheme();
  const { language: currentLanguage, changeLanguage, languages, getTranslatedPlainText } = useCustomTranslation();

  function handleClick() {
    const confirmed = window.confirm(getTranslatedPlainText("components.topbar.prompts.signOut"))

    if (confirmed) {
      localStorage.removeItem("user");
      history.push("/");
      window.location.reload(true);
      //history.go(0);
    }
  }

  const { user } = useContext(AuthContext);

  const handleThemeSwitch = (dataTheme) => () => {
    if(dataTheme === currentTheme) return;
    switchTheme(dataTheme);
  }

  return (
    <nav className="navbar">
      <Link to="/" className="navbar__brand">
        <div className="navbar__brand-logo">
          {user?.clientNr} GWOCU Studio:
          <span className="navbar__brand-explorer-id">{user?.explorerId}</span>
        </div>
      </Link>
      <ul className="navbar__items">
        <li className="navbar__item">
          <button onClick={handleClick} className="navbar__item-button">
            <TranslationLoader
              type="button"
              translationKey="components.topbar.buttons.signout"
              fallbackText="Sign Out"
            />
          </button>
        </li>
        <li className="navbar__item">
          <a
            target="_blank"
            className="navbar__item-button"
            href="https://wiki.gwocu.com/en/GWOCU-Studio/GWOCU-Studio"
          >
            <TranslationLoader
              translationKey="components.topbar.buttons.wiki"
              type="button"
              fallbackText="Wiki"
            />
          </a>
        </li>
        <li className="navbar__item">
          <Menu>
            <Menu.Handler>
              <button className="navbar__item-button">
                <TranslationLoader
                  type="button"
                  translationKey="components.topbar.buttons.language"
                  fallbackText="Language"
                />
              </button>
            </Menu.Handler>
            <Menu.List>
            {languages.map(({code, language}) => (
              <Menu.Item
                key={code}
                onClick={() => changeLanguage(code)}
                selected={code === currentLanguage}
              >
                {language}
              </Menu.Item>
              ))}
            </Menu.List>
          </Menu>
        </li>
        <li className="navbar__item">
          <Menu>
            <Menu.Handler>
              <button className="navbar__item-button">
                <TranslationLoader
                  type="button"
                  translationKey="components.topbar.buttons.theme"
                  fallbackText="Theme"
                />
              </button>
            </Menu.Handler>
            <Menu.List>
              {themes.map(({dataTheme, name, Icon}) => (
                <Menu.Item
                  selected={currentTheme === dataTheme}
                  key={dataTheme}
                  onClick={handleThemeSwitch(dataTheme)}
                >
                  <Icon />
                  {name}
                </Menu.Item>)
              )}
            </Menu.List>
          </Menu>
        </li>
        <li className="navbar__item navbar__item--profile">
          {user.username === "public@gwocu.com"
            ? (<span className="navbar__item-text">{user.username}</span>)
            : (<Link to="/updateuser/" className="navbar__item-button">{user.username}</Link>)
          }
        </li>
      </ul>
    </nav>
  );
}
