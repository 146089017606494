import { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext.js";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import {
  Button,
  Checkbox,
  Form,
  InputText,
  Modal,
  TranslationLoader,
} from "../../UI/index.js";
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import "./editUserRoles.css";

export default function EditUserRoles() {
  const { getTranslatedPlainText } = useCustomTranslation();
  const location = useLocation();
  const [explorer, setExplorer] = useState(null);
  const [loadingExplorer, setLoadingExplorer] = useState(true);
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const goBack = () => history.goBack();
  const { targetuser, explorerId } = location.state;

  useEffect(() => {
    const fetchExplorerData = async () => {
      const myPayload = {
        clientNr: user.clientNr,
        chatbotKey: user.chatbotKey,
        email: targetuser.email, // Use targetuser's email here
      };

      try {
        const response = await axios.post(
          process.env.REACT_APP_CENTRAL_BACK + "/users/query",
          encodebody(myPayload)
        );
        const responseData = getDecodedBody(response.data);

        const explorerData = responseData.explorers.find(
          (exp) => exp.name === explorerId
        );
        setExplorer(explorerData);
      } catch (error) {
        console.error("Error fetching explorer data:", error);
      } finally {
        setLoadingExplorer(false);
      }
    };

    fetchExplorerData();
  }, [user, targetuser, explorerId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExplorer((prevExplorer) => ({
      ...prevExplorer,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setExplorer((prevExplorer) => ({
      ...prevExplorer,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedExplorers = targetuser.explorers.map((exp) =>
      exp.name === explorer.name ? explorer : exp
    );

    const updatedUser = {
      clientNr: user.clientNr,
      chatbotKey: targetuser.chatbotKey,
      email: targetuser.email,
      explorers: updatedExplorers,
    };

    const body = encodebody(updatedUser);
    try {
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + `/users/update/`, body);
      alert(getTranslatedPlainText("pages.editUserRoles.alerts.userUpdateSuccess"));
      goBack();
    } catch (err) {
      const error = err.response ? JSON.stringify(getDecodedBody(err.response.data)) : err.message;
      alert(getTranslatedPlainText("pages.editUserRoles.alerts.userUpdateError", { error }));
    }
  };

  return (
    <Modal open={true} onClose={goBack} maxWidth="40rem">
      <Modal.Title>
        <TranslationLoader
          type="text"
          translationKey="pages.editUserRoles.heading"
          fallbackText="Edit User Roles"
        />
      </Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <InputText
            defaultValue={targetuser.email}
            readOnly
          />
          <Form.Control>
            <Form.Label>
              <TranslationLoader
                type="label"
                translationKey="pages.editUserRoles.inputs.explorerName"
                fallbackText="Explorer Name:"
              />
            </Form.Label>
            <InputText
              type="text"
              name="name"
              value={explorer?.name || ""}
              onChange={handleInputChange}
              readOnly
              fullWidth
            />
          </Form.Control>
          <Form.Col>
            <Checkbox
              name="designer"
              checked={explorer?.designer || false}
              onChange={handleCheckboxChange}
            >
              <TranslationLoader
                type="label"
                translationKey="pages.editUserRoles.inputs.designer"
                fallbackText="Designer"
              />
            </Checkbox>
            <Checkbox
              name="owner"
              checked={explorer?.owner || false}
              onChange={handleCheckboxChange}
            >
              <TranslationLoader
                type="label"
                translationKey="pages.editUserRoles.inputs.owner"
                fallbackText="Owner"
              />
            </Checkbox>
            <Checkbox
              name="reader"
              checked={explorer?.reader || false}
              onChange={handleCheckboxChange}
            >
              <TranslationLoader
                type="label"
                translationKey="pages.editUserRoles.inputs.reader"
                fallbackText="Reader"
              />
            </Checkbox>
          </Form.Col>
          <Form.Col>
            <Button type="submit" disabled={loadingExplorer}>
              <TranslationLoader
                type="button"
                translationKey="pages.editUserRoles.buttons.update"
                fallbackText="Update"
              />
            </Button>
          </Form.Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
