import { useState, useEffect } from "react";
import axios from "axios";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import { Button, Form, InputText, Modal, TranslationLoader } from "../../UI/index.js";
import { encodebody, getDecodedBody } from "../../utils/utils.js";

export default function AcceptPublicInvitation() {
  const [email, setEmail] = useState("");
  const [client, setClient] = useState("");
  const [publicUser, setPublicUser] = useState({});
  const { getTranslatedPlainText, isLoadingTranslation } = useCustomTranslation();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const tokenresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/invitation/verifytoken", { token: token });
        return tokenresponse.data;
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code that is not in the range of 2xx
          alert(getTranslatedPlainText("pages.acceptPublicInvitation.alerts.tokenVerificationError", { error: getDecodedBody(error.response.data) }));
        } else if (error.request) {
          // The request was made but no response was received
          alert(getTranslatedPlainText("pages.acceptPublicInvitation.alerts.tokenVerificationNoResponseError"));
        } else {
          // Something happened in setting up the request that triggered an Error
          alert(getTranslatedPlainText("pages.acceptPublicInvitation.alerts.tokenVerificationError", { error: error.message }));
        }

        alert(getTranslatedPlainText("pages.acceptPublicInvitation.alerts.invalidTokenError"));
        throw error;
      }
    };

    const loginPublicUser = async (myuserData) => {
      try {
        const publicLoginPayload = {
          clientNr: myuserData.clientNr,
          explorerId: myuserData.explorerId,
          email: myuserData.email,
          chatbotKey: myuserData.chatbotKey,
        };

        const publicUser = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/invitation/publiclogin", publicLoginPayload);
        setClient(myuserData.clientNr);
        setPublicUser(publicUser.data);
        return publicUser.data;
      } catch (err) {
        if (err.response) {
          // The request was made and the server responded with a status code that is not in the range of 2xx
          alert(getTranslatedPlainText("pages.acceptPublicInvitation.alerts.loginError", { error: getDecodedBody(err.response.data) }));
        } else if (err.request) {
          // The request was made but no response was received
          alert(getTranslatedPlainText("pages.acceptPublicInvitation.alerts.registrationNoResponseError"));
        } else {
          // Something happened in setting up the request that triggered an Error
          alert(getTranslatedPlainText("pages.acceptPublicInvitation.alerts.registrationError", { error: err.message }));
        }
      }
    };

    const initialize = async () => {
      // Wait for translations to prevent empty alert messages
      if(isLoadingTranslation) return;

      try {
        const myuserData = await verifyToken();
        const myPublicUser = await loginPublicUser(myuserData);
        if (!myuserData.emailWillbeRequested) {
          await handleSubmit(myuserData.clientNr, myPublicUser);
        } else {
          console.log("NO AUTOMATIC LOAD", myuserData.emailWillbeRequested);
        }
      } catch (error) {
        // Handle any error that occurred in either function
      }
    };
    initialize();
  }, [token, isLoadingTranslation]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (client, publicUser, e) => {
    if (e) e.preventDefault(); // Prevent default only if `e` is defined

    // set storage elements and redirect
    const userSettingsString = JSON.stringify(publicUser);
    localStorage.setItem("user", userSettingsString);

    if (email) {
      const emailPayload = {
        clientNr: client,
        email: email,
      };

      try {
        await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/invitation/registerpublicemail", encodebody(emailPayload));
      } catch(error) {
        console.log("Error saving email")
      }
    }

    // do a complete load
    window.location.href = "/";
  };

  return (
    <Modal open={true} showCloseButton={false} maxWidth="40rem">
      <Modal.Title>
        <TranslationLoader
          translationKey="pages.acceptPublicInvitation.heading"
          fallbackText={`Welcome to the ${client} GWOCU Studio workspace`}
          interpolationObject={{client}}
        />
      </Modal.Title>
      <Modal.Body>
        <Form onSubmit={(e) => handleSubmit(client, publicUser, e)}>
          <Form.Row>
            <p>
              <TranslationLoader
                translationKey="pages.acceptPublicInvitation.description"
                fallbackText="For an enhanced experience, please consider setting your email address. This step is optional but recommended."
              />
            </p>
          </Form.Row>
          <Form.Control>
            <Form.Label htmlFor="email">
              <TranslationLoader
                type="label"
                translationKey="pages.acceptPublicInvitation.inputs.email"
                fallbackText="Email"
              />
            </Form.Label>
            <InputText
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              />
            </Form.Control>
          <Form.Row>
            <Button type="submit">
              <TranslationLoader
                type="button"
                translationKey="pages.acceptPublicInvitation.buttons.continue"
                fallbackText="Continue"
              />
            </Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
