const crypto = require('crypto-js');
import yaml from 'js-yaml';
import axios from 'axios';

export function HeadersGlobalAdd(apiHeaders, yamlObject) {
  const globalParameters = yamlObject['Global-Parameters-Header'];

  if (!globalParameters || (globalParameters.enable ==='no') || !globalParameters.parameters || Object.keys(globalParameters.parameters).length === 0) {
    // No parameters specified, return the original apiHeaders
    return apiHeaders;
  }

  if (globalParameters.overwrite && globalParameters.overwrite.toLowerCase() === 'yes') {
    // Overwrite specified parameters in apiHeaders
    for (const param in globalParameters.parameters) {
      if (apiHeaders.hasOwnProperty(param)) {
        apiHeaders[param] = globalParameters.parameters[param];
      }
    }
  }

  // Add missing parameters to apiHeaders
  for (const param in globalParameters.parameters) {
    if (!apiHeaders.hasOwnProperty(param)) {
      apiHeaders[param] = globalParameters.parameters[param];
    }
  }

  return apiHeaders;
}

function calculateBasicAuth(username, password) {
  const credentials = `${username}:${password}`;
  const encodedCredentials = btoa(credentials);

  return `Basic ${encodedCredentials}`;
}

export function addAuthToHeaders(myheadersWithGlobals, yamlObject ) {
  const authenticationType =  yamlObject['Authentication-Type'];

  switch (authenticationType) {
    case "No-Authentication":
      return myheadersWithGlobals;
    case "Digital-Signature":
      return myheadersWithGlobals;
    case "Basic-Authentication":
        // get properties for the basic authentication
      const basicAuthentication = yamlObject['Basic-Authentication'] ;
      if (basicAuthentication.overwrite == "yes") {
        const param = "Authorization"
        myheadersWithGlobals[param] = calculateBasicAuth(basicAuthentication.username, basicAuthentication.password);
        return myheadersWithGlobals
      } else {
        return myheadersWithGlobals;
      }
    case "ApiKey":
      return myheadersWithGlobals;
    default:
      return myheadersWithGlobals;
  }
}

export function addAuthToRequestBody(myRequestBodyWithGlobals, yamlObject ) {
  const authenticationType =  yamlObject['Authentication-Type'];

  switch (authenticationType) {
    case "No-Authentication":
      return myRequestBodyWithGlobals;
    case "Digital-Signature":
      // get properties for the digital signature
      const DigitalSignature = yamlObject['Digital-Signature'] ;
      // parameters and result of the function
      const token = DigitalSignature.token ;

      const functionString = DigitalSignature.calculationFunction
      const parameterName = DigitalSignature.parameterName
      // remove the destination and signature parameter from the body if it is present 

      delete myRequestBodyWithGlobals["destination"] ;
      delete myRequestBodyWithGlobals[parameterName] ;

      const calculationFunction = new Function("token, parameters,crypto",functionString);
      const mydigitalSignature = calculationFunction(token,myRequestBodyWithGlobals,crypto);

      myRequestBodyWithGlobals[parameterName] = mydigitalSignature ;
      return myRequestBodyWithGlobals;
    case "Basic-Authentication":
      return myRequestBodyWithGlobals;
    case "ApiKey":
      return myRequestBodyWithGlobals;
    default:
      return myRequestBodyWithGlobals;
  }
}

function isFunctionParam(inputString) {
  // Check if the inputString contains the word "function"
  return inputString.includes("{");
}

export function requestBodyGlobalAdd (apiRequestBody, yamlObject) {
  const globalParameters = yamlObject['Global-Parameters-RequestBody'];

  if (!globalParameters || (globalParameters.enable ==='no') || !globalParameters.parameters || Object.keys(globalParameters.parameters).length === 0) {
    // No parameters specified, return the original apiHeaders
    return apiRequestBody;
  }

  if (globalParameters.overwrite && globalParameters.overwrite.toLowerCase() === 'yes') {
    // Overwrite specified parameters in api Request Body
    for (const param in globalParameters.parameters) {
      if (apiRequestBody.hasOwnProperty(param)) {
        // first check if it is a function or regular value parameter
        const paramValue = globalParameters.parameters[param];

        if (isFunctionParam(String(paramValue))) {
            // it is a function. Calll the function to return the value
            const functionString = String(paramValue);
            const calculationFunction = new Function(functionString);
            const myparamvalue = calculationFunction();

            apiRequestBody[param] = myparamvalue;
        } else {
          //not a function. use value
          apiRequestBody[param] = globalParameters.parameters[param];
        }
      }
    }
  }

  // Add missing parameters to body
  for (const param in globalParameters.parameters) {
    if (!apiRequestBody.hasOwnProperty(param)
        && (globalParameters.addIfMissing?.toLowerCase() ?? 'no') === 'yes'
      ) {
      const paramValue = globalParameters.parameters[param];
      if (isFunctionParam(String(paramValue))) {
          // it is a function. Calll the function to return the value
          const functionString = String(paramValue);
          const calculationFunction = new Function(functionString);
          const myparamvalue = calculationFunction();

          apiRequestBody[param] = myparamvalue;
      } else {
        //not a function. use value
        apiRequestBody[param] = globalParameters.parameters[param];
      }

    }
  }
  return apiRequestBody;
}

export function parseApiHeaders(api) {
  return api.headers.reduce((acc, header) => {
    const [key, value] = header.split(':');
    if (key && value) {
      acc[key.trim()] = value.trim();
    }
    return acc;
  }, {});
}

export async function getConfiguration(explorerObject, thirdPartyName) {
  if (thirdPartyName && thirdPartyName !== "none"){
    const myPayload = {
      clientNr: explorerObject.clientNr,
      explorerId: explorerObject.explorerId,
      name: thirdPartyName
    };


    try {
      const myThirdPartyResponse = await axios({
        method: 'post',
        url: process.env.REACT_APP_CENTRAL_BACK + '/thirdparties/query',
        data: myPayload
      });
    

      const thirdParty = myThirdPartyResponse.data;
      if (thirdParty.yaml) {
        
        return yaml.load(thirdParty.yaml);
      }
    } catch (error) {
      // If the axios call fails, the catch block catches the error and the function will return this:
      return yaml.load(explorerObject.yaml);
    }
  }
  // This return statement will execute if thirdPartyName is falsy
  return yaml.load(explorerObject.yaml);
}

export function isValidConfiguration(explorerObject) {
  try {
    // Try to parse the YAML string
    yaml.load(explorerObject.yaml);
    // If parsing succeeds, return true
    return true;
  }  catch (error) {
    // If an error occurs during parsing, return false
    return false;
  }
};

export async function makeCurlComponentFromApi(explorer, workflowName,taskId, api) {
  // Construct the curl statement
  const yamlObject = await getConfiguration(explorer, api.thirdparty)
  const apiHeaders = parseApiHeaders(api);
  const Globalheaders = HeadersGlobalAdd(apiHeaders, yamlObject);
  var headers = addAuthToHeaders(Globalheaders,yamlObject);

  var user = JSON.parse(localStorage.getItem("user"));
  var endpoint;

  try {
    const myLinkParamPayload = {
      clientNr: explorer.clientNr,
      explorerId: explorer.explorerId,
      workflowName: workflowName,
      taskId: taskId,
      chatbotKey: user.chatbotKey,
      email:user.email,
      baseUrl: api.resourcePath ? api.resourcePath : ""
    }

    var activeLinks = false;

    try {
      const myLinkParamResponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/link/querylinkparameters", myLinkParamPayload);
      var myParams = await myLinkParamResponse.data;
      if (myParams.activeLinks) {
        endpoint = myParams.path;
        activeLinks = true ;
      } else {
        endpoint = api.urlRoute;
        activeLinks = false;
      }
    } catch(error) {
      endpoint = api.urlRoute;
      activeLinks = false;
    }
  } catch(error) {
    endpoint = api.urlRoute
    activeLinks = false;
  }

  var initialRequestBodyFields = activeLinks ? { ...myParams.requestBody } : { ...api.requestBody };

  const globalRequestBody = requestBodyGlobalAdd(initialRequestBodyFields,yamlObject );
  const requestBody = addAuthToRequestBody(globalRequestBody,yamlObject);
  const apiType = api.method.toUpperCase();

  let stringifiedHeaders = Object.entries(headers).reduce((result, [header, value]) => result += `\n-H '${header}: ${value}' \\`, "");
  let stringifiedRequestBody = requestBody ? JSON.stringify(requestBody, null, 2) : ""

  const curlStatement = `curl -X ${apiType} ${endpoint} \\${stringifiedHeaders}\n-d '${stringifiedRequestBody}'`;
  return curlStatement;
};

export async function makeCurlMarkdownFromApi(explorer, workflowName, taskId, api) {
  // Construct the curl statement
  const yamlObject = await getConfiguration(explorer, api.thirdparty);

  const apiHeaders = parseApiHeaders(api);
  const globalHeaders = HeadersGlobalAdd(apiHeaders, yamlObject);
  const headers = addAuthToHeaders(globalHeaders, yamlObject);

  const user = JSON.parse(localStorage.getItem("user"));
  let endpoint;

  try {
      const myLinkParamPayload = {
          clientNr: explorer.clientNr,
          explorerId: explorer.explorerId,
          workflowName: workflowName,
          taskId: taskId,
          chatbotKey: user.chatbotKey,
          email: user.email,
          baseUrl: api.resourcePath || "",
      };

      const myLinkParamResponse = await axios.post(
          process.env.REACT_APP_CENTRAL_BACK + "/link/querylinkparameters",
          myLinkParamPayload
      );
      var myParams = await myLinkParamResponse.data;

      if (myParams.activeLinks) {
          endpoint = myParams.path;
      } else {
          endpoint = api.urlRoute;
      }
  } catch (error) {
      endpoint = api.urlRoute;
  }

  const initialRequestBodyFields = endpoint
      ? { ...myParams.requestBody }
      : { ...api.requestBody };

  const globalRequestBody = requestBodyGlobalAdd(initialRequestBodyFields, yamlObject);
  const requestBody = addAuthToRequestBody(globalRequestBody, yamlObject);

  const apiType = api.method;

  const stringifiedHeaders = headers
      ? JSON.stringify(headers, null, 2).replace(/"/g, `'`)
      : "{}";
  const stringifiedRequestBody = requestBody
      ? JSON.stringify(requestBody, null, 2).replace(/"/g, `'`)
      : "{}";

  const curlStatement = `curl -X ${apiType.toUpperCase()} "${endpoint}" \\
-H '${stringifiedHeaders}' \\
-d '${stringifiedRequestBody}'`;

  // Generate Markdown
  const markdown = `
### API: ${api.name}

---

#### cURL Example:
\`\`\`bash
${curlStatement}
\`\`\`
`;

  return markdown;
}


export async function makeCurlComponentFromApiExecutionResult(explorer, workflowName, taskId, api) {
  // Construct the curl statement
  const yamlObject = await  getConfiguration(explorer, api.thirdparty)
  const apiHeaders = parseApiHeaders(api);
  const Globalheaders = HeadersGlobalAdd(apiHeaders, yamlObject);
  var headers = addAuthToHeaders(Globalheaders, yamlObject);

  var user = JSON.parse(localStorage.getItem("user"));
  var endpoint;

  try {
    const myLinkParamPayload = {
      clientNr: explorer.clientNr,
      explorerId: explorer.explorerId,
      workflowName: workflowName,
      taskId: taskId,
      chatbotKey: user.chatbotKey,
      email:user.email,
      baseUrl: api.resourcePath ? api.resourcePath : ""
    }

    const myLinkParamResponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/link/querylinkparameters", myLinkParamPayload);
    var myParams = await myLinkParamResponse.data;
    var activeLinks = false;

    if (myParams.activeLinks) {
      endpoint = myParams.path;
      activeLinks = true ;
    } else {
      endpoint = api.urlRoute;
      activeLinks = false;
    }

  } catch(error) {
    endpoint = api.urlRoute
    activeLinks = false;
  }

  var initialRequestBodyFields =  activeLinks ? {...myParams.requestBody} : { ...api.requestBody };
  const globalRequestBody = requestBodyGlobalAdd(initialRequestBodyFields,yamlObject );
  const requestBody = addAuthToRequestBody(globalRequestBody,yamlObject);
  headers["destination"] = endpoint;

  const allowedMethodsForBody = ["POST", "PUT", "PATCH"]; 
  const fetchOptions = {
    method: api.method,
    headers: {
      ...headers,
    },
  };
  // Check if the current API method allows a body
  if (allowedMethodsForBody.includes(api.method.toUpperCase())) {
    fetchOptions.body = JSON.stringify(requestBody);
  }

  const fetchResponse = await fetch(process.env.REACT_APP_CENTRAL_BACK + "/relay", fetchOptions);
  const responseData = await fetchResponse.json();

  const resultWithStatus = {
    status: fetchResponse.status,
    resultBody: responseData
  };

  // save result for eventual workflow use
  const resultEndpoint = `${process.env.REACT_APP_CENTRAL_BACK}/api/registerapiresult`;

  const myresultPayload = {
    result: {...resultWithStatus},
    clientNr: explorer.clientNr,
    explorerId: explorer.explorerId,
    name: api.name,
    email: user.email,
    chatbotKey: user.chatbotKey,
  }

  try {
    await axios.post(resultEndpoint, myresultPayload);
  } catch(error) {
    console.log("An error occured when saving result", error);
  }

  return resultWithStatus
};

export function isObject(value) {
  return value !== null && typeof value === 'object';
};