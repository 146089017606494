import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import { Button, Checkbox, Form, InputText, Modal, ProgressBar, Skeleton, TranslationLoader } from "../../UI";
import { ArrowBackIosNewRounded } from "@mui/icons-material";
import { generateMarkdown } from "../../utils/markdownUtils";
import { makeCurlMarkdownFromApi } from "../../utils/api-spec-util.js";
import "./exportguides.css";

export default function ExportGuide ({ clientNr, explorerId, open, onClose }) {
  const { getTranslatedPlainText, getTranslatedPlaceholder } = useCustomTranslation();
  const [fileName, setFileName] = useState("export");
  const [version, setVersion] = useState("1.0");
  const [products, setProducts] = useState(null);
  const [workflows, setWorkflows] = useState({});
  const [selectedProducts, setSelectedProducts] = useState({});
  const [selectedWorkflows, setSelectedWorkflows] = useState({});
  const [selectedTasks, setSelectedTasks] = useState({});
  const [exportAllProducts, setExportAllProducts] = useState(false);
  const [collapsed, setCollapsed] = useState({}); // State to manage expand/collapse
  const [progress, setProgress] = useState(0);
  const [loadingWorkflows, setLoadingWorkflows] = useState({});
  const [loadingTasks, setLoadingTasks] = useState({});

  useEffect(() => {
    axios.post(process.env.REACT_APP_CENTRAL_BACK + "/product/queryall", { clientNr, explorerId })
      .then(response => {
        const sortedProducts = response.data?.sort((a, b) => a.sequence - b.sequence) || [];
        setProducts(sortedProducts);
      })
      .catch(error => console.error("Error fetching products:", error));
  }, [clientNr, explorerId]);

  const fetchWorkflowsForProduct = async (product) => {
    setLoadingWorkflows(prev => ({ ...prev, [product.productName]: true }));
    try {
      const workflowResponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/workflow/queryallgivenproduct", {
        clientNr: product.clientNr,
        explorerId: product.explorerId,
        productName: product.productName,
      });
      const sortedWorkflows = workflowResponse.data.sort((a, b) => a.sequence - b.sequence);
      const workflowsWithTasks = await Promise.all(sortedWorkflows.map(async (workflow) => {
        const tasks = await fetchOrderedTasks(workflow);
        return { ...workflow, tasks };
      }));
      setWorkflows(prevWorkflows => ({
        ...prevWorkflows,
        [product.productName]: workflowsWithTasks,
      }));
    } catch (error) {
      console.error(`Error fetching workflows for product ${product.productName}:`, error);
    } finally {
      setLoadingWorkflows(prev => ({ ...prev, [product.productName]: false }));
    }
  };

  const fetchOrderedTasks = async (workflow) => {
    setLoadingTasks(prev => ({ ...prev, [workflow.name]: true }));
    try {
      const taskResponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/link/queryorderedtasks", {
        clientNr: workflow.clientNr,
        explorerId: workflow.explorerId,
        workflowName: workflow.name
      });
      return taskResponse.data;
    } catch (error) {
      console.error(`Error fetching tasks for workflow ${workflow.name}:`, error);
      return [];
    } finally {
      setLoadingTasks(prev => ({ ...prev, [workflow.name]: false }));
    }
  };

  const fetchApi = async (apiName) => {
    try {
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/api/query", {
        name: apiName,
        clientNr,
        explorerId
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching API ${apiName}:`, error);
      return null;
    }
  }

  const fetchExplorer = async () => {
    try {
      const myExplorerbody = { clientNr, explorerId };
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/explorer/query", myExplorerbody);
      return response.data;
    } catch (error) {
      console.error(`Error fetching Explorer:`, error);
      return null;
    }
  }

  const toggleCollapse = (id) => {
    setCollapsed(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };

  const handleProductChange = (productId) => {
    setSelectedProducts(prevState => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };

  const updateProgress = (increment) => {
    setProgress((prevProgress) => prevProgress + increment);
  };

  const handleWorkflowChange = (workflowId, tasks) => {
    setSelectedWorkflows(prevState => {
      const newSelection = { ...prevState, [workflowId]: !prevState[workflowId] };

      // When selecting/unselecting a workflow, set all its tasks to match its new state
      const newSelectedTasks = { ...selectedTasks };
      tasks.forEach(task => {
        newSelectedTasks[task._id] = newSelection[workflowId];
      });
      setSelectedTasks(newSelectedTasks);

      return newSelection;
    });
  };

  const handleTaskChange = (taskId) => {
    setSelectedTasks(prevState => ({
      ...prevState,
      [taskId]: !prevState[taskId]
    }));
  };

  const handleExport = async () => {
    const progressIncrement = 100 / 4;
    updateProgress(progressIncrement);  //step 1

    let markdownGuide = "";
    markdownGuide += generateMarkdown(getTranslatedPlainText("modals.exportGuides.guide.products"), "H1") + "\n";

    const explorer = await fetchExplorer();

    // Determine the products to export
    const filteredProducts = exportAllProducts
      ? products
      : products.filter(product => selectedProducts[product._id]);

    for (const product of filteredProducts) {
      // Fetch workflows for the current product
      await fetchWorkflowsForProduct(product);

      markdownGuide += generateMarkdown(product.productName, "H1") + "\n";
      markdownGuide += generateMarkdown(product.description, null) + "\n\n";

      const productWorkflows = workflows[product.productName] || [];
      markdownGuide += generateMarkdown(getTranslatedPlainText("modals.exportGuides.guide.workflowsInProduct", { productName: product.productName }), "H1") + "\n";

      for (const workflow of productWorkflows) {
        // Check workflow selection if not exporting all
        if (!exportAllProducts && !selectedWorkflows[workflow._id]) continue;

        markdownGuide += generateMarkdown(getTranslatedPlainText("modals.exportGuides.guide.workflowName", { workflowName: workflow.name }), "H2") + "\n";
        markdownGuide += generateMarkdown(workflow.description, null) + "\n\n";

        const tasks = workflow.tasks || await fetchOrderedTasks(workflow);
        markdownGuide += generateMarkdown(getTranslatedPlainText("modals.exportGuides.guide.tasksInWorkflow", { workflowName: workflow.name }), "H3") + "\n";

        for (const task of tasks) {
          // Check task selection if not exporting all
          if (!exportAllProducts && !selectedTasks[task._id]) continue;
          markdownGuide += generateMarkdown(getTranslatedPlainText("modals.exportGuides.guide.taskName", { taskName: task.name }), "H3") + "\n";
          markdownGuide += generateMarkdown(task.description, null) + "\n\n";

          if (task.apiName === "") continue;

          const api = await fetchApi(task.apiName);
          if (!api) continue;

          markdownGuide += generateMarkdown(getTranslatedPlainText("modals.exportGuides.guide.apiForTask", { taskName: task.name }), "H4") + "\n";

          // Include API name and description
          markdownGuide += generateMarkdown(getTranslatedPlainText("modals.exportGuides.guide.apiName", { apiName: task.name }) + api.name, "H5") + "\n";
          markdownGuide += generateMarkdown(api.description, null) + "\n";

          // Include API curl command
          const myCurl = await makeCurlMarkdownFromApi(explorer, workflow.name, task.taskId, api);
          markdownGuide += myCurl + "\n";

          // Include Request Body
          if (api.requestBody && Object.keys(api.requestBody).length > 0) {
            markdownGuide += generateMarkdown(getTranslatedPlainText("modals.exportGuides.guide.requestBody"), "H5") + "\n";
            markdownGuide += generateMarkdown(JSON.stringify(api.requestBody, null, 2), null, true) + "\n";
          }

          // Include Parameters Description
          if (api.parametersDescription && Object.keys(api.parametersDescription).length > 0) {
            markdownGuide += generateMarkdown(getTranslatedPlainText("modals.exportGuides.guide.parameterDescriptions"), "H5") + "\n";
            markdownGuide += generateMarkdown(JSON.stringify(api.parametersDescription, null, 2), null, true) + "\n";
          }

          // Include Response Body
          if (api.responseBody && Object.keys(api.responseBody).length > 0) {
            markdownGuide += generateMarkdown(getTranslatedPlainText("modals.exportGuides.guide.responseBodyExample"), "H5") + "\n";
            markdownGuide += generateMarkdown(JSON.stringify(api.responseBody, null, 2), null, true) + "\n";
          }

          // Include Additional Information
          if (api.thirdparty && api.thirdparty !== "none") {
            markdownGuide += generateMarkdown(getTranslatedPlainText("modals.exportGuides.guide.apiAction", { apiActionName: api.thirdparty}), null) + "\n";
          }

          markdownGuide += generateMarkdown("", null, false, true); // Page break
        }
      }
    }

    updateProgress(progressIncrement);  //step 2

    // Generate the Markdown file
    const fullFileName = `${fileName}_v${version}.md`;
    const blob = new Blob([markdownGuide], { type: "text/markdown" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fullFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    updateProgress(progressIncrement);  //step 3
    setProgress(100);
    setTimeout(() => setProgress(0), 500);
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="48rem">
      <Modal.Title>
        <TranslationLoader
          translationKey="modals.exportGuides.heading"
          fallbackText="Export Guides"
        />
      </Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleExport}>
          <Form.Control>
            <Form.Label htmlFor="fileName">
              <TranslationLoader
                type="label"
                translationKey="modals.exportGuides.inputs.fileName"
                fallbackText="File Name"
              />
            </Form.Label>
            <InputText
              id="fileName"
              value={fileName}
              onChange={e => setFileName(e.target.value)}
              placeholder={getTranslatedPlaceholder("modals.exportGuides.inputs.fileName")}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="version">
              <TranslationLoader
                type="label"
                translationKey="modals.exportGuides.inputs.version"
                fallbackText="Version"
              />
            </Form.Label>
            <InputText
              id="version"
              value={version}
              onChange={e => setVersion(e.target.value)}
              placeholder={getTranslatedPlaceholder("modals.exportGuides.inputs.version")}
            />
          </Form.Control>
          <Form.Control>
            <Checkbox
              id="exportAllProducts"
              checked={exportAllProducts}
              onChange={e => setExportAllProducts(e.target.checked)}
              >
                <TranslationLoader
                  type="label"
                  translationKey="modals.exportGuides.inputs.exportAll"
                  fallbackText="Export all products to guides"
                />
            </Checkbox>
          </Form.Control>
          <hr className="export-guides__divider" />
          <div className="export-guides__tree-view">
            <div className="export-guides__nodes export-guides__nodes--root">
              {products === null
              ? (<div className="export-guides__node">
                  <Skeleton
                    height="3.1rem"
                    width="3.1rem"
                    borderRadius="50%"
                  />
                  <Checkbox loading>
                    <Skeleton height="1em" />
                  </Checkbox>
                </div>
              ) : (
                products.map((product) => (
                  <React.Fragment key={product._id}>
                    {/* Container for Product Checkbox and Name */}
                    <div className="export-guides__node">
                      <Button
                        color="secondary"
                        rounded
                        size="sm"
                        onClick={() => {
                          toggleCollapse(product._id);
                          if (!workflows[product.productName]) {
                            fetchWorkflowsForProduct(product); // Fetch workflows when expanding a product
                          }
                        }}
                      >
                        <ArrowBackIosNewRounded
                          className={`export-guides__button-icon ${
                            collapsed[product._id] ? "export-guides__button-icon--expanded" : ""
                          }`}
                        />
                      </Button>
                      <Checkbox
                        checked={!!selectedProducts[product._id] || exportAllProducts}
                        onChange={() => handleProductChange(product._id)}
                        disabled={exportAllProducts}
                      >
                        <span className="export-guides__node-label">
                          {product.productName}
                        </span>
                      </Checkbox>
                    </div>
                    {loadingWorkflows[product.productName] && collapsed[product._id] && (
                      <div className="export-guides__nodes export-guides__nodes--children">
                        <div className="export-guides__node">
                          <Skeleton
                            height="3.1rem"
                            width="3.1rem"
                            borderRadius="50%"
                          />
                          <Checkbox loading>
                            <Skeleton height="1em" />
                          </Checkbox>
                        </div>
                      </div>
                    )}
                    {/* Collapsible Workflows for Product */}
                    {collapsed[product._id] && workflows[product.productName]?.length > 0 && (
                      <div className="export-guides__nodes export-guides__nodes--children">
                        {workflows[product.productName].map((workflow) => (
                          <React.Fragment key={workflow._id}>
                            <div className="export-guides__node">
                              <Button
                                color="secondary"
                                rounded
                                size="sm"
                                onClick={() => toggleCollapse(workflow._id)}
                              >
                                <ArrowBackIosNewRounded
                                  className={`export-guides__button-icon ${
                                    collapsed[workflow._id] ? "export-guides__button-icon--expanded" : ""
                                  }`}
                                />
                              </Button>
                              <Checkbox
                                checked={!!selectedWorkflows[workflow._id] || exportAllProducts}
                                onChange={() => handleWorkflowChange(workflow._id, workflow.tasks)}
                                disabled={!selectedProducts[product._id] || exportAllProducts}
                              >
                                <span className="export-guides__node-label">
                                  {workflow.name}
                                </span>
                              </Checkbox>
                            </div>
                            {loadingTasks[workflow.name] && (
                              <div className="export-guides__nodes export-guides__nodes--children">
                                <div className="export-guides__node">
                                  <Checkbox loading>
                                    <Skeleton height="1em" />
                                  </Checkbox>
                                </div>
                              </div>
                            )}
                            {/* Collapsible Tasks for Workflow */}
                            {collapsed[workflow._id] && workflow.tasks.length > 0 && (
                              <div className="export-guides__nodes export-guides__nodes--children">
                                {workflow.tasks.map((task) => (
                                  <div className="export-guides__node">
                                    <Checkbox
                                      key={task._id}
                                      checked={!!selectedTasks[task._id] || exportAllProducts}
                                      onChange={() => handleTaskChange(task._id)}
                                      disabled={!selectedWorkflows[workflow._id] || exportAllProducts}
                                    >
                                      <span className="export-guides__node-label">
                                        {task.name}
                                      </span>
                                    </Checkbox>
                                  </div>
                                ))}
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
              )))}
            </div>
          </div>
          {progress > 0 && (
            <Form.Row>
              <ProgressBar progress={progress} />
            </Form.Row>
          )}
          <Form.Row>
            <Button color="secondary" onClick={onClose}>
              <TranslationLoader
                type="button"
                translationKey="modals.exportGuides.buttons.cancel"
                fallbackText="Cancel"
              />
            </Button>
            <Button type="submit">
              <TranslationLoader
                type="button"
                translationKey="modals.exportGuides.buttons.exportGuide"
                fallbackText="Export Guide"
              />
            </Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
