import { useState, useEffect } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import jsYaml from "js-yaml";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import { Button, Checkbox, Form, Icon, InputText, Modal, ProgressBar, Select, TranslationLoader } from "../../UI";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { encodebody, getDecodedBody,convertToOpenAPI } from "../../utils/utils.js";

export default function ModalApiDefExport ({ clientNr, explorerId, open, onClose }) {
  const { getTranslatedPlainText } = useCustomTranslation();
  const [fileName, setFileName] = useState("api-export");
  const [apis, setApis] = useState([]);
  const [selectedApis, setSelectedApis] = useState([]);
  const [exportAllApis, setExportAllApis] = useState(false);
  const [exportType, setExportType] = useState("openApi"); // State for export type
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);

  useEffect(() => {
    if (!open) return;

    // Fetch all APIs when the modal is opened
    axios.post(process.env.REACT_APP_CENTRAL_BACK + "/api/queryall", encodebody({ clientNr, explorerId }))
      .then(response => {
        const apiList = getDecodedBody(response.data);
        setApis(apiList);
        setSelectedApis(apiList.map(api => api.name)); // Default to selecting all APIs
      })
      .catch(error => console.error("Error fetching APIs:", error));
  }, [clientNr, explorerId, open]);

  const handleExport = async (e) => {
    e.preventDefault();

    setShowProgressBar(true);
    setProgress(0);

    let apiListToExport = exportAllApis ? apis : apis.filter(api => selectedApis.includes(api.name));

    if (apiListToExport.length === 0) {
      setShowProgressBar(false);
      return alert(getTranslatedPlainText("modals.apiDefExport.alerts.noApiSelectedForExportError"));
    }

    try {
      let exportObject;

      // Choose the conversion function based on exportType
      if (exportType === "openApi") {
        exportObject = convertToOpenAPI(apiListToExport, "API COLLECTION CLIENT", "Selected APIs for export workspace");
      } else {
        exportObject = convertToStudioAPI(apiListToExport); // Placeholder function
      }

      const yamlContent = jsYaml.dump(exportObject, { skipInvalid: true });
      const blob = new Blob([yamlContent], { type: "text/yaml" });
      saveAs(blob, `${fileName}.yaml`);
      setProgress(100);
    } catch (error) {
      console.error("Error exporting APIs:", error);
    }

    setShowProgressBar(false);
  };

  const convertToStudioAPI = (apiList) => {
    // Initialize an empty object to store the structured API data
    let studioApiObject = {
      apis: [] // Placeholder for the list of APIs
    };

    for (const api of apiList) {
      studioApiObject.apis.push({ ...api });
    }
    // Return the constructed studioApiObject which can be used for YAML conversion
    return studioApiObject;
  };

  const handleExportAllApisChange = (e) => {
    setExportAllApis(e.target.checked);
    if (e.target.checked) {
      setSelectedApis(apis.map(api => api.name)); // Select all APIs
    } else {
      setSelectedApis([]); // Deselect all
    }
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="48rem">
      <Modal.Title>
        <TranslationLoader
          translationKey="modals.apiDefExport.heading"
          fallbackText="Export API Definitions"
        />
        <Icon
          size="sm"
          href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel-menu#importapi-section"
        >
          <HelpCenterIcon />
        </Icon>
      </Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleExport}>
          <Form.Control>
            <Form.Label htmlFor="fileName">
              <TranslationLoader
                type="label"
                translationKey="modals.apiDefExport.inputs.fileName"
                fallbackText="File Name"
              />
            </Form.Label>
            <InputText
              id="fileName"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              placeholder="File Name"
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="exportType">
              <TranslationLoader
                type="label"
                translationKey="modals.apiDefExport.inputs.exportFormat"
                fallbackText="Export Format"
              />
            </Form.Label>
            <Select
              id="exportType"
              value={exportType}
              onChange={(e) => setExportType(e.target.value)}
              options={[
                { label: "OpenAPI", value: "openApi" },
                { label: "StudioAPI", value: "studioApi" }
              ]}
            />
          </Form.Control>
          <Form.Row>
            <Checkbox
              id="exportAllApis"
              checked={exportAllApis}
              onChange={handleExportAllApisChange}
            >
              <TranslationLoader
                type="label"
                translationKey="modals.apiDefExport.inputs.exportAllApis"
                fallbackText="Export all APIs or choose below"
              />
            </Checkbox>
          </Form.Row>
          {!exportAllApis && (
            <Form.Row>
              <Select
                value={selectedApis}
                onChange={(e) => (
                  setSelectedApis([...e.target.selectedOptions].map(option => option.value))
                )}
                options={apis.map(({ name }) => ({ label: name, value: name }))}
                multiple
              />
            </Form.Row>
          )}
          {showProgressBar && (
            <Form.Row>
              <ProgressBar progress={progress} />
            </Form.Row>
          )}
          <Form.Row>
            <Button color="secondary" onClick={onClose}>
              <TranslationLoader
                type="button"
                translationKey="modals.apiDefExport.buttons.cancel"
                fallbackText="Cancel"
              />
            </Button>
            <Button type="submit">
              <TranslationLoader
                type="button"
                translationKey="modals.apiDefExport.buttons.exportApis"
                fallbackText="Export APIs"
              />
            </Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
