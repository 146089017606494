import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const LANGUAGES = [
  {
    code: "en",
    language: "English"
  },
  {
    code: "es",
    language: "Español"
  }
];

i18n
  .use(Backend)
  .use(LanguageDetector) // For persistance across sessions
  .use(initReactI18next)
  .init({
    // Show logs in the browser console
    /* debug: true, */
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      // relative to public folder
      loadPath: "/locales/{{lng}}/translation.json",
    },
    react: {
      // Loads the text as soon as possible,
      // preventing permanent blank spaces (due to lack of fallback components)
      useSuspense: false,
    },
  });
