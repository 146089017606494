import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import { OptionMenu } from "./OptionMenu";
import { Button, Tooltip, TranslationLoader } from "../../../UI";
import { Delete, MoreVertOutlined } from "@material-ui/icons";
import { DriveFileRenameOutlineRounded } from "@mui/icons-material";
import CopyIcon from "./CopyIcon";

export default function TreeItemOptions({
  className,
  item,
  onCopy,
  onRemove,
  onRename,
}) {
  const { getTranslatedTooltipConfig } = useCustomTranslation();

  return (
    <OptionMenu>
      <OptionMenu.Handler>
        <Tooltip
          tooltip={getTranslatedTooltipConfig("pages.apisEditor.treeItemOptions.handler")}
          showDelay={500}
        >
          <Button
            className={className}
            color="secondary"
            rounded={true}
            size="xs"
          >
            <MoreVertOutlined />
          </Button>
        </Tooltip>
      </OptionMenu.Handler>
      <OptionMenu.List>
        <OptionMenu.Item
          onClick={onRename}
        >
          <DriveFileRenameOutlineRounded />
          <TranslationLoader
            type="menu-item"
            translationKey="pages.apisEditor.treeItemOptions.rename"
            fallbackText="Rename"
          />
        </OptionMenu.Item>
        {!item.isFolder && (
          <OptionMenu.Item
            onClick={onCopy}
          >
            <CopyIcon />
            <TranslationLoader
              type="menu-item"
              translationKey="pages.apisEditor.treeItemOptions.copy"
              fallbackText="Copy"
            />
          </OptionMenu.Item>
        )}
        {(!item.children || item.children?.length === 0) && (
          <OptionMenu.Item
            onClick={onRemove}
            className="rct-tree-item-more-options-delete-button"
          >
            <Delete />
            <TranslationLoader
              type="menu-item"
              translationKey="pages.apisEditor.treeItemOptions.remove"
              fallbackText="Remove"
            />
          </OptionMenu.Item>
        )}
      </OptionMenu.List>
    </OptionMenu>
  );
};