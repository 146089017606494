import axios from "axios";
import {encodebody, getDecodedBody} from "./utils/utils.js";

export const loginCall = async (userCredential, dispatch) => {
  dispatch({ type: "LOGIN_START" });
  try {
    const myUserPayload = {
      clientNr: userCredential.clientNr,
      email: userCredential.email,
      password: userCredential.password,
      explorer: userCredential.explorer,
      appname: "APIFNY"
    }

    const userPayload = myUserPayload;
    const res = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/auth/login", userPayload);

    const mypayload = {
      clientNr: userCredential.clientNr,
      ...res.data
    }

    dispatch({ type: "LOGIN_SUCCESS", payload: mypayload });
  } catch (err) {
    dispatch({ type: "LOGIN_FAILURE", payload: err });
    throw err;
  }
};
