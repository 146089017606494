import { useState, useEffect } from "react"
import axios from "axios";
import { useTheme } from "../../context/ThemeContext";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { SyntaxHighlighter } from "../../UI";
import { generateJavaScriptCode, generatePythonCode, getDecodedBody, encodebody } from "../../utils/utils.js";

export default function WorkflowCode ({ clientNr, explorerId, productName, workflowName, codeType }) {
  const { getTranslatedPlainText, language } = useCustomTranslation();
  const [explorer, setExplorer] = useState([]);
  const [generatedCode, setGeneratedCode] = useState("");
  const [apiList, setApiList] = useState(null);
  const { isADarkTheme } = useTheme();

  useEffect(() => {
    fetchApi();
  }, [clientNr, explorerId, productName, workflowName, codeType]);

  useEffect(() => {
    if(!apiList) return;
    generateCode(apiList);
  }, [language]);

  async function fetchApi() {
    try {
      const myApibody = {
        clientNr,
        explorerId,
        workflowName
      }
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/link/queryorderedapi", encodebody(myApibody));
      const myApiList = getDecodedBody(response.data);

      setApiList(myApiList);
      generateCode(myApiList);

      const myExplorerbody = { clientNr, explorerId };

      const Eresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/explorer/query", encodebody(myExplorerbody));
      const myExplorer = getDecodedBody(Eresponse.data);
      setExplorer(myExplorer);
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };

  const generateCode = (apis) => {
    const interpolationObj = { workflowName };
    let combinedPythonCode = getTranslatedPlainText("pages.home.workflowCode.pythonIntroComment", interpolationObj);
    let combinedJavaScriptCode = getTranslatedPlainText("pages.home.workflowCode.javascripIntroComment", interpolationObj);
    const apiNameComment = getTranslatedPlainText("pages.home.workflowCode.apiNameComment");

    for (const api of apis) {
      if (Object.keys(api).length === 0) continue;
      const requestBodyFields = {};
      if (codeType === "python") {
        const pythonCode = generatePythonCode(api.api, explorer, requestBodyFields, apiNameComment);
        combinedPythonCode += pythonCode;
      } else if (codeType === "javascript") {
        const javascriptCode = generateJavaScriptCode(api.api, explorer, requestBodyFields, apiNameComment);
        combinedJavaScriptCode += javascriptCode;
      }
    }

    // Determine the combined code based on the selected codeType
    const combinedCode = codeType === "python" ? combinedPythonCode : combinedJavaScriptCode;

    // Set the combined code using setGeneratedCode
    setGeneratedCode(combinedCode);
  };

  return (
    <>
      <div className="section-right__toolbar-container section-right__toolbar-container--empty" />
      <div className="section-right__content">
        <div className="section-right__syntax-highlighter">
          <SyntaxHighlighter
            code={generatedCode}
            darkMode={isADarkTheme}
          />
        </div>
      </div>
    </>
  );
};
