import { Replay } from "@mui/icons-material";
import { Button, Icon, Spinner, TranslationLoader } from "../../UI";
import "./FetchErrorView.css";

export default function FetchErrorView({
  className,
  errorMessage,
  retry,
  retrying,
}) {
  const classes = `fetch-error ${className || ""}`;
  return (
    <div className={classes}>
      <p className="fetch-error__message">
        {errorMessage ?? (
          <TranslationLoader
            translationKey="pages.home.fetchErrorView.error"
            fallbackText="Error loading information"
          />
        )}
      </p>
      <Button
        className="fetch-error__button"
        onClick={retry}
        disabled={retrying}
      >
        {retrying ? (
          <>
            <span>
              <TranslationLoader
                translationKey="pages.home.fetchErrorView.loading"
                fallbackText="Loading..."
              />
            </span>
            <Spinner thickness={5} />
          </>
        ) : (
          <>
            <span>
              <TranslationLoader
                translationKey="pages.home.fetchErrorView.retry"
                fallbackText="Try again"
              />
            </span>
            <Icon size="xs" color="primary">
              <Replay />
            </Icon>
          </>
        )}
      </Button>
    </div>
  );
};
