export class CustomDataProviderImpl {
  // Initial data is mandatory to drag and drop items
  data = {
    root: {
      index: "root",
      isFolder: true,
      data: "Root item",
    },
  };
  apiNames = new Set();
  folderNames = new Set();
  treeChangeListeners = [];

  constructor(idGenerator, showSystem) {
    this.idGenerator = idGenerator || Math.random;
    this.showSystem = showSystem || false;
  }

  getItems() {
    return this.data;
  }

  async getTreeItem(itemId) {
    const item = this.data[itemId] || null;
    if (this.showSystem) return item;
    return item?.system ? null : item;
  }

  getItem(itemIndex) {
    return this.data[itemIndex];
  }

  isFolder(itemId) {
    return !!this.data[itemId]?.isFolder;
  }

  async onChangeItemChildren(itemId, newChildren) {
    this.data[itemId].children = newChildren;
    this.treeChangeListeners.forEach((listener) => listener([itemId]));
  }

  setItems(items) {
    this.data = { ...items };

    for (let index in items) {
      const item = items[index];
      if (item.isFolder) {
        this.folderNames.add(item.data);
      } else {
        this.apiNames.add(item.data);
      }
    }

    this.treeChangeListeners.forEach((listener) => listener(["root"]));
  }

  onDidChangeTreeData(listener) {
    this.treeChangeListeners.push(listener);
    return {
      dispose: () =>
        this.treeChangeListeners.splice(
          this.treeChangeListeners.indexOf(listener),
          1
        ),
    };
  }

  async renameItem(item, newName) {
    const oldName = item.data;
    this.data[item.index].data = newName;

    if (item.isFolder) {
      this.folderNames.delete(oldName);
      this.folderNames.add(newName);
    } else {
      this.apiNames.delete(oldName);
      this.apiNames.add(newName);
    }

    const parentIndex = this.findParentIndex(item.index);
    this.treeChangeListeners.forEach((listener) => listener([parentIndex]));
  }

  createFolder(baseName, parentIndex) {
    const folderIndex = this.generateId();
    const folderName = this.generateFolderName(baseName);

    const folderItem = {
      data: folderName,
      index: folderIndex,
      isFolder: true,
      children: [],
    };

    this.data[folderIndex] = folderItem;
    this.data[parentIndex].children.push(folderIndex);

    this.treeChangeListeners.forEach((listener) => listener([parentIndex]));
    return folderItem;
  }

  createApi(baseName, parentIndex, system) {
    const apiIndex = this.generateId();
    const apiName = this.generateApiName(baseName);

    const apiItem = {
      index: apiIndex,
      data: apiName,
      system,
    };

    this.data[apiIndex] = apiItem;
    this.data[parentIndex].children.push(apiIndex);

    this.treeChangeListeners.forEach((listener) => listener([parentIndex]));

    return apiItem;
  }

  removeEmptyItem(itemIndex) {
    const parentIndex = this.findParentIndex(itemIndex);
    const parent = this.data[parentIndex];

    const { data: itemName, isFolder } = this.data[itemIndex];

    parent.children = parent.children.filter(
      (currIndex) => currIndex !== itemIndex
    );
    delete this.data[itemIndex];
    isFolder
      ? this.folderNames.delete(itemName)
      : this.apiNames.delete(itemName);

    this.treeChangeListeners.forEach((listener) => listener([parentIndex]));
  }

  findParentIndex(childIndex) {
    if (childIndex === "root") return childIndex;

    for (const [parentIndex, parentItem] of Object.entries(this.data)) {
      if (parentItem.children && parentItem.children.includes(childIndex)) {
        return parentIndex;
      }
    }
    return null; // Return null if the parent is not found
  }

  toggleSystemFlag(itemIndex) {
    this.data[itemIndex].system = !this.data[itemIndex].system;
    const parentIndex = this.findParentIndex(itemIndex);
    this.treeChangeListeners.forEach((listener) => listener([parentIndex]));
  }

  isNameInUse(name, isFolder) {
    return isFolder ? this.folderNames.has(name) : this.apiNames.has(name);
  }

  generateName(baseName, nameSet) {
    if (!nameSet.has(baseName)) {
      nameSet.add(baseName);
      return baseName;
    }

    let counter = 2;
    let candidateName;
    do {
      candidateName = `${baseName} (${counter})`;
      counter++;
    } while (nameSet.has(candidateName));

    nameSet.add(candidateName);
    return candidateName;
  }

  generateFolderName(baseName) {
    return this.generateName(baseName, this.folderNames);
  }

  generateApiName(baseName) {
    return this.generateName(baseName, this.apiNames);
  }

  generateId() {
    let id = this.idGenerator();

    while (this.data[id] !== undefined) {
      id = this.idGenerator();
    }

    return id;
  }
}
