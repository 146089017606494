console.warn = () => {};
console.error = () => {};
// console.log = () => {};
console.info = () => {};
console.debug = () => {};

import { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import axios from 'axios';
import { AuthContext } from "./context/AuthContext";
import Login from "./pages/login/Login";
import EditUserRoles from "./pages/editUserRoles/EditUserRoles.jsx";
import ChangePassword from "./pages/changePassword/ChangePassword";
import Explorer from "./pages/explorer/Explorer";
import AcceptPrivateInvitation from "./pages/acceptPrivateInvitation/AcceptPrivateInvitation.jsx";
import AcceptPublicInvitation from "./pages/acceptPublicInvitation/AcceptPublicInvitation.jsx";
import ApisEditor from './pages/apisEditor/ApisEditor.jsx';
import { getDecodedBody, encodebody } from "./utils/utils.js";
import { ProtectedRoute } from './components/protectedRoute/ProtectedRoute.jsx';

export default function App() {
  const { user } = useContext(AuthContext);
  const isAuthenticated = user !== null;
  const [authorization, setAuthorization] = useState({ name: "1", designer: false, owner: false, reader: false });

  useEffect(() => {
    const getUserAuthorization = async () => {
      if (!user) {
        // No user is logged in, so skip making any axios calls
        // setAuthorization({name: "1", designer: false, owner: false, reader: false});
        return;
      }

      try {
        const versionResponse = await axios.post(`${process.env.REACT_APP_CENTRAL_BACK}/users/version`, { clientNr:"1" });
        const currentVersion = versionResponse.data.versionNr;

        // Compare the version in user object with the fetched version
        if (!user.version || user.version !== currentVersion) {
          // Versions do not match, delete the user object from storage and redirect to login
          localStorage.removeItem('user'); // Adjust according to your storage mechanism
          // setUser(null); // Update the context to reflect the user being logged out
          window.location.reload();
          return; // Exit early to prevent further processing
        }

        const userPayload = {
          clientNr: user.clientNr,
          chatbotKey: user.chatbotKey,
          email: user.email,
        };
        const res = await axios.post(`${process.env.REACT_APP_CENTRAL_BACK}/users/query`, encodebody(userPayload));
        const resData = getDecodedBody(res.data)
        const currentAuth = resData.explorers.find(explorer => explorer.name === user.explorerId);
        //const currentAuth = res.data.explorers[user.explorerId]
        setAuthorization(currentAuth);
        // Assuming the response might affect authorization somehow
        // Process response if necessary, e.g., res.data
      } catch (error) {
        console.error('Failed to fetch authorization or version:', error);
        // setAuthorization({name: "1", designer: false, owner: false, reader: false});
      }
    };

    getUserAuthorization();
  }, [user]);

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/acceptinvite">
          <AcceptPrivateInvitation />
        </Route>
        <Route path="/acceptpublicinvite">
          <AcceptPublicInvitation />
        </Route>
        <ProtectedRoute
          exact
          path={["/", "/explorer"]}
          isAuthenticated={isAuthenticated}
        >
          <Explorer
            clientNr={user?.clientNr}
            explorerId={user?.explorerId || "1"}
            authorization={authorization}
          />
        </ProtectedRoute>
        <ProtectedRoute path="/updateuser" isAuthenticated={isAuthenticated}>
          <ChangePassword />
        </ProtectedRoute>
        <ProtectedRoute path="/apiseditor" isAuthenticated={isAuthenticated}>
          <ApisEditor
            clientNr={user?.clientNr}
            explorerId={user?.explorerId || "1"}
            authorization={authorization}
          />
        </ProtectedRoute>
        <ProtectedRoute path="/edituser" isAuthenticated={isAuthenticated}>
          <EditUserRoles />
        </ProtectedRoute>
      </Switch>
    </Router>
  );
}
