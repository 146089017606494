import { useState, useEffect } from "react";
import axios from "axios";
import crypto from "crypto-js";
import ReactJson from "react-json-view";
import { useTheme } from "../../context/ThemeContext";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { useAsync } from "../../hooks/useAsync.js";
import { CodeEditor, Form, Icon, Skeleton, Terminal, Textarea, Toolbar, TranslationLoader } from "../../UI";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { HeadersGlobalAdd, requestBodyGlobalAdd, addAuthToHeaders, addAuthToRequestBody, parseApiHeaders, getConfiguration, isValidConfiguration } from "../../utils/api-spec-util.js";
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import "./apiTerminal.css";

export default function ApiTerminal({
    clientNr,
    explorerId,
    workflowName,
    apiName,
    OnGetTask,
    authorization,
    onFinishExecution,
    taskName
  }) {
  const [response, setResponse] = useState("");
  const { getTranslatedPlainText } = useCustomTranslation();
  const [route, setRoute] = useState("");
  const [api, setApi] = useState([]);
  const {error, loading, retry, retrying} = useAsync(fetchApi, [clientNr, explorerId, apiName]);
  const [explorer, setExplorer] = useState([]);
  const [requestBody, setRequestBody] = useState({});
  const { isADarkTheme } = useTheme();
  const readOnly = !authorization.designer && !authorization.owner;

  useEffect(() => {
    return () => onFinishExecution();
  }, []);

  useEffect(() => {
    fetchApi();
  }, [clientNr, explorerId, apiName]);

  function removeProperty(propertyName, object) {
    // create a new object to store the result
    let result = {};
    // loop through the keys of the original object
    for (let key in object) {
      // if the key is not equal to the property name to remove
      if (key !== propertyName) {
        // copy the key-value pair to the result object
        result[key] = object[key];
      }
    }
    // return the result object
    return result;
  }

  const commands = {
    cd: (directory) => (
      getTranslatedPlainText("pages.home.apiTerminal.terminalMessages.changedPathInfo", { directory })
    ),
    run: async () => {
      try {
        const myResponse = await handleSubmit();

        let jsonResponse;
        if (typeof myResponse === "string") {
          jsonResponse = { data: myResponse };
        } else {
          jsonResponse = myResponse;
        }
        return (
          <ReactJson src={jsonResponse} theme="apathy" name={null} collapsed={1} />
        );
      } catch (error) {
        console.error("Error during API execution:", error);
        return (
          <div className="terminal__message terminal__message--error">
            {getTranslatedPlainText("pages.home.apiTerminal.terminalMessages.apiExecutionError")}
          </div>
        );
      }
    },
  };

  const handleSave = async () => {
    const mytaskid = OnGetTask();

    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const endpoint = `${process.env.REACT_APP_CENTRAL_BACK}/api/registercustom`;

      const myCustomPayload = {
        ...api,
        taskId: mytaskid,
        userClientNr: user.clientNr,
        urlRoute: route,
        requestBody,
        clientNr,
        explorerId,
      };

      const registerCustomPayload = removeProperty("_id", myCustomPayload);

      await axios.post(endpoint, encodebody(registerCustomPayload));
      alert(getTranslatedPlainText("pages.home.apiTerminal.alerts.apiUpdateSuccess"));
    } catch (err) {
      const error = err.response ? JSON.stringify(getDecodedBody(err.response.data)) : err.message;
      alert(getTranslatedPlainText("pages.home.apiTerminal.alerts.apiUpdateError", { error }));
    }
  };

  const handleRestoreDefault = async () => {
    const mytaskid = OnGetTask();

    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const endpoint = `${process.env.REACT_APP_CENTRAL_BACK}/api/deletecustom`;

      const query = {
        userClientNr: user.clientNr,
        explorerId: explorerId,
        taskId: mytaskid,
        name: apiName
      };

      await axios.post(endpoint, encodebody(query));
      alert(getTranslatedPlainText("pages.home.apiTerminal.alerts.apiRestoreSuccess"));
    } catch (error) {
      alert((error.response ? JSON.stringify(getDecodedBody(error.response.data)) : error.message));
    }
  };

  const handleRequestBodyChange = (value, event) => {
    let parsedValue;

    // Check if the value is an empty string and set it to an empty object
    if (value === "") {
      parsedValue = {};
    } else {
      try {
        // Attempt to parse the value as JSON
        parsedValue = JSON.parse(value);
      } catch (error) {
        console.error("Invalid JSON:", error);
        // If the value is not valid JSON, you might want to handle this case,
        // e.g., by not calling setApiData, showing an error message, etc.
        return; // Exit the function if the JSON is invalid
      }
    }
    // Update the state with the parsed value, which is now guaranteed to be an object
    setRequestBody(parsedValue);
  };

  const handleRouteChange = (value) => setRoute(value);

  async function fetchApi() {
    var user = JSON.parse(localStorage.getItem("user"));
    var mytaskid = OnGetTask();

    try {
      const myApibody = {
        clientNr: clientNr,
        explorerId: explorerId,
        taskId: mytaskid,
        userClientNr: user.clientNr,
        name: apiName,
        custom: true
      };
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/api/query", encodebody(myApibody));
      var myApi = getDecodedBody(response.data);

      const myLinkParamPayload = {
        taskId: mytaskid,
        chatbotKey: user.chatbotKey,
        email: user.email,
        baseUrl: myApi.resourcePath ? myApi.resourcePath : "",
        clientNr,
        explorerId,
        workflowName,
      };

      var activeLinks = false;
      try {
        const myLinkParamResponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/link/querylinkparameters", encodebody(myLinkParamPayload));
        var myParams = getDecodedBody(myLinkParamResponse.data);

        if (myParams.activeLinks) {
          setRoute(myParams.path);
          activeLinks = true;
        } else {
          setRoute(myApi.urlRoute);
          activeLinks = false;
        }
      } catch(error) {
        setRoute(myApi.urlRoute);
        activeLinks = false;
      }

      const myExplorerbody = { clientNr, explorerId };

      const Eresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/explorer/query", encodebody(myExplorerbody));
      const myExplorer = getDecodedBody(Eresponse.data);
      setExplorer(myExplorer);

      var myRequestBodyWithGlobals = {};
      if (myApi.requestBody) {
        const yamlObject = await getConfiguration(myExplorer, myApi.thirdparty);
        var initialRequestBody;
        if (activeLinks) {
          initialRequestBody = myParams.requestBody ;
        } else {
          initialRequestBody = myApi.requestBody ;
        }
        myRequestBodyWithGlobals = requestBodyGlobalAdd(initialRequestBody, yamlObject);
        myApi.requestBody = myRequestBodyWithGlobals;
        setRequestBody(myRequestBodyWithGlobals);
      }
      setApi(myApi);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    try {
      setResponse("");

      if (!isValidConfiguration(explorer)) {
        return alert(getTranslatedPlainText("pages.home.apiTerminal.alerts.invalidYamlError"));
      }

      const yamlObject = await getConfiguration(explorer, api.thirdparty);
      const apiHeaders = parseApiHeaders(api);
      const myheadersWithGlobals = HeadersGlobalAdd(apiHeaders, yamlObject);
      const finalHeaders = addAuthToHeaders(myheadersWithGlobals, yamlObject);
      const finalRequestBody = addAuthToRequestBody(requestBody, yamlObject, crypto);

      finalHeaders.destination = route;

      const allowedMethodsForBody = ["POST", "PUT", "PATCH"];
      const fetchOptions = {
        method: api.method,
        headers: {
          ...finalHeaders,
        },
      };

      if (allowedMethodsForBody.includes(api.method.toUpperCase())) {
        fetchOptions.body = JSON.stringify(finalRequestBody);
      }

      const fetchResponse = await fetch(process.env.REACT_APP_CENTRAL_BACK + "/relay", fetchOptions);

      const responseData = await fetchResponse.json();
      setResponse(JSON.stringify(responseData, null, 2));

      const resultWithStatus = {
        status: fetchResponse.status,
        resultBody: responseData
      };

      const user = JSON.parse(localStorage.getItem("user"));
      const endpoint = `${process.env.REACT_APP_CENTRAL_BACK}/api/registerapiresult`;

      const myresultPayload = {
        result: { ...resultWithStatus },
        clientNr: clientNr,
        explorerId: explorerId,
        name: apiName,
        email: user.email,
        chatbotKey: user.chatbotKey,
      };

      try {
        await axios.post(endpoint, encodebody(myresultPayload));
      } catch (error) {
        console.log("An error occured when saving result", error);
      }

      return resultWithStatus;

    } catch (error) {
      console.error("Error during API execution:", error);
      setResponse(JSON.stringify(error, null, 2));
      throw error;
    }
  };

  return (
    error || retrying
    ? (<FetchErrorView
        className="section-right__content"
        retry={retry}
        retrying={retrying}
      />
      ) : (
    <>
      <header className="section-right__toolbar-container">
        <Toolbar>
          {!readOnly && (
            <>
              <Toolbar.Button onClick={handleSave}>
                <TranslationLoader
                  type="button"
                  translationKey="pages.home.apiTerminal.toolbar.buttons.save"
                  fallbackText="Save"
                />
              </Toolbar.Button>
              <Toolbar.Button onClick={handleRestoreDefault}>
                <TranslationLoader
                  type="button"
                  translationKey="pages.home.apiTerminal.toolbar.buttons.restore"
                  fallbackText="Restore Default"
                />
              </Toolbar.Button>
            </>
          )}
          <Toolbar.Item>
            <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/curl-execution">
              <HelpCenterIcon />
            </Icon>
          </Toolbar.Item>
        </Toolbar>
      </header>
      <div className="section-right__content">
      {loading
          ? <Skeleton className="api-terminal__curl-form" />
          : <Form id="form" className="api-terminal__curl-form" onSubmit={handleSubmit}>
            <Form.Row>
            <Form.Label>
              <pre>1  // {getTranslatedPlainText("pages.home.apiTerminal.curl.heading")}</pre>
              <pre>2  //</pre>
              <pre>3  // {getTranslatedPlainText("pages.home.apiTerminal.curl.taskName", { taskName })}</pre>
              <pre>3  // {getTranslatedPlainText("pages.home.apiTerminal.curl.apiName", { apiName: api.name })}</pre>
              <pre>4  //</pre>
              <pre>5  curl -X {api.method}</pre>
            </Form.Label>
          </Form.Row>
          <Form.Row>
            <Textarea
              title="cURL"
              name="route"
              value={route}
              onChange={(e) => handleRouteChange(e.target.value)}
              readOnly={readOnly}
            />
          </Form.Row>
          <Form.Row>
            {api.headers && api.headers.map((header) => `-H "${header}"`).join(" ")} -d {" "}
          </Form.Row>
          <Form.Row>
            <CodeEditor
              id="json-editor"
              defaultLanguage="json"
              value={api.requestBody}
              height="300px"
              onChange={handleRequestBodyChange}
              darkMode={isADarkTheme}
              readOnly={readOnly}
            />
          </Form.Row>
        </Form>
        }
        <div className="section-right__terminal-container section-right__terminal-container--api-terminal">
          <Terminal
            loading={loading}
            commands={commands}
            welcomeMessage={
              <div>
                {getTranslatedPlainText("pages.home.apiTerminal.terminalMessages.welcomeToTerminalInfo")}
                <br />
                {getTranslatedPlainText("pages.home.apiTerminal.terminalMessages.runPrompt")}
                <br />
              </div>
            }
          />
        </div>
      </div>
      </>
    )
  );
};