import { useRef, useState } from "react";
import styled from "styled-components";
import htmlToMd from "html-to-md";
import { renderToString } from "react-dom/server";
import ReactMarkdown from "react-markdown";
import ReactQuill from "react-quill";
import Skeleton from "./Skeleton";
import { Button } from "./Button";
import "react-quill/dist/quill.snow.css";

const StyledTextEditorContainer = styled.div`
  background-color: var(--input-bg-clr);
  border-radius: 0.8rem;
  border: 2px solid var(--text-editor-border-clr);
  caret-color: var(--input-txt-clr);
  font-size: inherit;

  /* Makes sure both editors have the same height */
  height: ${({ height }) => height};
  display: grid;
  grid-template-rows: 1fr auto;

  transition:
    border-color var(--transition-duration-fast, 250ms) ease-in-out,
    outline var(--transition-duration-fast, 250ms) ease-in-out;

  &:hover {
    border-color: var(--text-editor-border-clr-hover);
  }

  &.focused {
    border-color: var(--text-editor-border-clr-hover);
    outline: 2px solid var(--text-editor-border-clr-hover);
  }

  &.readOnly {
    border-color: var(--text-editor-border-clr);
    outline: none;
  }

  &.readOnly .ql-toolbar.ql-snow {
    display: none;
  }

  & .rich-text-editor-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  & .rich-text-editor-container .ql-editor {
    line-height: 1.5;
    flex: 1;
    flex-shrink: 0;
  }

  & .text-editor--markdown {
    background-color: transparent;
    border: none;
    padding: 1.45rem 1.5rem;
    width: 100%;
    min-height: 100%;
    height: 100%;
    resize: none;
    color: var(--text-editor-txt-clr);
  }

  & .text-editor--markdown:focus {
    outline: none;
  }

  & .text-editor__toggle-button-section {
    line-height: 1;
    padding: 0.4rem;
  }

  & .text-editor__section-separator {
    border: none;
    border-top: 1px solid var(--text-editor-border-clr);
    margin: 0.4rem 0;
  }

  & .text-editor__toggle-button {
    line-height: 1;
  }
`;

const StyledRichTextEditorContainer = styled.div`
  overflow-y: auto;

  & .quill {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    height: 100%;
    font-size: inherit;
  }

  & .quill .ql-container.ql-snow {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    font-size: inherit;
  }

  /* Toolbar */
  & .ql-toolbar.ql-snow {
    border: none !important;
    border-bottom: 1px solid var(--text-editor-border-clr) !important;
  }

  /* Textarea container */
  & .ql-container.ql-snow {
    border: none !important;
  }

  /* Toolbar buttons */
  & .ql-snow.ql-toolbar .ql-picker-label,
  & .ql-snow.ql-toolbar button,
  & .ql-snow.ql-toolbar button {
    background-color: var(--text-editor-button-bg-clr);
    color: var(--text-editor-button-txt-clr);
    border-radius: 0.4rem;
    transition:
      background-color var(--transition-duration-normal, 250ms) ease-in-out,
      color var(--transition-duration-normal, 250ms) ease-in-out;
  }

  /* Toolbar buttons on hover */
  & .ql-snow.ql-toolbar .ql-picker-label:hover,
  & .ql-snow.ql-toolbar button:hover,
  & .ql-snow.ql-toolbar button.ql-active {
    background-color: var(--text-editor-button-bg-clr-hover);
    color: var(--text-editor-button-txt-clr-hover);
  }

  /* Toolbar icons */
  & .ql-snow.ql-toolbar .ql-stroke,
  & .ql-snow.ql-toolbar .ql-stroke {
    stroke: var(--text-editor-button-txt-clr);
    transition: stroke var(--transition-duration-normal, 250ms) ease-in-out;
  }

  & .ql-snow.ql-toolbar .ql-fill,
  & .ql-snow.ql-toolbar .ql-fill {
    fill: var(--text-editor-button-txt-clr);
    transition: fill var(--transition-duration-normal, 250ms) ease-in-out;
  }

  /* Toolbar icons on hover */
  & .ql-snow.ql-toolbar button:hover .ql-stroke,
  & .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  & .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  & .ql-snow.ql-toolbar .ql-picker.ql-expanded .ql-stroke,
  & .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  & .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    stroke: var(--text-editor-button-txt-clr-hover);
  }

  & .ql-snow.ql-toolbar button:hover .ql-fill,
  & .ql-snow.ql-toolbar button.ql-active .ql-fill {
    fill: var(--text-editor-button-txt-clr-hover);
  }

  /* Text styles menu when expanded */
  & .ql-snow.ql-toolbar .ql-picker-options {
    background-color: var(--text-editor-menu-bg-clr);
    color: var(--text-editor-menu-txt-clr);
  }

  & .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border: 1px solid var(--text-editor-border-clr);
  }

  /* Text styles menu items on hover */
  & .ql-snow.ql-toolbar .ql-picker-item:hover,
  & .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  & .ql-snow.ql-toolbar .ql-picker-label.ql-active {
    color: var(--text-editor-menu-txt-clr-hover);
    background-color: var(--text-editor-menu-bg-clr-hover);
  }

  /* Text styles menu handler when expanded */
  & .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border: 1px solid var(--text-editor-border-clr);
    color: var(--text-editor-button-txt-clr-hover);
  }

  & .ql-editor {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    color: var(--text-editor-txt-clr);
  }

  & .ql-snow a {
    display: inline;
    color: var(--text-editor-button-txt-clr);
  }

  & .ql-snow .ql-tooltip.ql-editing,
  & .ql-snow .ql-tooltip {
    background-color: var(--tooltip-bg-clr);
    border: 1px solid var(--text-editor-border-clr);
    color: var(--tooltip-txt-clr);
  }

  & .ql-snow .ql-tooltip.ql-editing input {
    background-color: var(--input-bg-clr);
    color: var(--input-txt-clr);
    border: 1px solid var(--input-border-clr);
    margin-right: 8px;
    display: block;
    float: left;
  }

  & .ql-snow .ql-tooltip.ql-editing .ql-action {
    display: inline-block;
  }

  & .ql-snow .ql-tooltip.ql-editing .ql-action,
  & .ql-snow .ql-tooltip a.ql-action {
    background-color: var(--btn-primary-bg-clr);
    color: var(--btn-primary-txt-clr);
  }

  & .ql-snow .ql-tooltip.ql-editing .ql-action:hover,
  & .ql-snow .ql-tooltip a.ql-action:hover {
    background-color: var(--btn-primary-bg-clr-hover);
    color: var(--btn-primary-txt-clr-hover);
  }

  & .ql-snow .ql-tooltip a.ql-remove {
    background-color: var(--btn-danger-bg-clr);
    color: var(--btn-danger-txt-clr);
    margin-left: 4px;
    float: left;
  }

  & .ql-snow .ql-tooltip a.ql-remove:hover {
    background-color: var(--btn-danger-bg-clr-hover);
    color: var(--btn-danger-txt-clr-hover);
  }

  & .ql-snow .ql-tooltip .ql-preview {
    text-decoration: underline;
  }

  & .ql-snow .ql-tooltip.ql-editing .ql-action,
  & .ql-snow .ql-tooltip a.ql-action,
  & .ql-snow .ql-tooltip a.ql-remove {
    border-radius: 4px;
    padding: 0 1em;
    transition: background-color var(--transition-duration-normal, 250ms) ease-in-out;
  }

  & .ql-snow .ql-tooltip::before,
  & .ql-snow .ql-tooltip .ql-preview,
  & .ql-snow .ql-tooltip a.ql-action,
  & .ql-snow .ql-tooltip.ql-flip a.ql-remove {
    display: block;
    float: left;
  }

  & .ql-snow .ql-tooltip a.ql-action {
    clear: both;
  }

  & .ql-snow .ql-tooltip.ql-editing .ql-action::after,
  & .ql-snow .ql-tooltip a.ql-action::after,
  & .ql-snow .ql-tooltip a.ql-action::after,
  & .ql-snow .ql-tooltip a.ql-remove::before {
    margin: 0;
    border-right: none;
    padding: 0;
  }

  & .ql-snow .ql-tooltip.ql-editing a.ql-remove {
    display: none;
  }

  & .ql-snow .ql-editor blockquote {
    border-left-color: var(--text-editor-border-clr);
  }

  & .ql-snow .ql-editor code,
  & .ql-snow .ql-editor pre {
    background-color: var(--text-editor-code-bg-clr);
  }
`;

function isHTML(str) {
  const doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

/**
 * GWOCU's text editor component.
 *
 * Can toggle between rich text and markdown modes.
 * It manages the internal state for mode switching and handles content as stringified HTML. Although it also accepts plain text as an initial value.
 *
 * The editor will take up all available width and keep its initial height, regardless of its content.
 *
 * @component
 * @param {Object} props - The props for the text editor component.
 * @param {number} [props.height="500px"] - The fixed height of the editor. Overflowing content will scroll. Default value: `500px`.
 * @param {boolean} [props.loading=false] - If `true`, shows a loading skeleton animation as a placeholder. Default value: `false`.
 * @param {function} [props.onChange=undefined] - The `change` event handler. Default value: `undefined`.
 * @param {boolean} [props.readOnly=false] - If `true`, the editor is read-only. Default value: `false`.
 * @param {string} [props.value=""] - The initial value of the editor, can be plain text or stringified HTML. Default value: `""`.
 * @param {string} [props.useMarkdownModeLabel="Use Markdown Editor"] - Label for switching to Markdown editor mode, customizable for translations. Default value: `"Use Markdown Editor"`.
 * @param {string} [props.useRichTextModeLabel="Use Rich Text Editor"] - Label for switching to Markdown editor mode, customizable for translations. Default value: `"Use Rich Text Editor"`.
 * @returns {JSX.Element} The rendered text editor component.
 *
 * @example
 * <TextEditor value={stringifiedHtml} onChange={eventHandler} />
 */
export const TextEditor = ({
  height = "500px",
  loading = false,
  onChange,
  readOnly = false,
  value = "",
  useMarkdownModeLabel = "Use Markdown Editor" ,
  useRichTextModeLabel = "Use Rich Text Editor"
}) => {
  const [isRichTextMode, setIsRichTextMode] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const richTextEditorRef = useRef(null);

  const toggleTextEditorMode = () => setIsRichTextMode((prevMode) => !prevMode);
  const handleDescriptionChange = (value) => onChange(value);
  const handleTextareaFocus = () => !isRichTextMode && setIsFocused(true);
  const handleTextareaBlur = () => !isRichTextMode && setIsFocused(false);
  const handleRichTextEditorFocus = () => isRichTextMode && setIsFocused(true);
  const handleRichTextEditorBlur = () => isRichTextMode && setIsFocused(false);

  const classes = `text-editor-container ${isFocused ? "focused" : ""} ${readOnly ? "readOnly" : ""}`;

  return loading ? (
    <Skeleton height={height} />
  ) : (
    <StyledTextEditorContainer height={height} className={classes}>
      {isRichTextMode ? (
        <StyledRichTextEditorContainer
          className="rich-text-editor-container"
          ref={richTextEditorRef}
        >
          <ReactQuill
            value={isHTML(value) ? value : `<p>${value}</p>`} // Convert plain text to simple HTML
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, false] }],
                ["bold", "italic", "underline", "strike", "blockquote", "code"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["code-block", "link"],
                ["clean"],
              ],
            }}
            bounds=".rich-text-editor-container"
            theme="snow"
            onChange={handleDescriptionChange}
            onFocus={handleRichTextEditorFocus}
            onBlur={handleRichTextEditorBlur}
            readOnly={readOnly}
          />
        </StyledRichTextEditorContainer>
      ) : (
        <MarkdownEditor
          htmlValue={value}
          className="text-editor--markdown"
          onChange={handleDescriptionChange}
          onFocus={handleTextareaFocus}
          onBlur={handleTextareaBlur}
          readOnly={readOnly}
        />
      )}
      <div className="text-editor__toggle-button-section">
        <hr className="text-editor__section-separator" />
        <Button
          className="text-editor__toggle-button"
          color="secondary"
          onClick={toggleTextEditorMode}
        >
          {isRichTextMode ? useMarkdownModeLabel : useRichTextModeLabel}
        </Button>
      </div>
    </StyledTextEditorContainer>
  );
};

const MarkdownEditor = ({
  htmlValue,
  className,
  onChange,
  onFocus,
  onBlur,
  readOnly,
}) => {
  const [markdownValue, setMarkdownValue] = useState(htmlToMd(htmlValue));

  const handleTextareaChange = (e) => {
    const markdownContent = e.target.value;
    const htmlContent = <ReactMarkdown>{markdownContent}</ReactMarkdown>;
    const htmlString = renderToString(htmlContent);
    onChange?.(htmlString); // Updates parent state with the converted HTML.
    setMarkdownValue(markdownContent);
  };

  return (
    <textarea
      value={markdownValue}
      className={className}
      onChange={handleTextareaChange}
      onFocus={onFocus}
      onBlur={onBlur}
      readOnly={readOnly}
    />
  );
};