// Override console.warn to suppress all warnings

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { ThemeProvider } from "./context/ThemeContext";
import "./languages/i18next.js";
import "./themes";
import "./index.css";

ReactDOM.render(
  <AuthContextProvider>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </AuthContextProvider>,
  document.getElementById("root")
);