import { useState, useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { Button, Card, Form, InputText, InputPassword, LayoutTextAndContent, Spinner, Tooltip, TranslationLoader } from "../../UI";
import { loginCall } from "../../apiCalls";
import { getDecodedBody } from "../../utils/utils.js";
import "./login.css";

export default function Login() {
  const {
    getTranslatedPlaceholder,
    getTranslatedTooltipConfig,
    getTranslatedPlainText
  } = useCustomTranslation();
  const location = useLocation();  // Hook to get the location object
  const params = new URLSearchParams(location.search);  // Instantiate with the current query string

  const clientNrFromQuery = params.get("clientNr");
  const chatbotKeyFromQuery = params.get("chatbotKey");

  const [clientNr, setClientNr] = useState(clientNrFromQuery || "");
  const [chatbotKey, setChatbotKey] = useState(chatbotKeyFromQuery || "");
  const [email, setEmail] = useState("");
  const [gwokenToken, setGwokenToken] = useState(process.env.REACT_APP_GWOKUTOKEN || "");
  const [explorers, setExplorers] = useState([]);
  const [explorerSelect, setExplorerSelect] = useState("");
  const [password, setPassword] = useState("");
  const { isFetching, dispatch } = useContext(AuthContext);

  const redirectTo = location.state?.from || "/";
  const history = useHistory();

  useEffect(() => {
    const fetchExplorerData = async () => {
      // Check if all required fields have values before making the API call
      if (clientNr.trim() === "" || email.trim() === "") return;

      const body = {clientNr, email};
      try {
        const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/users/explorers", body);
        // Assuming the API response has an array of explorers in the "data" property
        const myExplorers = getDecodedBody(response.data)
        setExplorers(myExplorers);
        if (myExplorers.length > 0) {
          setExplorerSelect(myExplorers[0].id);
        } else {
          setExplorerSelect("");
        }
      } catch (error) {
        setExplorers([]);
      }
    };

    fetchExplorerData();
  }, [clientNr, chatbotKey, email, gwokenToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await loginCall({
          explorer: explorerSelect,
          clientNr,
          email,
          password,
        },
        dispatch
      );

      history.replace(redirectTo);
    } catch (error) {
      alert(getTranslatedPlainText("pages.login.alerts.loginError"));
    }
  };

  return (
    <LayoutTextAndContent>
      <LayoutTextAndContent.Cols>
        <LayoutTextAndContent.Col>
          <LayoutTextAndContent.Heading>
            GWOCU Studio
          </LayoutTextAndContent.Heading>
          <LayoutTextAndContent.Description>
            <TranslationLoader
              translationKey="pages.login.description"
              fallbackText="Part of the GWOCU suite of products."
            />
          </LayoutTextAndContent.Description>
        </LayoutTextAndContent.Col>
        <LayoutTextAndContent.Col>
          <Card className="login-form-container" shadow={true}>
            <Form onSubmit={handleSubmit}>
              <Form.Col>
                <Tooltip tooltip={getTranslatedTooltipConfig("pages.login.inputs.client")} placement="left">
                  <InputText
                    placeholder={getTranslatedPlaceholder("pages.login.inputs.client")}
                    value={clientNr}
                    onChange={(e) => setClientNr(e.target.value)}
                    required
                  />
                </Tooltip>
                <Tooltip tooltip={getTranslatedTooltipConfig("pages.login.inputs.email")} placement="left">
                  <InputText
                    placeholder={getTranslatedPlaceholder("pages.login.inputs.email")}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Tooltip>
                <Tooltip tooltip={getTranslatedTooltipConfig("pages.login.inputs.password")} placement="left">
                  <InputPassword
                    placeholder={getTranslatedPlaceholder("pages.login.inputs.password")}
                    minLength="6"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Tooltip>
              </Form.Col>
              <Form.Col>
                <Button type="submit" disabled={isFetching}>
                  {isFetching ? (
                      <>
                        <Spinner thickness={7} />
                        {getTranslatedPlainText("pages.login.buttons.login.loading")}
                      </>
                    ) : (
                      <TranslationLoader
                        type="button"
                        translationKey="pages.login.buttons.login"
                        fallbackText="Sign In"
                      />
                  )}
                </Button>
              </Form.Col>
            </Form>
          </Card>
        </LayoutTextAndContent.Col>
      </LayoutTextAndContent.Cols>
    </LayoutTextAndContent>
  );
}
