import { ReactTerminal } from "react-terminal";
import styled from "styled-components";
import Skeleton from "./Skeleton";

const StyledTerminalContainer = styled.div`
  height: 100%;

  & .terminal {
    border-top: 1px solid var(--app-border-clr);
  }

  & .terminal,
  #terminalEditor {
    background: var(--terminal-bg-clr) !important;
    color: var(--terminal-txt-clr-info);
  }

  /* Terminal prompt */
  & #terminalEditor span[style="color: rgb(169, 23, 168);"] {
    color: var(--terminal-prompt-clr) !important;
  }

  /* Terminal caret */
  & span[class^="index_caretAfter__"] {
    background: var(--terminal-caret-clr) !important;
  }
`;

/**
 * GWOCU's terminal component.
 *
 * This component renders an interactive terminal with customizable commands.
 * It takes up 100% of the height of its container.
 *
 * @component
 * @param {Object} props - The props for the terminal component.
 * @param {Object} [props.commands=undefined] - An object mapping command names to their corresponding functions. These commands are executed when the user inputs them in the terminal. Default value: `undefined`.
 * @property {boolean} [props.loading=false] - If `true`, shows a loading skeleton animation as a placeholder. Default value: `false`.
 * @param {boolean} [props.showControlBar=false] - If `true`, a control bar is shown at the top of the terminal. Default value: `false`.
 * @param {string | React.ReactNode} [props.prompt=">>"] - The prompt symbol, text or component displayed before the user's input. Default value: `">>"`.
 * @param {string | React.ReactNode} [props.welcomeMessage=""] - The welcome message displayed when the terminal loads. This can be a string, a React component, or any valid JSX. Default value: "";
 * @returns {JSX.Element} The rendered terminal component.
 *
 * @example
 * <Terminal
 *   commands={{
 *     help: () => console.log("Help is on your way!")
 *   }}
 *   welcomeMessage={
 *     <div>
 *       <h1>Welcome to the terminal!</h1>
 *       <p>Type 'help' to get started.</p>
 *     </div>
 *   }
 * />
 */
export const Terminal = ({
  commands,
  loading = false,
  showControlBar = false,
  prompt = ">>",
  welcomeMessage,
}) => {
  return (
    loading
    ? <Skeleton height="100%" />
    : <StyledTerminalContainer>
        <ReactTerminal
          commands={commands}
          showControlBar={showControlBar}
          prompt={prompt}
          welcomeMessage={welcomeMessage}
        />
      </StyledTerminalContainer>
  );
};