import {Skeleton} from "../../../UI";
import "./TreeViewSkeleton.css"

export default function TreeViewSkeleton() {
  return (
    <div className="skeletons">
      <div className="skeletons__toolbar">
        <Skeleton height="2.64rem" width="10.4rem" />
        <Skeleton height="2.64rem" width="7.76rem" />
        <Skeleton height="2.64rem" width="8.64rem" />
        <Skeleton height="2.64rem" width="11.28rem" />
        <Skeleton height="2.64rem" width="11.28rem" />
        <Skeleton height="2.64rem" width="11.28rem" />
      </div>
      <div className="skeletons__tree">
        <Skeleton height="3.4rem" />
        <Skeleton height="3.4rem" />
      </div>
    </div>
  )
}
