import { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import PasswordValidator from "password-validator";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import { Button, Form, InputPassword, Modal, TranslationLoader } from "../../UI/index.js";
import { encodebody, getDecodedBody } from "../../utils/utils.js";

export default function AcceptPrivateInvitation() {
  const schema = new PasswordValidator();
  schema
    .is().min(8)                                    // Minimum length 8
    .is().max(100)                                  // Maximum length 100
    .has().uppercase()                              // Must have uppercase letters
    .has().lowercase()                              // Must have lowercase letters
    .has().digits(2)                                // Must have at least 2 digits
    .has().symbols()                                // Must have symbols
    .has().not().spaces();

  const { getTranslatedPlainText } = useCustomTranslation();
  const [password, setPassword] = useState("");
  const [client, setClient] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");

  function handleOnClose() {
    // Attempt to close the current window
    window.close();
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return alert(getTranslatedPlainText("pages.acceptPrivateInvitation.alerts.passwordMismatchError"));
    }

    const result = schema.validate(password, { list: true });
    if (result.length !== 0) {
      // Lists aspects of the password that failed
      return alert(getTranslatedPlainText("pages.acceptPrivateInvitation.alerts.invalidPasswordError"));
    }

    // verify token and return JSON
    try {
      const tokenresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/invitation/verifytoken", { token });
      var myuserData = tokenresponse.data
      setClient(myuserData.clientNr);
    } catch(error) {
      return alert(getTranslatedPlainText("pages.acceptPrivateInvitation.alerts.invalidTokenError"));
    }

    // Send data to your API endpoint to create the user
    try {
      const userPayload = {
        clientNr: myuserData.clientNr,
        email: myuserData.toEmail,
        username: myuserData.toEmail,
        password: password,
        chatbotKey: myuserData.chatbotKey,
        groups: ["chatbotDesigners"],
        explorers: myuserData.explorers,
        isAdmin: false
      }

      await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/auth/register", encodebody(userPayload));
      // Redirect to login page after successful registration
      alert(getTranslatedPlainText("pages.acceptPrivateInvitation.alerts.registrationSuccess"));
      // delete invite
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/invitation/delete", encodebody({ chatbotKey: myuserData.chatbotKey, email:  myuserData.toEmail}));

      history.push(`/login?clientNr=${encodeURIComponent(myuserData.clientNr)}&chatbotKey=${encodeURIComponent(myuserData.chatbotKey)}&email=${encodeURIComponent(myuserData.email)}`);
      // history.push("/login");
    } catch (err) {
      if (err.response) {
        // The request was made and the server responded with a status code that is not in the range of 2xx
        return alert(getTranslatedPlainText("pages.acceptPrivateInvitation.alerts.registrationError", { error: getDecodedBody(err.response.data) }));
      } else if (err.request) {
        // The request was made but no response was received
        return alert(getTranslatedPlainText("pages.acceptPrivateInvitation.alerts.registrationNoResponseError"));
      } else {
        // Something happened in setting up the request that triggered an Error
        return alert(getTranslatedPlainText("pages.acceptPrivateInvitation.alerts.registrationError", { error: err.message }));
      }
    }
  };

  return (
    <Modal open={true} onClose={handleOnClose} maxWidth="40rem" showCloseButton={false}>
      <Modal.Title>
        <TranslationLoader
          translationKey="pages.acceptPrivateInvitation.heading"
          fallbackText={`Welcome to the ${client} GWOCU Studio workspace`}
          interpolationObject={{ client }}
        />
      </Modal.Title>
      <Modal.Body>
        <Form>
          <Form.Row>
            <p>
              <TranslationLoader
                translationKey="pages.acceptPrivateInvitation.description"
                fallbackText="Please set your password."
              />
            </p>
          </Form.Row>
          <Form.Control>
            <Form.Label htmlFor="password">
              <TranslationLoader
                type="label"
                translationKey="pages.acceptPrivateInvitation.inputs.password"
                fallbackText="Password"
              />
            </Form.Label>
            <InputPassword
              id="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="confirmpassword">
              <TranslationLoader
                type="label"
                translationKey="pages.acceptPrivateInvitation.inputs.confirmPassword"
                fallbackText="Confirm password"
              />
            </Form.Label>
            <InputPassword
              type="password"
              id="confirmpassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </Form.Control>
          <Form.Row>
            <Button color="secondary" onClick={handleOnClose}>
              <TranslationLoader
                type="button"
                translationKey="pages.acceptPrivateInvitation.buttons.cancel"
                fallbackText="Cancel"
              />
            </Button>
            <Button type="submit" onClick={handleSubmit}>
              <TranslationLoader
                type="button"
                translationKey="pages.acceptPrivateInvitation.buttons.submit"
                fallbackText="Submit"
              />
            </Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
