import { useRef, useContext } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext.js";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import Topbar from "../../components/topbar/Topbar.jsx";
import { Button, Card, Form, InputPassword, InputText, LayoutTextAndContent, TranslationLoader } from "../../UI/index.js";
import { encodebody,getDecodedBody } from "../../utils/utils.js";
import "./changePassword.css";

export default function ChangePassword() {
  const { getTranslatedPlaceholder, getTranslatedPlainText } = useCustomTranslation();
  const { user: currentUser } = useContext(AuthContext);
  const isUnregisteredInvitee = currentUser.email === "public@gwocu.com";
  const clientNr = currentUser.clientNr;

  const chatbotKey = useRef();
  const username = useRef();
  const email = useRef();
  const password = useRef();
  const passwordAgain = useRef();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwordAgain.current.value !== password.current.value) {
      alert(getTranslatedPlainText("pages.changePassword.alerts.passwordMismatchError"));
    } else {
      const user = {
        clientNr: clientNr,
        chatbotKey: chatbotKey.current.value,
        username: username.current.value,
        email: email.current.value,
        password: password.current.value,
      };
      const body = encodebody(user);
      try {
        await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/users/update", encodebody(body));
        alert(getTranslatedPlainText("pages.changePassword.alerts.passwordUpdateSuccess"));
        history.push("/");
      } catch (err) {
        const error = err.response ? JSON.stringify(getDecodedBody(err.response.data)) : err.message;
        alert(getTranslatedPlainText("pages.changePassword.alerts.passwordUpdateError", { error }));
      }
    }
  };

  return (
    <LayoutTextAndContent>
      <LayoutTextAndContent.NavbarSlot>
        <Topbar />
      </LayoutTextAndContent.NavbarSlot>
      <LayoutTextAndContent.Cols>
        <LayoutTextAndContent.Col>
          <LayoutTextAndContent.Heading>
            <TranslationLoader
              type="text"
              translationKey="pages.changePassword.heading"
              fallbackText="GWOCU Studio Chat"
            />
          </LayoutTextAndContent.Heading>
          <LayoutTextAndContent.Description>
            <TranslationLoader
              type="text"
              translationKey="pages.changePassword.description"
              fallbackText="Change your password regularly for more security."
            />
          </LayoutTextAndContent.Description>
        </LayoutTextAndContent.Col>
        <LayoutTextAndContent.Col>
          <Card className="update-user-form-container" shadow={true}>
            <Form onSubmit={handleSubmit}>
              <Form.Col>
                <InputText
                  ref={chatbotKey}
                  placeholder={getTranslatedPlaceholder("pages.changePassword.inputs.chatbotKey")}
                  value={currentUser.chatbotKey}
                  className="updateuserInput"
                  readOnly
                />
                <InputText
                  ref={username}
                  value={currentUser.username}
                  placeholder={getTranslatedPlaceholder("pages.changePassword.inputs.username")}
                  readOnly
                />
                <InputText
                  ref={email}
                  type="email"
                  placeholder={getTranslatedPlaceholder("pages.changePassword.inputs.email")}
                  value={currentUser.email}
                  readOnly
                />
                <InputPassword
                  ref={password}
                  placeholder={getTranslatedPlaceholder("pages.changePassword.inputs.newPassword")}
                  autoComplete="new-password"
                  minLength="8"
                  maxLength="100"
                  required
                  disabled={isUnregisteredInvitee}
                />
                <InputPassword
                  ref={passwordAgain}
                  placeholder={getTranslatedPlaceholder("pages.changePassword.inputs.newPasswordAgain")}
                  minLength="8"
                  maxLength="100"
                  required
                  disabled={isUnregisteredInvitee}
                />
              </Form.Col>
              <Form.Col>
                <Button type="submit" disabled={isUnregisteredInvitee}>
                  <TranslationLoader
                    translationKey="pages.changePassword.buttons.changePassword"
                    type="button"
                    fallbackText="Change Password"
                  />
                </Button>
                <Button color="secondary" onClick={() => history.push("/")}>
                  <TranslationLoader
                    translationKey="pages.changePassword.buttons.cancel"
                    type="button"
                    fallbackText="Cancel"
                  />
                </Button>
              </Form.Col>
            </Form>
          </Card>
        </LayoutTextAndContent.Col>
      </LayoutTextAndContent.Cols>
    </LayoutTextAndContent>
  );
}
