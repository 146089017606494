import { useState, useEffect } from 'react';
import axios from "axios";
import yaml from 'js-yaml';
import { useCustomTranslation } from '../../hooks/useCustomTranslation.js';
import { Button, Icon, Form, InputText, Modal, Textarea, Tooltip, TranslationLoader } from '../../UI';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import "./thirdparties.css";

export default function Thirdparties({clientNr, explorerId, open, onClose}) {
  const INITIAL_FORM_DATA = {
    name: "",
    description: "",
    yaml: "",
    clientNr,
    explorerId
  };
  const { getTranslatedPlainText, getTranslatedTooltipConfig } = useCustomTranslation();
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedDefaultYaml, setSelectedDefaultYaml] = useState("");
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);

  useEffect(() => {
    // Fetch all records when the component mounts
    fetchRecords();
    // fetch default yaml
    fetchYamlRecord();
  }, []);

  useEffect(() => {
    const myFormData = {
      ...INITIAL_FORM_DATA,
      yaml: selectedDefaultYaml, // Use the default YAML
    }
    setFormData(selectedRecord || myFormData);
  }, [selectedRecord]);

  const handleChange = ({ target }) => setFormData({ ...formData, [target.name]: target.value });

  async function fetchYamlRecord() {
    const payloadYaml = {
      yamlId: "1"
    }

    try {
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/yaml/query', encodebody(payloadYaml));
      setFormData((prevData) => ({
        ...prevData,
        yaml: getDecodedBody(response.data).yaml,
      }));
      setSelectedDefaultYaml(getDecodedBody(response.data).yaml);
    } catch (error) {
      console.error('Error fetching yaml:', error);
    }
  }

  async function fetchRecords() {
    const payload = { clientNr, explorerId };

    try {
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/thirdparties/queryall', encodebody(payload));
      setRecords(getDecodedBody(response.data));
    } catch (error) {
      console.error('Error fetching records:', error);
    }
  };

  const handleSelectRecord = (record) => {
    setSelectedRecord(prevRecord => record === prevRecord ? null : record);
  };

  const handleClose = () => {
    onClose?.()
    handleSelectRecord(null)
  }

  const handleDeleteRecord = async () => {
    const payload = {
      name: selectedRecord.name,
      clientNr,
      explorerId,
    }

    try {
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/thirdparties/delete', encodebody(payload));
      await fetchRecords();
      // Reset form to initial state
      setFormData({
        ...INITIAL_FORM_DATA,
        yaml: selectedDefaultYaml, // Use the default YAML
      });
      // Clear selected record
      setSelectedRecord(null);
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  function validateYaml(yamlString) {
    try {
      // Try to parse the YAML string
      yaml.load(yamlString);
      // If parsing succeeds, return true
      return true;
    } catch (error) {
      // If an error occurs during parsing, return false
      alert(getTranslatedPlainText("modals.thirdParties.alerts.invalidYamlError"));
      return false;
    }
  };

  const handleUpdateRecord = async () => {
    if (!formData.name || !formData.yaml) {
      return alert(getTranslatedPlainText("modals.thirdParties.alerts.emptyFieldsError"));
    }

    // Validate YAML before updating
     // If invalid, exit early
    if (!validateYaml(formData.yaml)) return;

    try {
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/thirdparties/update", encodebody(formData));
      await fetchRecords();
      setFormData(INITIAL_FORM_DATA);
      setSelectedRecord(null);
    } catch (error) {
      alert(getTranslatedPlainText("modals.thirdParties.alerts.apiActionUpdateError"));
    }
  };

  const handleAddRecord = async () => {
    if (!formData.name || !formData.yaml) {
      return alert(getTranslatedPlainText("modals.thirdParties.alerts.emptyFieldsError"));
    }

    // Validate YAML before adding
    // If invalid, exit early
    if (!validateYaml(formData.yaml)) return;

    try {
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/thirdparties/register", encodebody(formData));
      await fetchRecords();
      setFormData({
        ...INITIAL_FORM_DATA,
        yaml: selectedDefaultYaml, // Use the default YAML
      });
    } catch (error) {
      return alert(getTranslatedPlainText("modals.thirdParties.alerts.apiActionCreationError"));
    }
  };

  return (
    <Modal open={open} onClose={handleClose} maxWidth="80rem">
      <Modal.Title>
        <TranslationLoader
          translationKey="modals.thirdParties.heading"
          fallbackText="API Actions"
        />
      </Modal.Title>
      <Modal.Body>
        <div className="third-parties__table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <TranslationLoader
                    translationKey="modals.thirdParties.table.thead.mainClient"
                    fallbackText="Main Client"
                  />
                </th>
                <th>
                  <TranslationLoader
                    translationKey="modals.thirdParties.table.thead.apiActionName"
                    fallbackText="API Action Name"
                  />
                </th>
                <th>
                  <TranslationLoader
                    translationKey="modals.thirdParties.table.thead.description"
                    fallbackText="Description"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <tr
                  key={record._id}
                  className={selectedRecord?._id === record._id ? "table__row--selected" : `${selectedRecord?.clientNr}--${record.clientNr}`}
                  onClick={() => handleSelectRecord(record)}
                >
                  <td>{record.clientNr}</td>
                  <td>{record.name}</td>
                  <td>{record.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Tooltip
            tooltip={getTranslatedTooltipConfig("modals.thirdParties.buttons.help")}
            placement="right"
            trigger="click"
            interactive
          >
            <Icon interactive={true} className="table__icon--help">
              <HelpCenterIcon />
            </Icon>
          </Tooltip>
        </div>
        <Form>
          <InputText
            type="hidden"
            name="clientNr"
            className="third-parties__form-input-client"
            value={formData.clientNr}
            onChange={handleChange}
          />
          <Form.Control>
            <Form.Label>
              <TranslationLoader
                type="label"
                translationKey="modals.thirdParties.inputs.apiActionName"
                fallbackText="API Action Name"
              />
            </Form.Label>
            <InputText
              name="name"
              value={formData.name}
              onChange={handleChange}
              readOnly={selectedRecord !== null}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label>
              <TranslationLoader
                type="label"
                translationKey="modals.thirdParties.inputs.description"
                fallbackText="Description"
              />
            </Form.Label>
            <InputText
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label>
              <TranslationLoader
                type="label"
                translationKey="modals.thirdParties.inputs.yaml"
                fallbackText="YAML"
              />
            </Form.Label>
            <Textarea
              name="yaml"
              value={formData.yaml}
              onChange={handleChange}
              rows="5"
              resize="vertical"
            />
          </Form.Control>
          <Form.Row>
          <Button color="secondary" onClick={handleClose}>
            <TranslationLoader
              type="button"
              translationKey="modals.thirdParties.buttons.cancel"
              fallbackText="Cancel"
            />
          </Button>
          {selectedRecord ? (
            <>
              <Button color="danger" onClick={handleDeleteRecord}>
                <TranslationLoader
                  type="button"
                  translationKey="modals.thirdParties.buttons.delete"
                  fallbackText="Delete"
                />
              </Button>
              <Button onClick={handleUpdateRecord}>
                <TranslationLoader
                  type="button"
                  translationKey="modals.thirdParties.buttons.update"
                  fallbackText="Update"
                />
              </Button>
            </>
          ) : (
            <Button onClick={handleAddRecord}>
              <TranslationLoader
                type="button"
                translationKey="modals.thirdParties.buttons.addAction"
                fallbackText="Add Action"
              />
              </Button>
          )}
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
