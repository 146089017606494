import { useState } from "react";
import axios from "axios";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { useAsync } from "../../hooks/useAsync";
import FetchErrorView from "../fetchErrorView/FetchErrorView";
import { Button, Form, Icon, InputText, Select, TextEditor, Toolbar, TranslationLoader } from "../../UI";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { encodebody, getDecodedBody } from "../../utils/utils.js";

export default function Productview({ clientNr, explorerId, productName, authorization, updateTreeView }) {
  const [product, setProduct] = useState(null);
  const { getTranslatedSelectOptions, getTranslatedPlainText } = useCustomTranslation();
  const statusOptions = getTranslatedSelectOptions("pages.home.productView.inputs.status");
  console.log("STATUSOPTIONS",statusOptions);
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0].value);
  const { loading, error, retrying, retry } = useAsync(fetchProduct, [clientNr, explorerId, productName]);
  const readOnly = !authorization.designer && !authorization.owner;

  const handleDescriptionChange = (value) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      description: value,
    }));
  };

  const handleSequenceChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input is a valid number
    if (/^\d+$/.test(inputValue) || inputValue === "") {
      // If it's a valid number or an empty string, update the state
      setProduct((prevProduct) => ({
        ...prevProduct,
        sequence: inputValue,
      }));
    }
    // If it's not a valid number, you can choose to do nothing or provide feedback to the user
    // For example, show an error message or prevent further action
  };

  const handleUpdate = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + '/product/update';

    // Define the request body
    const requestBody = {
      clientNr: clientNr,
      explorerId: explorerId,
      productName:product.productName,
      sequence: product.sequence,
      description: product.description,
      status: selectedStatus
    };

      await axios.post(apiUrl, encodebody(requestBody));
      alert(getTranslatedPlainText("pages.home.productView.alerts.productUpdateSuccess"));
  };

  const handleDelete = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + "/product/delete";

    // Define the request body
    const requestBody = {
      productName: product.productName,
      clientNr,
      explorerId,
    };

      await axios.post(apiUrl, encodebody(requestBody));
      alert(getTranslatedPlainText("pages.home.productView.alerts.productDeletionSuccess"));
      updateTreeView();
  };

  async function fetchProduct() {
    if(!productName) return;
    const apiUrl = `${process.env.REACT_APP_CENTRAL_BACK}/product/query`;

    // Define the request body
    const requestBody = {
      clientNr,
      explorerId,
      productName,
    };

    try {
      // Make a POST request to fetch the product
      const response = await axios.post(apiUrl, encodebody(requestBody), {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = getDecodedBody(response.data);
      // Set the fetched product data to the state
      setProduct(data);
      setSelectedStatus(data.status);
    } catch (error) {
      console.log("Error fetching product:", error);
      throw error;
    }
  };

  return (
      error || retrying
      ? (<FetchErrorView
            className="section-right__content"
            retry={retry}
            retrying={retrying}
          />
        ) : (
          <>
            <header className="section-right__toolbar-container">
              <Toolbar>
                {!readOnly && (
                  <>
                    <Toolbar.Button onClick={handleUpdate}>
                      <TranslationLoader
                        type="button"
                        translationKey="pages.home.productView.toolbar.buttons.update"
                        fallbackText="Update"
                      />
                    </Toolbar.Button>
                    <Toolbar.Item>
                      <Button color="danger" size="sm" onClick={handleDelete}>
                        <TranslationLoader
                          type="button"
                          translationKey="pages.home.productView.toolbar.buttons.restore"
                          fallbackText="Restore Default"
                        />
                      </Button>
                    </Toolbar.Item>
                  </>
                )}
                <Toolbar.Item>
                  <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/products-detail-panel">
                    <HelpCenterIcon />
                  </Icon>
                </Toolbar.Item>
              </Toolbar>
            </header>
            <div className="section-right__content">
              <Form className="section-right__form">
                <Form.Control>
                  <Form.Label htmlFor="productName">
                    <TranslationLoader
                      type="label"
                      translationKey="pages.home.productView.inputs.productName"
                      fallbackText="Product Name"
                    />
                  </Form.Label>
                  <InputText
                    id="productName"
                    value={product?.productName}
                    loading={loading}
                    disabled
                  />
                </Form.Control>
                <Form.Control>
                  <Form.Label htmlFor="status">
                    <TranslationLoader
                      type="label"
                      translationKey="pages.home.productView.inputs.status"
                      fallbackText="Status"
                    />
                  </Form.Label>
                  <Select
                    id="status"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    disabled={readOnly}
                    options={statusOptions}
                    loading={loading}
                  />
                </Form.Control>
                <Form.Control>
                  <Form.Label htmlFor="sequence">
                    <TranslationLoader
                      type="label"
                      translationKey="pages.home.productView.inputs.sequence"
                      fallbackText="Sequence"
                    />
                  </Form.Label>
                  <InputText
                    id="sequence"
                    value={product?.sequence}
                    onChange={handleSequenceChange}
                    disabled={readOnly}
                    loading={loading}
                  />
                </Form.Control>
                <Form.Control>
                  <Form.Label htmlFor="productDescription">
                    <TranslationLoader
                      type="label"
                      translationKey="pages.home.productView.inputs.description"
                      fallbackText="Description"
                    />
                  </Form.Label>
                  <TextEditor
                    value={product?.description}
                    onChange={handleDescriptionChange}
                    readOnly={readOnly}
                    loading={loading}
                  />
                </Form.Control>
              </Form>
            </div>
          </>
        )
  );
}
