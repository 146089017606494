import { useState, useEffect } from "react";
import axios from "axios";
import yaml from "js-yaml";
import { toast, ToastContainer } from "react-toastify";
import { useCustomTranslation } from "../../hooks/useCustomTranslation.js";
import { Button, Icon, Form, InputText, Modal, Textarea, Tooltip, TranslationLoader, Select } from "../../UI";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import "react-toastify/dist/ReactToastify.css";
import "./webhooks.css";

export default function Webhooks({ clientNr, open, onClose }) {
  const {
    getTranslatedPlainText,
    getTranslatedSelectOptions,
    getTranslatedTooltipConfig
  } = useCustomTranslation();
  const INITIAL_FORM_DATA = {
    clientNr: clientNr,
    name: "",
    url: "",
    webhookKey: "",
    yaml: "",
    successMessageType: "TEXT",
    successMessage: "success",
  };
  const SUCCESS_MESSAGE_TYPE_OPTIONS = getTranslatedSelectOptions("modals.webhooks.inputs.successMessageType");
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedDefaultYaml, setSelectedDefaultYaml] = useState("");
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);

  useEffect(() => {
    fetchRecords();
    fetchYamlRecord();
  }, []);

  useEffect(() => {
    const MyFormdata = {
      ...INITIAL_FORM_DATA,
      yaml: selectedDefaultYaml, // Use the default YAML
    }
    setFormData(selectedRecord || MyFormdata);
  }, [selectedRecord]);

  const handleChange = ({ target }) => setFormData({ ...formData, [target.name]: target.value })

  const generateWebhookKey = () => {
    return Array.from({ length: 32 }, () =>
      Math.random().toString(36).charAt(2)
    ).join("");
  };

  const updateUrlAndKey = (name) => {
    const webhookKey = generateWebhookKey(); // Generate the webhook key
    const url = `https://apis.gwocu.com/api/webhook/listen/${name}?apiKey=${webhookKey}`; // Add webhookKey as a query parameter with "apiKey" as the key
    setFormData((prevData) => ({
      ...prevData,
      name,
      url,
      webhookKey,
    }));
  };

  const validateMessage = () => {
    const { successMessage, successMessageType } = formData;
    try {
      if (successMessageType === "JSON") {
        JSON.parse(successMessage); // Validate JSON format
      }
      return true;
    } catch (error) {
      alert(getTranslatedPlainText("modals.webhooks.alerts.invalidSuccessMessageError"));
      return false;
    }
  };

  const copyToClipboard = (text, label) => {
    navigator.clipboard.writeText(text);
    toast.success(getTranslatedPlainText("modals.webhooks.alerts.labelCopySuccess", { label }), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  async function fetchYamlRecord() {
    const payloadYaml = { yamlId: "1" };

    try {
      const response = await axios.post(
        process.env.REACT_APP_CENTRAL_BACK + "/yaml/query",
        encodebody(payloadYaml)
      );
      setFormData((prevData) => ({
        ...prevData,
        yaml: getDecodedBody(response.data).yaml,
      }));
      setSelectedDefaultYaml(getDecodedBody(response.data).yaml);
    } catch (error) {
      console.error("Error fetching YAML:", error);
    }
  };

  async function fetchRecords() {
    const payload = { clientNr };

    try {
      const response = await axios.post(
        process.env.REACT_APP_CENTRAL_BACK + "/webhook/queryall",
        encodebody(payload)
      );
      setRecords(getDecodedBody(response.data));
    } catch (error) {
      console.error("Error fetching records:", error);
    }
  };

  const handleSelectRecord = (record) => {
    setSelectedRecord(prevRecord => record === prevRecord ? null : record);
  };

  const handleAddMode = () => {
    fetchRecords();
    setFormData({
      ...INITIAL_FORM_DATA,
      yaml: selectedDefaultYaml, // Use the default YAML
    });
    setSelectedRecord(null);
  };

  const handleClose = () => {
    onClose?.();
    setSelectedRecord(null);
    setFormData(INITIAL_FORM_DATA);
  };

  const handleDeleteRecord = async () => {
    if (!selectedRecord) return;

    const payload = {
      name: selectedRecord.name,
      clientNr,
    };

    try {
      await axios.post(
        process.env.REACT_APP_CENTRAL_BACK + "/webhook/delete",
        encodebody(payload)
      );
      await fetchRecords();
      // Reset form to initial state
      setFormData({
        ...INITIAL_FORM_DATA,
        yaml: selectedDefaultYaml, // Use the default YAML
      });
      // Clear selected record
      setSelectedRecord(null);
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };

  const validateYaml = (yamlString) => {
    try {
      yaml.load(yamlString);
      return true;
    } catch (error) {
      alert(getTranslatedPlainText("modals.webhooks.alerts.invalidYamlError"));
      return false;
    }
  };

  const handleUpdateRecord = async () => {
    if (!formData.name || !formData.yaml || !formData.url || !formData.webhookKey) {
      return alert(getTranslatedPlainText("modals.webhooks.alerts.emptyFieldsError"));
    }

    if (!validateMessage()) return;
    if (!validateYaml(formData.yaml)) return;

    try {
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/webhook/update", encodebody(formData));
      fetchRecords();
      handleAddMode();
    } catch (error) {
      return alert(getTranslatedPlainText("modals.webhooks.alerts.webhookUpdateError"));
    }
  };

  const handleAddRecord = async () => {
    if (!formData.name || !formData.yaml || !formData.url || !formData.webhookKey) {
      return alert(getTranslatedPlainText("modals.webhooks.alerts.emptyFieldsError"));
    }

    if (!validateMessage()) return;
    if (!validateYaml(formData.yaml)) return;

    try {
      await axios.post(
        process.env.REACT_APP_CENTRAL_BACK + "/webhook/register",
        encodebody(formData)
      );
      await fetchRecords();
      setFormData({
        ...INITIAL_FORM_DATA,
        yaml: selectedDefaultYaml, // Use the default YAML
      });
    } catch (error) {
      return alert(getTranslatedPlainText("modals.webhooks.alerts.webhookCreationError"));
    }
  };

  return (
    <Modal open={open} onClose={handleClose} maxWidth="100rem">
      <Modal.Title>
        <TranslationLoader
          translationKey="modals.webhooks.heading"
          fallbackText="Manage Webhooks"
        />
      </Modal.Title>
      <Modal.Body>
        <div className="webhooks__table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <p className="webhooks__main-client-th">
                    <TranslationLoader
                      translationKey="modals.webhooks.table.thead.mainClient"
                      fallbackText="Main Client"
                    />
                  </p>
                </th>
                <th>
                  <TranslationLoader
                    translationKey="modals.webhooks.table.thead.name"
                    fallbackText="Name"
                  />
                </th>
                <th>
                  <TranslationLoader
                    translationKey="modals.webhooks.table.thead.url"
                    fallbackText="URL"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <tr
                  key={record._id}
                  className={selectedRecord?._id === record._id ? "webhooks__table__row--selected" : ""}
                  onClick={() => handleSelectRecord(record)}
                >
                  <td>{record.clientNr}</td>
                  <td>{record.name}</td>
                  <td>
                    <p className="webhooks__url-td">{record.url}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <hr className="divider"/>
        <Form>
          <Form.Control>
            <Form.Label htmlFor="webhookName">
              <TranslationLoader
                type="label"
                translationKey="modals.webhooks.inputs.webhookName"
                fallbackText="Webhook Name"
              />
            </Form.Label>
            <InputText
              id="webhookName"
              value={formData.name}
              onChange={(e) => updateUrlAndKey(e.target.value)}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="webhookUrl">
              <TranslationLoader
                type="label"
                translationKey="modals.webhooks.inputs.url"
                fallbackText="URL"
              />
              <Tooltip tooltip={getTranslatedTooltipConfig("modals.webhooks.buttons.copyUrl")}>
                <Icon
                  onClick={() => copyToClipboard(formData.url, "URL")}
                  style={{ marginLeft: "8px" }}
                  interactive
                  size="sm"
                >
                  <ContentCopyIcon />
                </Icon>
              </Tooltip>
            </Form.Label>
            <InputText
              id="webhookUrl"
              value={formData.url}
              readOnly
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="webhookKey">
              <TranslationLoader
                type="label"
                translationKey="modals.webhooks.inputs.webhookKey"
                fallbackText="Webhook Key"
              />
              <Tooltip tooltip={getTranslatedTooltipConfig("modals.webhooks.buttons.copyWebhookKey")}>
                <Icon
                  onClick={() => copyToClipboard(formData.webhookKey, "Webhook Key")}
                  style={{ marginLeft: "8px" }}
                  interactive
                >
                  <ContentCopyIcon />
                </Icon>
              </Tooltip>
            </Form.Label>
            <InputText
              id="webhookKey"
              value={formData.webhookKey}
              readOnly
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="successMessageType">
              <TranslationLoader
                type="label"
                translationKey="modals.webhooks.inputs.successMessageType"
                fallbackText="Success Message Type"
              />
            </Form.Label>
            <Select
              name="successMessageType"
              id="successMessageType"
              value={formData.successMessageType}
              onChange={handleChange}
              options={SUCCESS_MESSAGE_TYPE_OPTIONS}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="successMessage">
              <TranslationLoader
                type="label"
                translationKey="modals.webhooks.inputs.successMessage"
                fallbackText="Success Message"
              />
            </Form.Label>
            <Textarea
              id="successMessage"
              name="successMessage"
              value={formData.successMessage}
              onChange={handleChange}
              rows="3"
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="yaml">
              <TranslationLoader
                type="label"
                translationKey="modals.webhooks.inputs.yaml"
                fallbackText="YAML"
              />
            </Form.Label>
            <Textarea
              id="yaml"
              name="yaml"
              value={formData.yaml}
              onChange={handleChange}
              rows="5"
              resize="vertical"
            />
          </Form.Control>
          <Form.Row>
            <Button color="secondary" onClick={handleClose}>
              <TranslationLoader
                type="button"
                translationKey="modals.webhooks.buttons.cancel"
                fallbackText="Cancel"
              />
            </Button>
            {selectedRecord && (
              <Button onClick={handleAddMode}>
                <TranslationLoader
                  type="button"
                  translationKey="modals.webhooks.buttons.newWebhook"
                  fallbackText="New Webhook"
                />
              </Button>
            )}
            {selectedRecord ? (
              <>
                <Button color="danger" onClick={handleDeleteRecord}>
                  <TranslationLoader
                    type="button"
                    translationKey="modals.webhooks.buttons.delete"
                    fallbackText="Delete"
                  />
                </Button>
                <Button onClick={handleUpdateRecord}>
                  <TranslationLoader
                    type="button"
                    translationKey="modals.webhooks.buttons.update"
                    fallbackText="Update"
                  />
                </Button>
              </>
            ) : (
              <Button onClick={handleAddRecord}>
                <TranslationLoader
                  type="button"
                  translationKey="modals.webhooks.buttons.addWebhook"
                  fallbackText="Add Webhook"
                />
              </Button>
            )}
          </Form.Row>
        </Form>
      </Modal.Body>
      <ToastContainer />
    </Modal>
  );
};
