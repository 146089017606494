import { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactJson from "react-json-view";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { Terminal } from "../../UI";
import { makeCurlComponentFromApi, makeCurlComponentFromApiExecutionResult } from "../../utils/api-spec-util.js";
import { getDecodedBody, encodebody } from "../../utils/utils.js";

export default function Workflowterminal({
    clientNr,
    explorerId,
    name,
    tasksToRun,
    onRunTask,
    onRestartWorkflow,
    onFinishWorkflow
  }) {
  const { getTranslatedPlainText } = useCustomTranslation();
  const [explorer, setExplorer] = useState([]);
  const [workflowStarted, setWorkflowStarted] = useState(false);
  const [apiList, setApiList] = useState([]);
  const currentApiIndex = useRef(0);

  useEffect(() => {
    fetchApi();
    return () => onFinishWorkflow();
  }, []);

  const commands = {
    cd: (directory) => {
      return getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.changedPathInfo", { directory });
    },
    run: async () => {
      setWorkflowStarted(true);
      currentApiIndex.current = 0;
      try {
        if (apiList.length === 0) {
          return (
            <div className="terminal__message terminal__message--error">
              <br />
              {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.noApisToExecuteError")}
            </div>
          );
        }
        const taskId = apiList[currentApiIndex.current].taskId;
        onRunTask(taskId);
        const myOutput = await constructOutputAPIExecution(currentApiIndex.current);

        return (
          <div className="terminal__message">
            <br />
            <div>
              {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.workflowRunningInfo")}
            </div>
            <br />
            <div>
            {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.executedApiInfo", { apiName: apiList[currentApiIndex.current].api.name})}
            </div>
            <br />
            <pre className="terminal__code">
              {myOutput.curl}
            </pre>
            <br />
            <ReactJson src={myOutput.executionResult} theme="apathy" name={null} collapsed={1} />
            <div>
              {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.executeNextApiPrompt")}
            </div>
          </div>
        );
      } catch (error) {
        console.error('Error during API execution:', error);
        return (
          <div className="terminal__message terminal__message--error">
            {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.apiExecutionError")}
          </div>
        );
      }
    },
    next: async () => {
      if (!workflowStarted) {
        return (
          <div className="terminal__message terminal__message--error">
            {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.noWorkflowRunningError")}
          </div>
        );
      }
      currentApiIndex.current += 1;
      if (currentApiIndex.current === apiList.length) { // we have overpassed the length of the api list
        currentApiIndex.current = 0;
        onRestartWorkflow();
        setWorkflowStarted(false);
        return (
          <div className="terminal__message">
            {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.workflowEndReachedInfo")}
          </div>
        );
      }
      try {
        const taskId = apiList[currentApiIndex.current].taskId;
        onRunTask(taskId);
        const myOutput = await constructOutputAPIExecution(currentApiIndex.current);
        return (
          <div className="terminal__message">
            <br />
            <div>
              {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.executedTaskInfo", { taskName: tasksToRun[taskId] })}
            </div>
            <div>
              {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.executedApiInfo", { apiName: apiList[currentApiIndex.current].api.name })}
            </div>
            <br />
            <pre className="terminal__code">
              {myOutput.curl}
            </pre>
            <ReactJson src={myOutput.executionResult} theme="apathy" name={null} collapsed={1} />
            <div>
              {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.executeNextApiPrompt")}
            </div>
          </div>
        );
      } catch (error) {
        console.error('Error during API execution:', error);
        return (
          <div className="terminal__message terminal__message--error">
            {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.apiExecutionError")}
          </div>
        );
      }
    },
    stop: async () => {
      if (!workflowStarted) {
        return (
          <div className="terminal__message terminal__message--error">
            {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.noWorkflowRunningError")}
          </div>
        );
      }

      currentApiIndex.current = 0;
      onRestartWorkflow();
      setWorkflowStarted(false);

      try {
        return (
          <div className="terminal__message">
            <br />
            {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.workflowStoppedInfo")}
          </div>
        );
      } catch (error) {
        console.error('Error during API execution:', error);
        return (
          <div className="terminal__message terminal__message--error">
            {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.apiExecutionError")}
          </div>
        );
      }
    },
  };

  async function constructOutputAPIExecution(index) {
    if (index < apiList.length) {
      const curl = await makeCurlComponentFromApi(explorer, name, apiList[index].taskId, apiList[index].api)
      const executionResult = await makeCurlComponentFromApiExecutionResult(explorer, name, apiList[index].taskId, apiList[index].api)
      return { curl, executionResult }
    } else {
      setWorkflowStarted(false);
      return (
        <div>
          {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.workflowCompleteInfo")}
        </div>
      )
    }
  };

  async function fetchApi() {
    try {
      const myApibody = {
        workflowName: name,
        clientNr,
        explorerId,
      };

      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/link/queryorderedapi", encodebody(myApibody));
      const myApiList = getDecodedBody(response.data);

      setApiList(myApiList.filter(obj => Object.keys(obj).length > 0));

      const myExplorerbody = { clientNr, explorerId };
      const Eresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/explorer/query", encodebody(myExplorerbody));
      const myExplorer = getDecodedBody(Eresponse.data);
      setExplorer(myExplorer);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="section-right__toolbar-container section-right__toolbar-container--empty" />
      <div className="section-right__content">
        <div className="section-right__terminal-container">
          <Terminal
            commands={commands}
            welcomeMessage={
              <div>
                {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.welcomeToTerminalInfo")}
                <br />
                {getTranslatedPlainText("pages.home.workflowTerminal.terminalMessages.runPrompt")}
                <br />
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};
