import { useState } from "react";
import axios from "axios";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { useAsync } from "../../hooks/useAsync";
import FetchErrorView from "../fetchErrorView/FetchErrorView";
import Modalworkflowclone from "../modalworkflowclone/Modalworkflowclone";
import {
  Button,
  Form,
  Icon,
  InputText,
  Select,
  TextEditor,
  Toolbar,
  TranslationLoader,
} from "../../UI";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { getDecodedBody, encodebody } from "../../utils/utils.js";

export default function Workflowview({
  clientNr,
  explorerId,
  productName,
  name,
  authorization,
}) {
  const [workflow, setWorkflow] = useState(null);
  const [isWorkflowCloneModalOpen, setIsWorkflowCloneModalOpen] = useState(false);
  const { getTranslatedSelectOptions, getTranslatedPlainText } = useCustomTranslation();
  const STATUS_OPTIONS = getTranslatedSelectOptions("pages.home.workflowView.inputs.status");
  const [selectedStatus, setSelectedStatus] = useState(STATUS_OPTIONS[0].value);
  const { error, loading, retry, retrying } = useAsync(fetchWorkflow, [
    clientNr,
    explorerId,
    productName,
    name,
  ]);
  const readOnly = !authorization.designer && !authorization.owner;

  async function fetchWorkflow() {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + "/workflow/query";

    const requestBody = {
      clientNr,
      explorerId,
      productName,
      name,
    };

    try {
      const response = await axios.post(apiUrl, encodebody(requestBody), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = getDecodedBody(response.data);
      setWorkflow(data);
      setSelectedStatus(data.status);
    } catch (error) {
      console.error("Error fetching workflow:", error);
      throw error;
    }
  }

  const handleDescriptionChange = (value) => {
    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      description: value,
    }));
  };

  const handleSequenceChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input is a valid number
    if (/^\d+$/.test(inputValue) || inputValue === "") {
      // If it's a valid number or an empty string, update the state
      setWorkflow((prevWorkflow) => ({
        ...prevWorkflow,
        sequence: inputValue,
      }));
    }
    // If it's not a valid number, you can choose to do nothing or provide feedback to the user
    // For example, show an error message or prevent further action
  };

  const handleClone = () => {
    setIsWorkflowCloneModalOpen(true);
  };

  const handleUpdate = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + "/workflow/update";

    // Define the request body
    const requestBody = {
      description: workflow.description,
      sequence: workflow.sequence,
      status: selectedStatus,
      clientNr,
      explorerId,
      productName,
      name,
    };

    await axios.post(apiUrl, encodebody(requestBody));
    alert(getTranslatedPlainText("pages.home.workflowView.alerts.workflowUpdateSuccess"));
  };

  const handleDelete = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + "/workflow/delete";

    // Define the request body
    const requestBody = {
      clientNr,
      explorerId,
      productName,
      name,
    };

    await axios.post(apiUrl, encodebody(requestBody));
    alert(getTranslatedPlainText("pages.home.workflowView.alerts.workflowDeletionSuccess"));
    window.location.reload();
  };

  return error || retrying ? (
    <FetchErrorView
      className="section-right__content"
      retry={retry}
      retrying={retrying}
    />
  ) : (
    <>
      <header className="section-right__toolbar-container">
        <Toolbar>
          {!readOnly && (
            <>
              <Toolbar.Button onClick={handleUpdate}>
                <TranslationLoader
                  type="button"
                  translationKey="pages.home.workflowView.toolbar.buttons.update"
                  fallbackText="Update"
                />
              </Toolbar.Button>
              <Toolbar.Item>
                <Button color="danger" size="sm" onClick={handleDelete}>
                  <TranslationLoader
                    type="button"
                    translationKey="pages.home.workflowView.toolbar.buttons.remove"
                    fallbackText="Remove"
                  />
                </Button>
              </Toolbar.Item>
              <Toolbar.Button onClick={handleClone}>
                <TranslationLoader
                  type="button"
                  translationKey="pages.home.workflowView.toolbar.buttons.clone"
                  fallbackText="Clone"
                />
              </Toolbar.Button>
            </>
          )}
          <Toolbar.Item>
            <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/workflows-detail-panel">
              <HelpCenterIcon />
            </Icon>
          </Toolbar.Item>
        </Toolbar>
      </header>
      <div className="section-right__content">
        <Form className="section-right__form">
          <Form.Control>
            <Form.Label htmlFor="workflowName">
              <TranslationLoader
                type="label"
                translationKey="pages.home.workflowView.inputs.workflowName"
                fallbackText="Workflow Name"
              />
            </Form.Label>
            <InputText
              type="text"
              id="workflowName"
              value={workflow?.name}
              loading={loading}
              disabled
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="status">
              <TranslationLoader
                type="label"
                translationKey="pages.home.workflowView.inputs.status"
                fallbackText="Status"
              />
            </Form.Label>
            <Select
              id="status"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              disabled={readOnly}
              options={STATUS_OPTIONS}
              loading={loading}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="sequence">
              <TranslationLoader
                type="label"
                translationKey="pages.home.workflowView.inputs.sequence"
                fallbackText="Sequence"
              />
            </Form.Label>
            <InputText
              id="sequence"
              value={workflow?.sequence}
              onChange={handleSequenceChange}
              disabled={readOnly}
              loading={loading}
            />
          </Form.Control>
          <Form.Control className="panel__text-editor-section">
            <Form.Label htmlFor="workflowDescription">
              <TranslationLoader
                type="label"
                translationKey="pages.home.workflowView.inputs.description"
                fallbackText="Description"
              />
            </Form.Label>
            <TextEditor
              id="workflowDescription"
              value={workflow?.description}
              onChange={handleDescriptionChange}
              readOnly={readOnly}
              loading={loading}
            />
          </Form.Control>
        </Form>
        {isWorkflowCloneModalOpen && (
          <Modalworkflowclone
            clientNr={clientNr}
            explorerId={explorerId}
            SourceProductName={productName}
            sourceWorkflowName={name}
            open={isWorkflowCloneModalOpen}
            onClose={() => setIsWorkflowCloneModalOpen(false)}
          />
        )}
      </div>
    </>
  );
}
