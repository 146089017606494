import { useState } from "react";
import { Tooltip } from "../../../UI";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { useCustomTranslation } from "../../../hooks/useCustomTranslation";

export default function RenameTreeItemRenderer({
  formProps,
  inputProps,
  inputRef,
  item,
  onStopRenamingItem,
  protectedItems,
  isNameInUse
}) {
  const { getTranslatedPlainText, getTranslatedTooltipConfig } = useCustomTranslation();
  const itemRef = useOutsideClick(onStopRenamingItem);
  const [nameError, setNameError] = useState(null);

  if(protectedItems?.find((currItem) => currItem === item.index)) {
    return (
      <Tooltip
        tooltip={getTranslatedTooltipConfig("pages.apisEditor.renameTreeItemRenderer.inputs.systemFolderNewName")}
        visible={true}
      >
        <span ref={itemRef}>{item.data}</span>
      </Tooltip>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const normalizedName = inputProps.value.trim();
    const changedName = item.data !== normalizedName;

    if(!changedName) {
      return onStopRenamingItem();
    }

    if(normalizedName.length === 0) {
      return setNameError(getTranslatedPlainText("pages.apisEditor.renameTreeItemRenderer.alerts.blankNewTaskNameError"));
    }

    if(isNameInUse(normalizedName)) {
      return setNameError(getTranslatedPlainText("pages.apisEditor.renameTreeItemRenderer.alerts.duplicateTaskNameError"));
    }

    formProps.onSubmit(e);
  }

  const handleKeyDown = (e) => {
    if(nameError) setNameError(null);
    if(e.key === "Escape") onStopRenamingItem();
  }

  return (
    <form {...formProps} onSubmit={handleSubmit}>
      <Tooltip
        tooltip={{ content: nameError, isHtml: false }}
        visible={!!nameError}
      >
        <span>
          <input
            {...inputProps}
            ref={inputRef}
            className="rct-tree-item-renaming-input"
            onBlur={handleSubmit}
            onKeyDown={handleKeyDown}
          />
        </span>
      </Tooltip>
    </form>
  );
};
