import { useState } from "react";
import axios from "axios";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { useAsync } from "../../hooks/useAsync";
import { Form, Icon, InputText, TextEditor, Toolbar, TranslationLoader } from "../../UI";
import { getDecodedBody, encodebody } from "../../utils/utils.js";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

export default function Workflowcomplianceview({ clientNr, explorerId, productName, name, authorization }) {
  const { getTranslatedPlainText } = useCustomTranslation();
  const { loading, error, retrying, retry } = useAsync(fetchWorkflow, [clientNr, explorerId, productName, name]);
  const [workflow, setWorkflow] = useState(null);
  const readOnly = !authorization.designer && !authorization.owner;

  async function fetchWorkflow() {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + "/workflow/query";

    const requestBody = {
      clientNr,
      explorerId,
      productName,
      name,
    };

    try {
      const response = await axios.post(apiUrl, encodebody(requestBody), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = getDecodedBody(response.data);
      setWorkflow(data);
    } catch (error) {
      console.error("Error fetching workflow:", error);
    }
  };

  const handleComplianceDescriptionChange = (value) => {
    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      complianceDescription: value,
    }));
  };

  const handleUpdate = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + "/workflow/update";

    // Define the request body
    const requestBody = {
      complianceDescription: workflow.complianceDescription,
      clientNr,
      explorerId,
      productName,
      name,
    };

    await axios.post(apiUrl, encodebody(requestBody));
    alert(getTranslatedPlainText("pages.home.workflowComplianceView.alerts.workflowUpdateSuccess"));
  };

  return error || retrying
    ? (<FetchErrorView
        className="section-right__content"
        retry={retry}
        retrying={retrying}
      />
      ) : (
      <>
        <header className="section-right__toolbar-container">
          <Toolbar>
            {!readOnly &&
              <Toolbar.Button onClick={handleUpdate}>
                <TranslationLoader
                  type="button"
                  translationKey="pages.home.workflowComplianceView.toolbar.buttons.update"
                  fallbackText="Update"
                />
              </Toolbar.Button>
            }
            <Toolbar.Item>
              <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/workflows-detail-panel#workflowcompliance-section">
                <HelpCenterIcon />
              </Icon>
            </Toolbar.Item>
          </Toolbar>
        </header>
        <div className="section-right__content">
          <Form className="section-right__form">
            <Form.Control>
              <Form.Label htmlFor="workflowName">
                <TranslationLoader
                  type="label"
                  translationKey="pages.home.workflowComplianceView.inputs.workflowName"
                  fallbackText="Workflow Name"
                />
              </Form.Label>
              <InputText
                id="workflowName"
                value={workflow?.name}
                loading={loading}
                disabled
              />
            </Form.Control>
            <Form.Control>
              <Form.Label htmlFor="workflowComplianceDescription">
                <TranslationLoader
                  type="label"
                  translationKey="pages.home.workflowComplianceView.inputs.complianceDescription"
                  fallbackText="Compliance Description"
                />
              </Form.Label>
              <TextEditor
                id="workflowComplianceDescription"
                value={workflow?.complianceDescription}
                onChange={handleComplianceDescriptionChange}
                loading={loading}
                readOnly={readOnly}
              />
            </Form.Control>
          </Form>
        </div>
      </>
    );
}
